
import anime from 'animejs/lib/anime.es.js';
import {showCartHeader,waiting, removeWaiting} from '../javascript/index.js';
import {orderDetailsToCart, orderDetails, openCart} from '../javascript/cart.js';

import { v4 as uuidv4 } from 'uuid';
// import "velocity-animate/velocity.ui.min.js";
export {loadStore, storeList, getStoreList};
let tempOrderDetails = {cart:[],shipping:{},contact:{},payment:{}};

let ffWelcomeViewed = false;


export function orderDetailstoStore(updated) {
    tempOrderDetails = updated;
    
}

//let storeArtList = artList;

let storeArtList = [];
let storeList = [];
let collections = [];
let storeSetH = 0;
let vI = 0;
let packageList = [];

let arriveBez = 'cubicBezier(.02,.77,.09,.95)';
let departBez = 'cubicBezier(.02,.77,.09,.95)';

let inMotion = false;
// utils
function uniqueStore(arr, prop){
  return arr.reduce((a, d) => {
    if (!a.includes(d[prop]) && d[prop] !== '') { a.push(d[prop]); }
    return a;
  }, []);
}
function ffWelcome() {
    if(!ffWelcomeViewed) {
        let content = `
        <div class='ffWelcome-set'>
            <h2>Welcome!</h2>
            <p>Thanks for visiting my website.  Before releasing this to the general public I'd like to make sure everyoriginal1 is smooth.  Please click around and view my art and please add some artwork to the cart and complete a checkout.  The cost to you will be 1 cent at checkout - this is to ensure the payment buttons work and the follow up confirmations are seamless.  <br><br>
            Also, please email me with any small (or large) glitches.  I hope to release this to everyone next week!</p> 

        </div>
    `;
    $('body').append(content);
    $('.ffWelcome-set').on('click',function() {
        $(this).fadeOut(600);
        ffWelcomeViewed = true;
    })
    }
    
}

function loadStore() {
    // console.log(tempOrderDetails);
    collections = [];
    waiting('.content');
    $('main').height('')
    $('.subHeader').css('margin','0 0')
    $.when(
        loadArtList(),
        getStoreList(),
        loadPackageList()
        
    ).done(function(artList, sList, pList) {
        //console.log(pList);
        packageList = JSON.parse(pList[0]);
        storeArtList = JSON.parse(artList[0]);
        storeList = JSON.parse(sList[0]);
        //console.log(packageList);
        uniqueStore(storeArtList, 'collection').forEach((item, i) => {
            let obj = {collection:item};
            let byColl = storeArtList.filter((storeItem) => {
                return storeItem.collection == item
            });
            let subList = uniqueStore(byColl,'subcollection');
            obj.sub = subList;
            collections.push(obj);
        });
        //console.log(storeArtList);
        storeArtList = storeArtList.filter((art) => {
            return art.orderNum !== '0'
        })

        let windowInner = window.innerHeight;
        let cartPageHeight = windowInner-100;
        if($(window).width() < 600) {
            //console.log('small window')
            // $('.content-set').css('height',cartPageHeight);
        } else {
            //console.log('large window')
            // $('.content-set').css('height',cartPageHeight);
        }
        $('.content-set').html(`<div class='store-set'></div>`);
        removeWaiting();
        loadStoreItems();

    })

}

//<i style='color:#878787;position:relative' class="press fas fa-chevron-right fa-lg"></i>
let last='';

// get LISTS
function loadArtList() {
    return $.ajax({
        url: "ajax/art.php?getArtList",
        success: function(data) {
            return data;

        }
    });
}
function getStoreList() {
    return $.ajax({
        url: "ajax/admin.php?storeList",
        success: function(data) {
            //console.log(data);
            return data;

        }
    });
}
function filterStoreList() {
    $('collSelect-set').off('click').on('click',function(e) {
        if($(e.target).attr('id') == 'startCollSelect') {
            console.log('getting started');
        } else if($(e.target).hasClass('collSelect-select')) {
            let thisCol = $(e.target).attr('data-col');
            let thisSubCol = $(e.target).attr('data-subcol');
            console.log(thisCol,thisSubCol);
            if(thisCol=='all') {
                loadStoreItems();
            } else {
                loadStoreItems('collection',thisCol,thisSubCol);

            }
            $('.collSelect').hide();
        } else {
        }

    })
}
function loadPackageList() {
    return $.ajax({
        url: "ajax/admin.php?getPackageList",
        success: function(data) {
            //console.log(data);
            return data;

        }
    });
}

function loadSubHeader(col = 'Choose Collection',subcol = '') {
    console.log(col,subcol)
    $(".subHeader").show();
    $('.collSelect').remove();
    let colContent = `<h3 id='startCollSelect' class='press colSelectButton'>${col}</h3>`;
    let subcolContent = subcol ? `<h3 id='startSubCollSelect' class='press colSelectButton'>${subcol}</h3>` : '';
    let exitButton = ``;
    if(col !== 'Choose Collection' && subcol !== '') {
        exitButton = `<div class='filterExit'><i class="fas fa-times"></i></div>`;
    } else {
        exitButton = '';
    }
    let content = `
        <div class='collSelect-set sh-arrive'>
            <div class='collSelect-button-set'>
                ${colContent}
                ${subcolContent}
                ${exitButton}
            </div>
            <div class='store-search'>
                <div class='store-search-input'></div>
                <div class='store-search-icon'><i class="fal fa-search"></i></div>
            </div>
        </div>

    `;

    function headerArrive() {
        //console.log('header arriving');
        if($('.sh-arrive').length>0) {
            //console.log('something there, exiting');
            anime({
                targets: '.sh-arrive ',
                translateX: [0,10],
                translateY: [0,10],
                opacity: [1,0],
                duration: 200,
                transform: ['blur(0px)','blur(4px)'],
                easing: 'cubicBezier(.15,1.02,.2,.99)',
                //delay: anime.stagger(100),
                complete: function() {
                    //console.log('loading content');
                    $('.subHeader').html(content);
                    clicks();
                }
            })
        } else {
            $('.subHeader').html(content);
            clicks();
            anime({
                targets: '.sh-arrive ',
                translateX: [-10,0],
                translateY: [-10,0],
                opacity: [0,1],
                duration: 800,
                transform: ['blur(4px)','blur(0px)'],
                easing: 'cubicBezier(.15,1.02,.2,.99)',
                delay: anime.stagger(100,{start: 320})
            })
        }

        function clicks() {

            $('.collSelect-set').on('click','.store-search-icon',function() {
                if($("#searchVal").length == 0) {
                    $('.store-search-input').html(`<input autocomplete="off" id='searchVal'>`);
                    $("#searchVal").focus();
                    $('body').prepend(`<div class='modal-blur modal'></div>`);
                    $('.modal-blur').on('click',function() {
                        console.log('clicking on modal')
                        $('.modal-blur').remove();
                        $('.collSelect').remove();
                        $('#searchVal').remove();
                    })
                    $("#searchVal").on('keyup',function() {
                        $('.collSelect').remove();
                        let val = $(this).val().toLowerCase();
                        let tempList = storeArtList.filter((art) => {
                            return art.title.toLowerCase().search(val) !== -1
                        })
                        console.log(tempList);
                        let content = `
                            <div class='collSelect'><table style='margin: 0 auto' class=''>
                        `;
                        tempList.forEach((col, i) => {
                            content += `
                            <tr>
                                <td data-col='${col.title}' data-field='title' class='collSelect-select collSelect-item press'>${col.title}</td>
                            </tr>`;
                        });
                        content += `
                            </table></div>
                        `;
                        $(".collSelect-set").append(content);


                    })
                } else {
                    $('.modal-blur').remove();
                    $('.collSelect').remove();
                    $('#searchVal').remove();
                }


            })


            $('.collSelect-set').on('click','#startCollSelect',function() {
                $(".collSelect").remove();
                $('body').prepend(`<div class='modal-blur modal'></div>`);
                $('.modal-blur').on('click',function() {
                    console.log('clicking on modal')
                    $('.modal-blur').remove();
                    $('.collSelect').remove();
                })
                let thisID = $(this).attr('id');
                //$('.collSelect').show();
                //console.log(thisID);
                let content = `<div class='collSelect'><table style='margin:0 auto'>

                    <tr><td data-col='all' colspan='2'  class='collSelect-select focus collSelect-item press'>All Artwork</td></tr>
                `;
                collections.forEach((col, i) => {
                    content += `
                    <tr>
                    <td data-col='${col.collection}' data-field='collection' class='collSelect-select collSelect-item press'>${col.collection}</td></tr>`;
                });
                content += `
                    <tr><td colspan='20' id='return' class='collSelect-select focus collSelect-item press'>close</td></tr>
                    </table></div>
                `;
                $(".collSelect-set").append(content);


            });
            // Choose Collection
            $('.collSelect-set').on('click','[data-col]',function() {
                let thisField = $(this).attr('data-field');
                let thisColl = $(this).data('col');
                console.log(thisField,thisColl);
                $('#searchVal').remove();
                loadStoreItems(thisField,thisColl);

            })
            // Open SubCollection
            $('.collSelect-set').on('click','#startSubCollSelect',function() {
                $(".collSelect").remove();
                //console.log('opening subcollselect');
                //$('.collSelect').show();
                let thisCollList = collections.filter((coll) => {
                    return coll.collection == col
                })
                //console.log(col,thisCollList);
                let content = `<div class='collSelect'><table style='margin:0 auto' >

                    <tr><td data-subcol='' colspan='2' class='collSelect-select collSelect-item press focus' >All ${col}</td></tr>
                `;
                thisCollList[0].sub.forEach((col, i) => {
                    content += `
                    <tr>
                    <td data-subcol='${col}' class='collSelect-select collSelect-item press'>${col}</td></tr>`;
                });
                content += `
                    <tr><td id='return' class='collSelect-select collSelect-item press focus' style=''>return</td></tr>
                    </table></div>
                `;

                $(".collSelect-set").append(content);

                // let buttonSetW = $(".collSelect-button-set").width();
                // $('.collSelect').width(buttonSetW);

            });


            // Choose SubCollection
            $('.collSelect-set').on('click','[data-subcol]',function() {
                let thisColl = $(this).data('subcol');
                //if(thisColl=='all')
                loadStoreItems('collection',col,thisColl);

            })

            $('.collSelect-set').on('click','#return',function() {
                $('.collSelect').remove();
                $('.modal-blur').remove();
            })


            $(`.filterExit`).on('click',function() {
                loadStoreItems();
            })
        }

    }


    headerArrive();
}



let loaded = [];
let filteredList = [];
let loadMotion = false;

// ArtPage
function loadStoreItems(field = 'collection',col,subcol) {
    $('.modal').remove();
    $('.store-set').empty();
    vI = 0;
    $('.collSelect-set').off('click');
    $('.collSelect').hide();
    //console.log(col,subcol);

    function clearStoreSet() {
        //console.log(vI);
        return new Promise((resolve,reject) => {
            anime({
                targets: '.store-set *',
                opacity: [1,0],
                duration: 0,
                easing: 'cubicBezier(1,.02,.76,.53)',
                complete: function() {
                    resolve('leaving done');

                    //$(".store-set").html('<h3>pictures coming...</h3>');
                }
            })
        })

    }

    filteredList = [];
    if(field=='title') {
        filteredList = storeArtList.filter((art) => {
            return art.title == col
        })
        loadSubHeader();
    }
    if(field=='collection') {
        if(!col || col=='all') {
            filteredList = storeArtList;
            //console.log(filteredList);
            $('#startSubCollSelect').hide();
            loadSubHeader();

        } else if(col && !subcol) {
            loadSubHeader(col,'Choose SubCollection');
            $("#startSubCollSelect").show();
            filteredList = storeArtList.filter((item) => {
                return item.collection == col
            })
        } else if(col && subcol) {
            console.log('both are registered');
            loadSubHeader(col,subcol);
            $('#startSubCollSelect').show();
            filteredList = storeArtList.filter((item) => {
                return item.collection == col && item.subcollection == subcol
            })
        }

        let next = false;
        loaded = [];
        function compare(a,b) {
            let comparison=0;
            if(parseInt(a.orderNum)>parseInt(b.orderNum)) {
                comparison = 1;
            } else if(parseInt(a.orderNum)<parseInt(b.orderNum)) {
                comparison = -1;
            }
            return comparison;
        }
        filteredList = filteredList.sort(compare);
    } else if(field=='title') {
        loadSubHeader('-','-')
    }

    //console.log(filteredList);
    clearStoreSet().then((mess) => {
        filteredList.forEach((art, i) => {
            let arts = `

                <div data-store-item='${art.ID}' data-openInfo='${art.ID}' class='store-item'>
                    <div data-artContain='${art.ID}' style='' class='store-art-container'>
                        <img data-artID='${art.ID}' loading='lazy' class='artImg' src='https://www.ecomm.bradwrightwebdev.com/assets/images/${art.fileName}' >
                    </div>
                    <div style='display:flex; flex-direction: column;'>
                        <div class='store-item-title' style='' data-h2='${art.ID}'>
                            <h2>${art.title}</h2>
                        </div>
                        <div class='press store-info-button' >Click for More Info</div>
                    </div>
                </div>



            `;


            $('.store-set').append(arts);
        });

        //loadStoreItem(vI,false)
    }).then((mess1) => {
        //console.log(mess1);
        //enterLoaded(vI,-1);

    })

    $(".store-set").on('click','[data-openInfo]',function() {
        let thisID = $(this).attr('data-openInfo');
        //console.log(thisID);
        infoHideShow(thisID);
    })

}



function enterLoaded(index,prev) {
    // if(loadMotion) {return}
    // loadMotion = true;

    // // determine browser arrows
    // if(index==0) {$('.store-up').css('visibility','hidden');$('.store-down').css('visibility','visible');}
    // else if(index== filteredList.length-1) {$('.store-down').css('visibility','hidden');$('.store-up').css('visibility','visible');}
    // else {$('.store-up, .store-down').css('visibility','visible');}
    // if(index<0 || index>=filteredList.length) {console.log('returning');loadMotion=false;return}
    //console.log(index,prev);

    let thisID = filteredList[index].ID;
    $(`[data-artSet-inside-set='${filteredList[index].ID}']`).show();
    $(`[data-artID='${thisID}']`).on('load',function() {
        //adjustHeight(thisID,0)
    })
    $(`[data-artSet-inside-set='${filteredList[index].ID}']`).hide();
    // animate out
    // if(prev>=0) {
    //     anime({
    //         targets: `[data-artSet-inside-set='${filteredList[prev].ID}']`,
    //         transform: 'rotateZ(.1deg)',
    //         opacity: [1,0],
    //         scale: [1,.95],
    //         filter: ['blur(0px)','blur(7px)'],
    //         duration: 600,
    //         easing: 'cubicBezier(.15,1.02,.2,.99)',
    //         complete: function() {
    //             //console.log('hiding');
    //             $(`[data-artSet-inside-set='${filteredList[prev].ID}']`).hide();
    //         }
    //     })
    // }

    anime({
        delay: 100,
        begin: function() {
            $(`[data-artSet-inside-set='${filteredList[index].ID}']`).show();
            vI = index;
            //adjustHeight(thisID,0);
        },
        targets: `[data-artSet-inside-set='${filteredList[index].ID}']`,
        transform: 'rotateZ(.1deg)',
        filter: ['blur(7px)','blur(0px)'],
        scale: [.95,1],
        opacity: [0,1],
        duration: 600,
        delay: 50,
        easing: 'cubicBezier(.15,1.02,.2,.99)',
        complete: function() {
            //console.log('it should be there bro')
            watchScroll(index);
            loadMotion = false;

        }
    })

    if(!loaded.includes(index+1)) {
        loadStoreItem(index+1,true);
    }

}

function infoHideShow(artID) {
    // add modal
    $('.store-modal').remove();


    //$('.store-set').css('overflow','hidden');

    // render Artwork
    let art = filteredList.filter((item) => {
        return item.ID == artID
    });
    art = art[0];
    // console.log(artID,art,tempOrderDetails);
    //$('.store-set').prepend(`</div>`);
    let original = storeList.find(i => i.type=='original' && i.artID==art.ID)
        // buyItems += //html 
        //     `<div class='flexr press buyPage-storeList-item'>
                
        //         <div style='color:#878787' data-buy='${original.ID}'>${original.type}</div>
        //         <div data-buy='${original.ID}'>${original.size}</div>
        //         <div data-buy='${original.ID}'>$${original.price}</div>
                
        //     </div>`
            // console.log(original,filteredList)
    let artRender = `

    <div class='store-modal modal'>
        <div class='modalExit press'><i class="fas fa-times"></i></div>
        <div class='modal-width info-art-set '>
            <h2>${art.title}</h2>
            <hr>
            <div class='info-artInfo-set'>
                <div class='info-art'>
                    <img data-artID='${art.ID}' class='infoArt' src='https://www.ecomm.bradwrightwebdev.com/assets/images/${art.fileName}' >
                    <p>${art.collection} - ${art.year}</p>
                    <p id='extra'>${art.description}</p>
                    <p style='color:black;text-decoration:italics;font-weight:900;margin-top:10px' >${art.medium}</p>
                    ${original ? `<div data-value='${original.ID}' class='info-artInfo-item'><p>Original</p><h4>${art.dim}</h4><h4>$${original.price}</h4></div>` : ''}
                </div>
                
            </div>


        </div>
        
        <div style='position:relative' class='info-buyInfo modal-width'>
            
            
        </div>
    </div>
        `;

    $('.store-set').prepend(artRender);
    let windowInner = window.innerHeight;


    $('body').prepend(`<div class='modal-blur modal'></div>`);
    let thisBuy = {}

    // render buy items

    function renderBuyInfo() {
        let con = ``
        if(tempOrderDetails.cart.length>0) {
            con += `
            <div style='position:relative;min-height:20px' id='buyList'><div data-nav='cart' class='modalCartImg' style="cursor:pointer;position:absolute;top:0px;font-size:20px;left:-15px"><i class="far fa-shopping-cart"></i></div>`
            tempOrderDetails.cart.forEach(c => {
                if(c.artID == artID) {
                con += `<div class='flexr'><p>${c.size}</p><p>$${c.price}</p><p>${c.type}</p><p data-id='${c.storeID}' class='cartPage-remove'>X</p></div>`
                }
            })
            
            con += `</div>`
        }
        
        con += `<hr>
        <div style='width:100%;justify-content:space-between;' id='po-reveal' class='flexr' >
            <h5>Print Options</h5>
            <i style='color:#878787;position:relative'  class="press fas fa-chevron-down fa-2x"></i>
        </div>
        <div class='flexc hide' id='po-set'></div>`
        
        
        $('.info-buyInfo').html(con)
    }

    renderBuyInfo() 

    let thisStoreItemList = storeList.filter((storeItem) => {
        return storeItem.artID == artID
    })

    thisStoreItemList = thisStoreItemList.sort((a,b) => {
        let compare = 0;
        if(parseInt(a.price)<parseInt(b.price)) {
            compare = 1;
        } else if(parseInt(a.price)>parseInt(b.price)) {
            compare = -1;
        }
        return compare
    })
    
    $('.modal').on('click','#po-reveal',function() {
        let content = ``
        thisStoreItemList.forEach((item, i) => {
            if(item.type=='original') return
            // console.log(item.size)
            content += `<div class='po-item' data-value='${item.ID}'>${item.size}</div>`
        });
        $('#po-set').toggleClass('hide')
        thisBuy = {};
        $('#po-set').html(content)


    })
    
    $('.modal').on('click','.cartPage-remove',function() {
        let removeID = $(this).attr('data-id');
        tempOrderDetails.cart = tempOrderDetails.cart.filter((item) => {
            return item.storeID !== removeID
        });
        renderBuyInfo()
        showCartHeader(tempOrderDetails.cart);
    });

    $('.modal').on('click','[data-value]',function() {
        if($('#po-set').hasClass('hide')) {
            console.log('po set hiding')
            $('#po-set').toggleClass('hide',false)
        }

        let thisID = $(this).attr('data-value');
        thisBuy.storeID = thisID;

        let thisItem = storeList.find((item) => {
            return item.ID == thisID
        });
        let thisArt = storeArtList.find((art) => {
            return art.ID == thisItem.artID
        })
        console.log(thisItem,thisID,thisArt)
        // if(thisItem.type == 'original') {
        //     if(tempOrderDetails.cart.some((item) => item.storeID == thisItem.ID)) {
        //         return
        //     }
        // }

        // create unique temp id
        let newID = uuidv4();
        thisBuy.ID = newID;
        thisBuy.title = thisArt.title;
        thisBuy.type = thisItem.type;
        thisBuy.size = thisItem.size;
        thisBuy.price = thisItem.price;
        thisBuy.artID = thisArt.ID;
        
        if(thisItem.type=='original') {
            thisBuy.canvas = 'yes'
        } else {
            thisBuy.canvas = 'no';
        }
        
        thisBuy.fileName = thisArt.fileName
        thisBuy.boxID = thisItem.boxID
        thisBuy.rollID = thisItem.rollID;

        console.log(thisBuy)

        let content = //html
        `<div class='buyOptions-set flexr'>
            ${thisBuy.size}
            <div class='typeSelect flexc'>`
                if(thisBuy.type=='original') {
                    content += `<div class='item selected'><p>Original</p><h3>$${thisBuy.price}</h3></div>
                    </div><div id='confirmRemove'><p>Great choice!  Due to the size of this piece of art, significant shipping charges will apply. </p><div id='addToCart' class='button'>Add to Cart</div></div>`
                 } else {
                    content += `<p class='chooseStyleText' >choose type</p><div class='flexr'><div data-typeSelect='p' class='item'><p>Print</p><h3>$${thisBuy.price}</h3></div>
                    <div data-typeSelect='canvas' class='item'><p>Print on Canvas</p><h3>$${parseInt(thisBuy.price)+50}</h3></div></div></div>`
                 } 
                
            content += `
        </div>`
        

        $('#po-set').html(content)

        $('[data-typeSelect').on('click',function() {
            let type = $(this).attr('data-typeSelect')
            if($(this).hasClass('selected')) {
                $(this).removeClass('selected')
                thisBuy.type = ''
                thisBuy.canvas = ''
                $('#confirmRemove').remove()
            } else {
                $('.buyOptions-set').find('.item').removeClass('selected')
                $(this).addClass('selected')
                if($('#confirmRemove').length>0) $('#confirmRemove').remove()
                if(type=='canvas' || type=='original') {
                    thisBuy.type = type
                    thisBuy.canvas = 'yes'
                    $('#po-set').append(`<div id='confirmRemove'><p>The Print on Canvas option carries a significant increase in the shipping cost of the product</p><div data-type='print' id='addToCart' class='button'>Add to Cart</div></div>`)
                } else {
                    $('#po-set').append(`<div id='confirmRemove'><div data-type='canvas' id='addToCart' class='button'>Add to Cart</div></div>`)
                    thisBuy.type = 'print'
                    thisBuy.canvas = 'no'
                }

            }
        })

    })

    

    anime({
        targets: '.modal-blur',
        duration: 300,
        easing: 'linear',
        opacity: [0,1]
    })

    anime({
        targets: `.store-modal`,
        opacity: [0,1],
        translateX: [30,0],
        easing: 'cubicBezier(0,1.01,.37,.95)',
        duration: 600,
    })

    anime({
        targets: `.store-modal h2, .info-art, .info-artInfo-item, .info-art p, .store-options-set, .info-buyInfo h3`,
        translateX: [30,0],
        easing: 'cubicBezier(0,1.01,.37,.95)',
        delay: anime.stagger(60,{start:100}),
        opacity: [0,1],
        duraiton: 300

    })

    


    $('.modal').on('click','#addToCart',function() {
        //console.log(tempOrderDetails.cart);
        
        console.log(thisBuy)
        
        
        tempOrderDetails.shipping.chosenRates = false;
        tempOrderDetails.shipping.shippingCharge = undefined
        tempOrderDetails.readyToPurchase = false;
        tempOrderDetails.cart.push(thisBuy);
        tempOrderDetails.edited = true
        console.log(tempOrderDetails)
        // orderDetails
        // orderDetails.getInstance().setInstance(tempOrderDetails)
        orderDetailsToCart(tempOrderDetails);
        // renderBuyPageItems();
        renderBuyInfo()
        setTimeout(showCartHeader(tempOrderDetails.cart),400);
        last='';
        
    });


    $(".modalExit, .modal-blur").on('click',function() {
        $('.store-set').css('overflow','auto');
        anime({
            targets: '.modal-blur',
            duration: 400,
            easing: 'linear',
            opacity: 0
        })
        anime({
            targets: `.store-modal h2, .info-art, .info-artInfo-item, info-art p, .store-options-set, .info-buyInfo h3`,
            translateX: [0,-50],
            easing: 'cubicBezier(0,1.01,.37,.95)',
            delay: anime.stagger(60,{start:100,direction:'reverse'}),
            opacity: 0,
            duraiton: 300

        })
        anime({
            targets: `.store-modal`,
            easing: 'cubicBezier(.76,.05,.31,.9)',
            translateX: -50,
            opacity: 0,
            duration: 400,
            delay: anime.stagger(10,{start:200}),
            complete: function() {
                $('.modal').remove();
            }
        })

    })
}

function adjustHeight(id,dur = 300,info='info') {
    //console.log(id,dur,info);
    if(info == 'info') {

    } else {

    }
    let infoSetH = 0;
    if(info=='info') {
        infoSetH= $(`[data-artstore-info-set='${id}']`).outerHeight();
    } else if(info=='buyPage') {
        infoSetH = $(`[data-artstore-buyPage-set='${id}']`).outerHeight();
    }
    console.log(storeSetH*.6, $(`[data-artID='${id}']`).height());
    $(`[data-artID='${id}']`).on('load',function() {
        if(storeSetH*.60 < $(`[data-artID='${id}']`).height()) {
            console.log($(`[data-artID='${id}']`));
        } else {
            console.log('it will fit')
        }
    });
    let h2H = $(`[data-h2Contain='${id}']`).height();
    let windowW = $(window).width();
    //console.log(infoSetH,id);
    let remainder = storeSetH - infoSetH - h2H;
    //console.log(typeof(infoSetH));
    let art = $(`[data-artID='${id}']`);
    let artOrigW = document.querySelector(`[data-artID='${id}']`).naturalWidth;
    let artOrigH = document.querySelector(`[data-artID='${id}']`).naturalHeight;
    let maxW = windowW*.9;
    //let ratio = artW/artH;
    let ratioOrig = artOrigW/artOrigH;
    let newW = ratioOrig*remainder;
    if(newW>maxW) {
        newW=maxW;
    }
    let newH = newW/ratioOrig;

    //console.log(ratio,ratioOrig);

    // change art size
    // anime({
    //     targets: `[data-artID='${id}']`,
    //
    //     width: newW,
    //     height: newH,
    //     //delay: 200,
    //     duration: dur,
    //     easing: 'cubicBezier(.15,1.02,.2,.99)',
    //     complete: function() {
    //         //console.log('width changed');
    //     }
    // })

    // // change info size
    // return new Promise((resolve,reject) => {
    //     anime({
    //         targets: `[data-storeArt-info='${id}']`,
    //         // height: infoSetH,
    //         //delay: 200,
    //         duration: dur,
    //         easing: 'cubicBezier(.15,1.02,.2,.99)',
    //         complete: function() {
    //             resolve('resolved');
    //         }
    //     })
    // });

}

function renderArt(content) {
    return new Promise((resolve,reject) => {

        resolve('resolved');
    })

}

function watchScroll(vI) {
    //console.log('hi there');
    $('.store-set').off('wheel scroll ').on('wheel scroll',function(e) {
        //console.log(e.originalEvent.deltaY);
        if(e.originalEvent.deltaY > 60) {
            e.preventDefault();
            enterLoaded(vI+1,vI);
            $('.store-set').off('wheel scroll ')
        }
        if(e.originalEvent.deltaY < -60) {
            enterLoaded(vI-1,vI);
            e.preventDefault();
            $('.store-set').off('wheel scroll ')
        }

        return
    });




    //console.log('watching for scroll',i);
    let el = $('.store-set img'),
    startX,
    startY,
    distX,
    distY,
    minDist = 150,
    minTime = 300,
    time,
    startTime
    el.off('touchstart').on('touchstart',function(e) {
        let touched = e.changedTouches[0];
        startX = touched.pageX
        startY = touched.pageY
        startTime = new Date().getTime()
        e.preventDefault();
    });

    el.off('touchmove').on('touchmove',function() {
        el.off('touchmove');
    })

    el.off('touchend').on('touchend',function(e) {
        let touched = e.changedTouches[0];
        distX = touched.pageX - startX
        distY = touched.pageY - startY
        time = new Date().getTime() - startTime
        if(Math.abs(distY) >= minDist && time <= minTime) {
            //console.log('lets swipe it yall')
            if(distY<0) {
                if(loaded.includes(vI+1)) {
                    enterLoaded(vI+1,vI);
                } else {
                    //loadStoreItem(i+1,false)
                }

            } else {
                if(loaded.includes(vI-1)) {
                    enterLoaded(vI-1,vI);
                } else {
                    //loadStoreItem(i-1,false)
                }

            }
        }

    });


}
