import anime from 'animejs/lib/anime.es.js';
import {showCartHeader, orderDetailstoIndex, waiting,removeWaiting} from '../javascript/index.js';
import { v4 as uuidv4 } from 'uuid';
import {loadStore, orderDetailstoStore} from '../javascript/store.js';
// import {orderDetails} from '../javascript/orderDetails.js';
import {stages,getOrderDetails} from '../javascript/admin.js';
export {orderDetails, addPayPalButtons};
import { nanoid } from 'nanoid'

let cartStoreList = [];
let packageList = [];
let currentCartPage = '';
let passingOrderID = 0;
let thisOrderRecall = [];
let inMotion =  false;
let orderDetails = {}
let testCheckout = true;
let rateDisplay = ``;
let cartPageSet = ['selectionPage','infoPage','orderOverview'];
let cart = orderDetails.cart;


const toFixed = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);

// CART
export function openCart() {
    waiting('.content');
    if(typeof(orderDetails.cart)==='string') {
        console.log('cart is a string')
        orderDetails.cart = JSON.parse(orderDetails.cart);
    }
    $('.modal-blur').remove()
    
    orderDetailstoStore(orderDetails)
    $.when(
        getStoreList(),
        getPackageList(),
        getOrderDetails(),
        orderDetailstoIndex(orderDetails)
    ).done(() => {

        //console.log(orderDetails,packageList);
        $('.subHeader').show().html(`<div class='cart-header-set'></div>`);

        $(".content-set").html(`
            <div class='cartPage'>
                <div class='cartExit'>continue<br>shopping</div>
                <div class='cartPage-set'>

                </div>
                <div class='cartPage-closer'></div>
            </div>`);

        let windowInner = window.innerHeight;
        let cartPageStart = $('.cartPage').offset().top;
        let cartPageHeight = windowInner-cartPageStart;
        if($(window).width() < 600) {
            // console.log('small window')
            $('.content-set').css('height',cartPageHeight);
        } else {
            // console.log('large window')
            $('.content-set').css('height',cartPageHeight);
        }

        //console.log(windowHeight,cartPageStart);

        let url = new URL(window.location.href);
        //console.log(url);
        if(url.searchParams.has('orderStatus') && !orderDetails.edited) {
            let eID = atob(url.searchParams.get('orderStatus'))
            orderStatus(eID);
            currentCartPage = 'orderStatus';
         } else {
            selectionPage();
            currentCartPage = 'selectionPage';
        }
        
        //cartSubTotal();
        cartHeader();

        cartClicks();
    })
    //console.log(orderDetails);






}


// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //

// **************************             GETS 

    function getPackageList() {
        return $.ajax({
            url: "ajax/admin.php?getPackageList",
            success: function(data) {
                packageList = JSON.parse(data);
                return new Promise((resolve,reject) => {
                    resolve();
                })

            }
        });
    }

    function getStoreList() {
        return $.ajax({
            url: "ajax/admin.php?storeList",
            success: function(data) {
                cartStoreList = JSON.parse(data);
                return new Promise((resolve,reject) => {
                    resolve();
                })

            }
        });
    }

//
// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //





// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //

// **************************        RENDER & REMOVE 


    function renderCart(content) {
        //console.log(orderDetails,thisOrderRecall)
        $('.cartPage-set').append(`<div class='confirmPage nextCartPage'>${content}</div>`);
        verifyInfo();
                    
        $('.subHeader').css('margin-left','auto').css('margin-right','auto').css('margin-top','30px')

        console.log('set margins for subheader')
        anime({
            before: function() {
                removeWaiting();
            },
            targets: `.nextCartPage`,
            //translateY: [-20,0],
            opacity: [0,1],
            duration: 900,
            easing: 'cubicBezier(.1,1,.42,1)',
            delay: 200,
            complete: function() {
                
                $('.nextCartPage').removeClass('nextCartPage');
                inMotion = false;
                // console.log('new cart page in')
            }
        })
            
            
            
    }

    function fadeOutCartPage() {
        //$('.confirmPage').not('.nextCartPage').remove();
        if($('.confirmPage').length > 0) {
            $('.confirmPage').not('.nextCartPage').fadeOut(100,function() {
                $('.confirmPage').not('.nextCartPage').remove();
            });
        }

        setTimeout(function() {
            if($('.confirmPage').length > 1) {
                console.log('removing extra frame')
                $('.confirmPage').first().remove()
            }
        },300)
        
    }

//   
// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //




// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //

// **************************      TOTALLING / UPKEEP

    function compilePackaging() {
        //console.log(packageList,cart);
        cart.forEach(ca => {
            let thisStoreItem = cartStoreList.find(sto=>{
                return sto.ID == ca.storeID
            })
            //console.log(thisStoreItem);
            let packID;
            if(ca.canvas=='yes') {
                packID = JSON.parse(thisStoreItem.boxID)
            } else {
                packID = JSON.parse(thisStoreItem.rollID)
            }
            //console.log(packID);
            ca.boxCharge = 0;
            ca.package = 'package';
            let pack = packageList.find(pack => pack.ID == packID)
            //console.log(pack);
            ca.boxCharge = pack.cost
            ca.boxDescr = pack.description
            ca.shippingWeight = pack.weight;
            ca.external_shipment_id = ca.storeID
            Object.assign(ca, {w:pack.w,h:pack.h,l:pack.l})
            //console.log(ca);

        })
        //console.log(cart,orderDetails);
        return new Promise((resolve,reject) => {
            resolve();
        })

    }

    function cartSubTotal() {
        //console.log(orderDetails);
        //orderDetails.subTotal = 0;
        let subTotal = 0;
        let shippingCharge = 0;
        let artTotal = 0;
        let tax = 0;
        orderDetails.cart.forEach((item, i) => {
            artTotal = artTotal + parseInt(item.price);
        });
        if(orderDetails.shipping.shippingCharge) {
            shippingCharge = parseInt(orderDetails.shipping.shippingCharge);
        }
        let dis = 0
		// if(orderDetails.payment.discount) {
		// 	if(orderDetails.payment.discountType=='perc') dis = (1 - orderDetails.payment.discount)*artTotal
		// 	if(orderDetails.payment.discountType=='dollar') dis = orderDetails.payment.subTotal - orderDetails.payment.discount
		// }

        subTotal = artTotal - dis + shippingCharge
        tax = subTotal * 0.07
        let totalCharge = subTotal + tax
        orderDetails.payment.art_total = artTotal
        orderDetails.payment.tax = tax.toFixed(2)
        orderDetails.payment.subTotal = subTotal
        orderDetails.payment.totalCharge = subTotal + tax
        // console.log(artTotal,shippingCharge,subTotal,tax,totalCharge)
		console.log(JSON.parse(JSON.stringify(orderDetails)))
        let back = `<div data-cartDir='down' class='cartNav-arrow' style='margin: 0 15px' ></div>`;
        let forward = `<div data-cartDir='up' class='cartNav-arrow' style='margin: 0 15px' ></div>`;
        let editMode = `<div class='flexc editMode'><p>You are currently editing an order</p>
        <div id='removeEdit' class='button'>Click here to start a new order</div></div>`

        let url = new URL(window.location.href);
        let editing = url.searchParams.has('editID');
        let content = `

                ${back}
                <div class='flexr' style='flex:1;flex-wrap:wrap-reverse'>
                    <div class='flexc' style='flex:1;max-width:200px'>
                        <div class='flexr' style='justify-content:space-between;width:100%'><p>Item(s):</p><p>${orderDetails.cart.length}</p></div>
                        <div class='flexr' style='justify-content:space-between;width:100%'><p>Subtotal:</p><p>$${subTotal.toFixed(2)}</p></div>
                    </div>
                    ${editing ? editMode : ''}
                </div>
                ${forward}
            `;

        $('.cartPage-closer').html(content);
        if(cartPageSet.indexOf(currentCartPage) !== 0) {
            $(`[data-cartDir='down']`).html(`<i style='color:#878787;position:relative'  class="press fas fa-chevron-left fa-2x"></i>`);
        }

        if(cartPageSet.indexOf(currentCartPage) !== 3) {
            $(`[data-cartDir='up']`).html(`<i style='color:#878787;position:relative' class="press fas fa-chevron-right fa-2x"></i>`);
        }

        // tax it
        orderDetails.payment.tax = parseInt(subTotal * 0.07)

        orderDetails.payment.totalCharge = parseInt(subTotal) + orderDetails.payment.tax;
        orderDetails.payment.totalCharge = parseInt(orderDetails.payment.totalCharge.toFixed(2))
        console.log(orderDetails)
        
        return new Promise((resolve,reject) => {
            resolve();
        })
        

    }

    function verifyInfo() {

        let d = orderDetails;
        // console.log('verifying',orderDetails)
        if(d.contact.name && d.contact.email) {
            $(`[data-which='name']`).html($(`
                <div class='side-header side-header-name' >Name</div>
                <p class=''>${d.contact.name}</p><p class='cut-short'>  ${d.contact.email} </p>
            `));
        } else {
            $(`[data-which='name']`).html($(`<p class=''>Enter Your Contact Information Here</p>`));
        }

        if(d.shipping.addressVerified) {
            $(`[data-which='address']`).html($(`
                <div class='side-header side-header-email' >Address</div>
                <p class=''>${d.shipping.street}</p>
            `));

        } else {
            $(`[data-which='address']`).html($(`<p class=''>Enter Your Shipping Address Here</p>`));
        }

        if(d.shipping.chosenRates) {

            $(`[data-which='charge']`).html($(`
                <div class='side-header side-header-email' >Chosen Rate</div>
                <p class=''>${d.shipping.service_type} -  $${parseInt(d.shipping.shippingCharge).toFixed(2)}</p>
            `));
        } else {
            $(`[data-which='charge']`).html($(`<p class=''>Choose Your Shipping Rate Here</p>`));
        }

        if(d.contact.name && d.contact.email && d.shipping.addressVerified && d.shipping.chosenRates) {
            orderDetails.readyToPurchase = true;
            //console.log('verified');
            if($('#allSet').length==0 && currentCartPage=='infoPage') {
                $('.confirmPage').append($(`<h2 id='allSet' style='font-size:16px;margin:30px'>Contact and Shipping information is complete!<br> Please proceed to the Payment Page</h2><div data-cartDir='up' class='cartNav-arrow'><i style='color:#878787;position:relative' class="press fas fa-chevron-right fa-2x"></i></div>`));
                //$("#allSet").fadeIn(2000);
            }

        } else {
            orderDetails.readyToPurchase = false;
            //console.log('not enough info yet')
        }


    }


    function autoClicks() {
        // console.log(orderDetails)
        if(!orderDetails.contact.name || !orderDetails.contact.email) {
            $(`[data-which='name']`).trigger('click')
        } else if(!orderDetails.shipping.addressVerified) {
            $(`[data-which='address']`).trigger('click')
        } else if(!orderDetails.shipping.chosenRates) {
            $(`[data-which='charge']`).trigger('click')
        } else {
            let visible = $('.visible1')
            if(visible.hasClass('charge-reveal')) $(`[data-which='charge']`).trigger('click')
            if(visible.hasClass('address-reveal')) $(`[data-which='address']`).trigger('click')
            if(visible.hasClass('name-reveal')) $(`[data-which='name']`).trigger('click')
            verifyInfo()
        }
    }


    function removeFromCart(removeID) {
        // let thisCart = cart.find((item) => {
        //     return item.storeID == removeID
        // })
        console.log(cart,removeID);
        let thisCartItem = cart.find((item) => {
            return item.ID == removeID
        })
        let artID = thisCartItem.artID;

        cart = cart.filter((item) => {
            return item.ID !== removeID
        });
        orderDetails.cart = cart;

        orderDetails.shipping.chosenRates = false;
        delete orderDetails.shipping.each
        $('.rate-display').empty();
        orderDetailstoStore(orderDetails);
        showCartHeader(cart);
        cartSubTotal();
        selectionPage();
        //$("[data-tr='"+removeID+"']").remove();
    }

    function cartClicks() {
        //console.log('cart clicks ready');
        $('.cartPage, .cart-header').off('click');
        $('.cart-header, .cartPage').on('click','[data-cartNav]',function() {

            let thisWay = $(this).attr('data-cartNav');
            eval(thisWay+'()')
        });

        $(".cartExit").on('click',function() {
            let url = new URL(window.location.href);
            console.log(currentCartPage,orderDetails)
            if(currentCartPage == 'orderStatus') {
                // orderDetails = {cart:[],shipping:{}}
                // tempOrderDetails = {cart:[],shipping:{}}
                console.log(url.origin, url.pathname)
                if(url.origin == 'http://localhost:8888') {
                    window.history.pushState({}, 'hey there', "/ecomm_demo/index.php" + ``)
                } else {
                    window.history.pushState({}, 'hey there', "/index.php" + ``)
                }
                //window.location.reload();
                loadStore();
            } else {
                loadStore();
            }
            
        })

        $('.cartPage').on('click','.cartNav-arrow',function() {
            let thisDir = $(this).attr('data-cartDir');
            let current = cartPageSet.indexOf(currentCartPage);
            //if(current == -1) {return}
            let nextIndex = 0;
            console.log(currentCartPage,current);
            if(thisDir=='up') {
                nextIndex = current+1;
                if(nextIndex > 4) {
                    return
                }
            } else {
                nextIndex = current-1;
                if(nextIndex < 0) {
                    return
                }
            }



           eval(cartPageSet[nextIndex]+'()');


        })

        // print type
        $('.cartPage').on('click','[data-print]',function() {
            let thisCanvas = $(this).data('print');
            let thisID = $(this).data('id');
            let thisCartItem = cart.find((item) => {
                return item.ID == thisID
            });

            if(thisCanvas=='no') {
                thisCartItem.type = 'print on canvas';
                thisCartItem.canvas = 'yes';
                thisCartItem.price = parseInt(thisCartItem.price) + 50
            }
            if(thisCanvas=='yes') {
                thisCartItem.type = 'print';
                thisCartItem.canvas = 'no';
                thisCartItem.price = parseInt(thisCartItem.price) - 50
            }

            orderDetails.shipping.chosenRates = false
            selectionPage();


        });

        $('.cartPage').on('click','#checkAddress',function() {
            checkAddress();

        })

        $('.cartPage').on('click','#checkRates',function() {
            if(orderDetails.shipping.addressVerified) {
                checkRates();
            }
        })

        // cart item REMOVE click
        $('.cartPage').on('click','.cartPage-remove',function() {
            console.log('trying to remove');
            let removeID = $(this).attr('data-id');
            removeFromCart(removeID);
        });

        $('.cartPage').on('keyup','.cartInput',function() {
            let thisEntry = $(this).attr('data-direct');
            let thisProp = $(this).attr('data-prop');
            let thisVal = $(this).val();
            // thisEntry = thisEntry.split('.');
            console.log(thisEntry,thisProp,thisVal)
            if(thisEntry=='shipping' || thisEntry=='contact') {
                orderDetails[thisEntry][thisProp] = thisVal;
            } else {
                orderDetails[thisEntry] = thisVal;
            }


        })

        $('.cartPage').on('click','.text-help',function() {
            if($('.text-help-display').is(':visible')) {
                $('.text-help-display').hide();
                $(".text-help").html('Tell me more');
                $('.text').show();
            } else {
                $('.text-help-display').show();
                $('.text').hide();
                $(".text-help").html('Tell me less');
            }
        });

        $('.cartPage').on('click',`#submitOrder`,function() {
            submitOrder();
        })

        $('.cartPage').on('click',`#editOrder`,function() {
            window.location.replace(window.location.href.split('?')[0] + `?orderStatus&editID=${btoa($(this).attr('data-id'))}`)
        })

        $('.cartPage').on('click',`#removeEdit`,function() {
            window.location.replace(window.location.href.split('?')[0])
        })

        $('.cartPage').on('click',`#cancelOrder`,function() {
            let id = $(this).attr('data-id')
            console.log(id)
            updateOrder(id,'cancelled','','stage','customer','');
            window.location.reload()
        })

        $('.cartPage').on('click','.cart-expand',function() {

            let which = $(this).attr('data-which');
            //console.log(which);

            // COMPILE PACKAGING COSTS WHEN SHIPPING OPENS
            if(which=='charge') {
                compilePackaging();
                if(!orderDetails.shipping.addressVerified) {
                    $('#checkRates').css('font-size','10px').html('Enter & Verify Shipping Address First');
                    $(`[data-which='address']`).css('border','1px solid red');

                }
            }

            anime({
                targets: '.visible1',
                height: 0,
                marginTop: 0,
                opacity: {
                    value: 0,
                    duration: 400,
                    easing: 'cubicBezier(.33,.01,0,1.01)'
                },
                duration: 300,
                easing: 'cubicBezier(.33,.01,0,1.01)',
                complete: function() {
                    $('.visible1').removeClass('visible1');
                    verifyInfo();

                }
            })
            anime({
                targets: '.visiblePlus',
                duration: 300,
                easing: 'cubicBezier(.33,.01,0,1.01)',
                rotateZ: '-=180deg',
                complete: function() {
                    $('.visiblePlus').removeClass('visiblePlus')
                }
            })


            if($('.'+which+'-reveal').hasClass('visible1')) {
            } else {
                $('.'+which+'-reveal').css('height','auto');
                let height = $('.'+which+'-reveal').height();

                $('.'+which+'-reveal').css('height','0px');

                if(height<150) {height = 150}
                
                anime({
                    targets: `#plus-`+which,
                    duration: 300,
                    rotateZ: '+=180deg',
                    //transform: 'rotateZ(360deg)',
                    easing: 'cubicBezier(.33,.01,0,1.01)',
                    complete: function() {
                        //console.log('trying to spin it'),
                        $('#plus-'+which).addClass('visiblePlus')
                    }

                })

                anime({
                    before: function() {
                        $('.side-header-'+which).hide();
                    },
                    targets: '.'+which+'-reveal',
                    marginTop: 30,
                    height: {
                        value: [0,height],
                        duration: 400,
                        easing: 'linear'
                    },
                    opacity: [0,1],
                    duration: 300,
                    easing: 'cubicBezier(.33,.01,0,1.01)',
                    complete: function() {
                        $('.'+which+'-reveal').addClass('visible1');
                        $('.'+which+'-reveal').find('input').filter(':visible:first').focus();
                        
                    }
                })
            }



        })

        

    }

    function adjustCartItemHeight(which) {
        $('.'+which+'-reveal').css('height','auto');
        let height = $('.'+which+'-reveal').height();
        $('.'+which+'-reveal').css('height','');
        // console.log(height);
        anime({
            before: function() {
                // console.log(height)
            },
            targets: '.'+which+'-reveal',
            height: height,
            opacity: 1,
            duration: 300,
            easing: 'cubicBezier(.33,.01,0,1.01)',
            complete: function() {
                //$('.'+which+'-reveal').addClass('visible');
                // console.log(height)
            }
        })
    }

    // spread cart info to index
    
    orderDetailstoIndex(orderDetails);

    export function orderDetailsToCart(updated) {
        orderDetails = updated;
        // console.log(orderDetails);
    }

//
// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //






// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //

// **************************            HEADER

    let last = '';
    let previous = '';
    function cartHeaderAnimate(which) {
        let totalW = $(".cart-header").width();
        let focus = $(`[data-cartNav='${which}']`);

        $(`[data-cartNav-H]`).css('color','#000');

        let currentIndex = cartPageSet.indexOf(which);

        let backContent, forwardContent;


        backContent = `<i class="far fa-chevron-left"></i>`;
        let backIndex = cartPageSet.filter((item,i) =>  i == currentIndex-1)
        
        backIndex.forEach((item, i) => {
            backContent += eval('cartName'+item)
        })
        


        let forwardIndex = cartPageSet.filter((item,i) =>  i == currentIndex+1)
        
        forwardIndex.forEach((item, i) => {
            forwardContent = eval('cartName'+item)
        })
        
        forwardContent += `<i class="far fa-chevron-right"></i>`;

        if(currentIndex == 0) {backContent = '';}
        if(currentIndex == 2 || orderDetails.cart.length == 0) {forwardContent = '';}



        anime({
            targets: '#back, #forward, .cart-mainHeader',
            opacity: 0,
            duration: 100,
            easing: 'cubicBezier(.1,1,.42,1)',
            complete: function() {
                $("#back").html(backContent);
                $("#forward").html(forwardContent);
                $(".cart-mainHeader").html(eval('cartName'+which));
                anime({
                    targets: '#back, #forward, .cart-mainHeader',
                    duration: 200,
                    easing: 'cubicBezier(.1,1,.42,1)',
                    opacity: [0,1],
                    delay: anime.stagger(50,{start:0})
                })
            }
        })


        previous = which;
    }

    function cartHeader() {
        let content = `
                <div class='cart-header'>
                    <div class='cart-header-sub' id='back'></div>
                    <div class='cart-mainHeader'></div>
                    <div class='cart-header-sub' id='forward'></div>
                </div>
            `;
        $('.subHeader').html(content);
    }


//    
// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //







// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //

// **************************             PAGES

    function selectionPage() {
        if(jQuery.isEmptyObject(orderDetails)) {
            orderDetails = JSON.parse(JSON.stringify(thisOrderRecall))
        } 
        //orderDetails.cart = ;
        
        
        last = '';
        let content = ``;
        //orderDetails.cart = cart;
        cart = orderDetails.cart;
        //console.log(orderDetails);
        if(cart.length == 0) {
            content += `<h2>Cart is empty</h2>`;

        } else {
            //let artNames = uniqueStore(cart, 'title');
            content += `<div data-cartDir='up' class='cartNav-arrow flexr' style='margin:10px auto 40px;cursor:pointer;'><h3 style='font-size:90%;line-height:1.6;width:65%'>Review your selection then enter your personal info on the next page</h3><div ><i style='color:#878787;position:relative' class="press fas fa-chevron-right fa-2x"></i></div></div>`;
            //console.log(artNames);
            let cartArtList = [];

            cart.forEach((item, i) => {
                if(!cartArtList.includes(item.artID)) {
                    cartArtList.push(item.artID);
                }
            });
            // console.log(cartArtList);

            cartArtList.forEach((artID, i) => {
                let thisCartArt = cart.find((item) => {
                    return item.artID == artID
                })
                content += ``;
                // console.log(thisCartArt);
                content += `

                <div class='confirmPage-item'>
                    <div data-cartArtHeader='${thisCartArt.artID}' valign='middle' style='' class='flexr header'>
                        <h3>${thisCartArt.title}</h3>
                        <div style='height:100%'>
                            <img src='https://www.ecomm.bradwrightwebdev.com/assets/images/${thisCartArt.fileName}' width='100'>
                        </div>
                    </div>
                </div>

                    `;
                let thisCartList = cart.filter((item) => {
                    return item.artID == artID
                })
                thisCartList.forEach((cartItem, i) => {
                    let thisStoreItem = cartStoreList.filter((storeItem) => {
                        return cartItem.storeID == storeItem.ID
                    });
                    //console.log(thisStoreItem,cartItem.storeID);
                    let type='';  let thisType=''; let print=''; let printCan=''; let text=''; let size=''; let clickable = ``;
                    //console.log(thisStoreItem);
                    // if(thisStoreItem.length > 0) {
                    //     thisType = thisStoreItem[0].type;

                    //     if(thisType=='original') {
                    //         text = 'original'; size = 'font-size:12px'; clickable = '';
                    //     } else {
                    //         if(thisType=='print') {
                    //             clickable = `data-print='${cartItem.canvas}'`;
                    //             if(cartItem.canvas=='no') {
                    //                 text = 'print'; print = 'chosen'; printCan = 'unchosen press'; size = 'font-size:12px';
                    //             } else if(cartItem.canvas=='yes') {
                    //                 text = 'print on<br>canvas'; print = 'press unchosen'; printCan = 'chosen'; size = 'font-size:12px'
                    //             }
                    //         }
                    //     }
                    // }

                    content += `
                        <div class='confirmPage-item'>
                            <div data-tr='${cartItem.ID}' data-cartArtItem='${cartItem.artID}' class='flexr cart-item-header cart-expand' >
                                <div style='width:20%'><p>${cartItem.size}</p></div>
                                <div style='width:40%' class='type-set'>
                                    <div ${clickable} style='${size}' data-id='${cartItem.ID}' class='print-button '>${cartItem.type}</div>
                                </div>
                                <div style='width:25%' ><p>$${cartItem.price}</p></div>
                                <div style='width:15%'><div data-id='${cartItem.ID}' class='cartPage-remove'>X</div></div>
                            </div>
                        </div>
                        `;
                });




            });

            // Show print upgrade info IF there's one in the cart
            // if(orderDetails.cart.some((c) => c.type == 'print')) {
            //     content += `
            //         <div class='upgrade-offer-set'>
            //             <div class='text'>Click on the 'print' button above<br>to upgrade to a canvas print</div>

            //             <div style='display:none' class='text-help-display'>
            //                 A Print uses really great paper and will arrive rolled up in a tube.

            //                 A Print on Canvas is the more elegant option and requires shipping adjustments
            //             </div>
            //             <div class='press text-help'>Tell me more</div>
            //         </div>`;
            // }
            
            
            
        }
        cartSubTotal();
        showCartHeader(orderDetails.cart);
        cartHeaderAnimate('selectionPage');
        fadeOutCartPage();
        renderCart(content);
        setTimeout(autoClicks,2000)
    }



    function infoPage() {
            //console.log(orderDetails);
            let content = `
            <div class='confirmPage-item'>
                <div data-which='name' class='press cart-item-header cart-expand '><p>Enter Your Contact Information Here</p></div>
                <div class='yourEmail name-reveal update await-reveal'>
                    <input class='cartInput' data-direct='contact' data-prop='name' name='name' type='text' placeholder='Full Name' value='${orderDetails.contact.name ? orderDetails.contact.name: ''}'>
                    <input class='cartInput' data-direct='contact' data-prop='email' name='email' type='email' placeholder='Email Address' value='${orderDetails.contact.email ? orderDetails.contact.email: ''}'>
                    <!-- <p style='max-width:200px'>**this will only be used to confirm order details**</p> -->
                </div>

            </div>


            `;

            let totalWeight = 0;
            orderDetails.cart.forEach((item, i) => {
                totalWeight = parseInt(totalWeight) + parseInt(item.shippingWeight)
            });
            let shipVerify = ''; let chargeVerify = '';
            if(orderDetails.shipping) {
                orderDetails.shipping.addressVerified ? shipVerify = 'allSet' : shipVerify = '';
                orderDetails.shipping.chosenRates ? chargeVerify = 'allSet' : chargeVerify = '';
            }
            let display = '';
            if(orderDetails.street) {
                display = `
                    <p class=''>Address</p>
                    <p style=''>${orderDetails.street}</p>
                `;
            } else {
                display += `<p class=''>Address</p>`;

            }
            content += `


                <div data-shipItem='address' class='yourAddress confirmPage-item shipPage-item ${shipVerify}'>
                    <div data-which='address' class='press cart-item-header cart-expand shipItem-header'>
                        <p>Enter Your Shipping Address Here</p>
                    </div>

                    <div  class='address-reveal await-reveal'>
                        <div id='addressInputPane' style='width:100%'>
                            <input id='nameShipping' style='width:70%;' class='cartInput' data-direct='shipping' data-prop='name' name='nameShipping' type='text' value='${orderDetails.shipping.nameShipping || orderDetails.contact.name || ''}' placeholder='name'>
                            <input id='street' style='width:70%;' class='cartInput' data-direct='shipping' data-prop='street' name='street' type='text' value='${orderDetails.shipping.street || ''}' placeholder='street'>
                            <div class='flexr'>
                                <input id='city' style='width:150px' class='cartInput' data-direct='shipping' data-prop='city' name='city' type='text' value='${orderDetails.shipping.city || ''}' placeholder='city'>
                                <input id='state' style='width:40px' class='cartInput' data-direct='shipping' data-prop='state' name='state' type='text' value='${orderDetails.shipping.state ||''}' placeholder='state'>
                                <input id='zip' style='width:80px' class='cartInput' data-direct='shipping' data-prop='zip' name='zip' type='text' value='${orderDetails.shipping.zip || ''}' placeholder='zip'>
                            </div>
                            <p style='margin: 10px 0;font-size:10px'>Before shipping I need to verify the address you entered</p>
                            <div id='checkAddress' class='button'>Verify Address</div>
                        </div>
                        <div id='verifyAddressPane' class=''></div>
                    </div>
                </div>

                <div data-shipItem='charge' class='shipPage-item confirmPage-item ${chargeVerify}'>
                    <div data-which='charge' class='press cart-item-header cart-expand shipItem-header'><p>Choose Your Shipping Rate Here</p></div>

                    <div  class='charge-reveal await-reveal '>
                        <div id='checkRates' class='press button'>Check Rates</div>
                        <div class='rate-display'>${rateDisplay || ''}</div>
                    </div>
                    
                </div>




            `;
            
            cartSubTotal();
            showCartHeader(orderDetails.cart);
            cartHeaderAnimate('infoPage');
            fadeOutCartPage();
            renderCart(content);
            currentCartPage = 'infoPage';
            setTimeout(verifyInfo,500);
            
    }

    function orderStatus(id,orderInfo) {
        
        waiting('.cartPage')
        currentCartPage = 'orderStatus'
        let thisOrderDetails = [];
        passingOrderID = 199;
        let orderID = 0
        let url = new URL(window.location.href);
        if(id) {
            orderID = id
        } else {
            orderID = url.searchParams.get('orderStatus') || url.searchParams.get('editID')
            orderID = atob(orderID)
        }
        
        let total = 0;
        console.log(orderID,id,orderInfo);

        function collectDetails() {
            return $.ajax({
                type: 'POST',
                url: "ajax/shipping.php?getOrderDetails",
                data: {
                    orderID
                },
                success: function(data) {
                    thisOrderDetails = JSON.parse(data);
                    thisOrderDetails = thisOrderDetails.sort((a,b) => {
                        if(a.stamp > b.stamp) return -1 
                        if(a.stamp < b.stamp) return 1
                    })
                    thisOrderDetails = thisOrderDetails[0]
                    console.log(thisOrderDetails,orderID);
                    return new Promise((resolve,reject) => {
                        resolve();
                    })
                }
               
            });
        }

        // receiving payment
        if(url.searchParams.has('ppId')) {
            let ppID = url.searchParams.get('ppId');
            ppID = atob(ppID);
            console.log(ppID,orderID);
            let edited = ['payment'];

            collectDetails()
            .then(function() {
                let thisOrder = thisOrderDetails
                
                thisOrder.content = JSON.parse(thisOrder.content)
                thisOrder.content.payment.current.received = true
                thisOrder.content.payment.current.amount_received = thisOrder.content.payment.current.total
                thisOrder.content.payment.current.method = 'paypal'
                thisOrder.content.payment.current.number = ppID
                thisOrder.content.payment.feed.unshift(
                    {
                        stamp: new Date(),
                        by: 'customer',
                        action: 'payment received',
                        method: 'paypal',
                        number: ppID,
                        amount: thisOrder.content.payment.current.amount_received
                    }
                )
                thisOrder.content.stages.push({stamp:new Date(),by:'customer',stage:'payment received',index:1})
                console.log(JSON.parse(JSON.stringify(thisOrder)))
                window.history.pushState({}, 'hey there', "/" + `index.php?orderStatus=${btoa(orderID)}`);
                // updateOrder(orderID,'payment received',ppID,'stage','customer','paypal');
                updateOrder(orderID,JSON.stringify(edited),JSON.stringify(thisOrder.content),'update','customer','payment received').then(function() {
                    $.ajax({
                        type: 'POST',
                        url: "ajax/shipping.php?confirmPayment",
                        data: {
                            orderDetails: thisOrder.content,
                            last: btoa(orderID),
                            name: thisOrder.content.contact.name,
                            email: thisOrder.content.contact.email
                        },
                        success: function(data) {
                            console.log(data)
                            removeWaiting()
                            orderStatus(orderID)
                        }
                    });
    
                })

            })
            // location.reload();
        } else {
    
    
            $.when(
                collectDetails(),
            ).done(function() {
                let edited = []
                let thisOrder = thisOrderDetails
                thisOrder.content = orderDetails = JSON.parse(thisOrder.content)
                let thisStage = stages(thisOrder).find(s => s.stage == thisOrder.content.stages[thisOrder.content.stages.length - 1].stage)
                console.log(thisStage)
                let nextStage = [];
                if(thisOrder.content.messages) {
                    console.log(JSON.parse(JSON.stringify(thisOrder)))
                    thisOrder.content.messages.forEach(m=>{
                        if(m.content == 'duration') {
                            let diff = new Date() - new Date(m.stamp)
                            if(diff < 0) m.view = false
                        }
                        if(m.content == 'stage') {
                            if(m.stage !== thisStage.index) m.view = false
                        }
    
                    })
                }
                // log view
                // if(!url.searchParams.has('admin') || url.searchParams.get('admin')!=='true') {
                //     if(thisOrder.content.hasOwnProperty('views')) {
                //         // console.log('add to views obj')
                //         thisOrder.content.views.unshift({stamp:new Date()})
                //         edited.push('views')
                //     } else {
                //         // console.log('creating new views obj')
                //         thisOrder.content.views = [{stamp:new Date()}]
                //         edited.push('views')
                //     }
                // }
                
                // customer update
                if(url.searchParams.has('editID')) {
                    orderDetails = typeof(thisOrder.content)=='object' ? thisOrder.content : JSON.parse(thisOrder.content)
                    orderDetails.orderID = thisOrder.orderID
                    orderDetailstoStore(orderDetails)
                    selectionPage(); 
                    return
                }
    
                
                // start content
                let content = `
                    <div class='os-set'>
                    <h2 style='background:${$('header').css('background-color')}' class='os-header'>Order Information</h2>
                    <div class='os-body'>
                `;
                //console.log(typeof(thisStage.status_text))
                if(typeof(thisStage.status_text)=='object') {
                    thisStage.status_text.forEach(s=> {
                        content += `${s}`;
                    })
                } else if(typeof(thisStage.status_text)=='function') {
                    let text = thisStage.status_text()
                    text.forEach(t=>{
                        content += `${t}`
                    })
                    
                } else {
                    content += `${thisStage.status_text}`;
                }
                
                content += `<div class='mess-set'></div>`
                content += `<p>If you have any questions or concerns
                please don't hesitate to email me at brad@bradwrightwebdev.com</p>`
                content += `</div></div>` // end os-body and os-set
                
                renderCart(content);
    
                function displayMessages(messages) {
                    console.log('starting display of messages')
                    let messContent = ``;
                    if(messages) {
                        messContent += `<h3>Messages</h3>`
                        messages.forEach(m=>{
                            // console.log(m)
                            if(m.view == true) {
                                let diff = new Date() - new Date(m.stamp) 
                                if(diff>0) diff = diff / (1000*60*60*24)
                                messContent += `
                                <div class='mess-item'>
                                <div class='mess-from'>from ${m.from=='admin' ? 'dev' : 'you'}</div>
                                <div class='mess-body'>${m.body}</div>
                                <div class='mess-action'>
                                    ${m.removeMethod=='user' && m.content=='removes' ? `<div data-id='${m.id}' style='background:#878787' class='hideMessage button'>Remove Message</div>` : ''}
                                    ${m.removeMethod=='duration' ? `Message will be visible for ${Math.ceil(diff)} more day(s)` : ''}
                                    ${m.content=='views' ? `This message will only appear once` : ''}    
                                `
                                m.removeMethod=='user' && m.content=='views' ? m.view = false : ''
                                messContent += `</div><hr></div>`
        
                            }
                        })
                    }
                    
                    messContent += `
                    <div style='margin-top:30px' class='mess-start button'>Add a message for Brad</div>
                    <div class='hide mess-form'>
                        <textarea id='custMess' style='width:80%;height:100px;padding:15px;text-align:center;border-radius:15px' placeholder='message to dev'></textarea>
                        <div class='postMessage button'>Post Message</div>
                    </div>
                    `
                    console.log('rending content')
                    $('.mess-set').html(messContent)
    
                    $('.postMessage').on('click',function() {
                        console.log('button clicked')
                        $(".mess-form").toggleClass('hide',false)
                        if(!edited.includes('message')) edited.push('message')
                        let body = $('#custMess').val()
                        let obj = {
                            body: body,
                            stamp: new Date(),
                            view: true,
                            from: 'customer',
                            removeMethod: 'user',
                            content: 'removes',
                            id: nanoid()
                        }
                        messages.push(obj)
                        thisOrder.content.messages = messages
                        console.log(thisOrder)
                        updateOrder(orderID,JSON.stringify(edited),JSON.stringify(thisOrder.content).replace(/[\/\(\)\']/g, "&apos;"),'update','customer','new message')
                        displayMessages(messages)
                    })
        
                    $('.hideMessage').on('click',function() {
                        if(!edited.includes('message')) edited.push('message')
                        let messID = $(this).attr('data-id')
                        let mess = messages.find(m=>m.id==messID)
                        mess.view = false
                        thisOrder.content.messages = messages
                        updateOrder(orderID,JSON.stringify(edited),JSON.stringify(thisOrder.content).replace(/[\/\(\)\']/g, "&apos;"),'update','customer','removed message')
                        displayMessages(messages)
                    })

                    $('.mess-start').on('click',function() {
                        $('.mess-form').toggleClass('hide')
                    })
                }
                if(thisOrder.content.messages) displayMessages(thisOrder.content.messages)
                if(!thisOrder.content.messages) displayMessages([])
    
                // log customer view
                // if(edited.includes('views')) {
                //     updateOrder(orderID,JSON.stringify(edited),JSON.stringify(thisOrder.content),'update','customer','customer viewed')
                // }
    
                fadeOutCartPage();
                $('.cart-header').empty();
                currentCartPage = 'orderStatus';
                removeWaiting();
                
    
                
                // FUNCTIONS
                
                // buttons
    
                // if(parseInt(thisStage.index)<3) {
                //     let bC = `<div class='button-set flexr'>`
                //     bC += `
                //         <div data-id='${orderID}' id='editOrder' class='button'>Update Order</div>
                //         <div data-id='${orderID}' id='cancelOrder' class='button'>Cancel Order</div>`
    
                //     bC += `</div>`
                //     $('#statusButtons').html(bC)
                // }
                console.log(thisOrder,thisStage)
                if(!thisOrder.content.payment || thisStage.index==0) {
                    console.log('no payment yet!')
                    needPayment();
                }
                // generate payment options
                function needPayment() {
                    console.log('generating buttons and such')
                    if(testCheckout) {
                        total = .01;
                    } else {
                        total = parseInt(thisOrder.content.payment.totalCharge).toFixed(2);
                    }
                    console.log(total)
                    addPayPalButtons(total,orderID)
    
                    function ffCheckout() {
                        console.log(testCheckout)
                        let ffContent = '';
                        if(testCheckout) {
                            ffContent += `<h4 style='margin:10px 0 20px'>This is currenty a test purchase of 1 cent</h4>`;    
                            ffContent += `<p >Click here to make this a real purchase of $${parseInt(thisOrder.content.payment.current.total).toFixed(2)}</p>`;
                        } else {
                            ffContent += `
                            <h4 style='margin:10px 0 20px'>This is currenty a real purchase of $${parseInt(thisOrder.content.payment.current.total).toFixed(2)}</h4>
                            <p>Click here to make this a test purchase of $.01</p>`;
                        }
                        //console.log(ffContent);
                        $('#ffWindow').html(ffContent);
                    }
    
                    setTimeout(ffCheckout,400);
                }
    
                $('.cartPage').off('click').on('click','#ffWindow',function() {
                    $('#paypalinsert').remove();
                    $('#smart-button-container').remove();
                    // let thisID = $(this).attr('data-id');
                    testCheckout = !testCheckout
                    console.log(testCheckout);
                    orderStatus(orderID);
                })
    
                
            })
        }
        
       
               
           
       
    }


    
    function orderOverview() {
        console.log(orderDetails);
        let subTotal = orderDetails.payment.subTotal
        orderDetails.payment.art_total = 0
        cart.forEach(c=>{
            orderDetails.payment.art_total = orderDetails.payment.art_total + parseInt(c.price)
        })
        let shipping = orderDetails.shipping.chosenRates
        console.log(shipping)
        let shippingCharge = parseInt(orderDetails.shipping.shippingCharge)
        let tax = orderDetails.payment.tax
        orderDetails.payment.totalCharge = subTotal + tax;
        if(!orderDetails.uuid) {
            orderDetails.uuid = uuidv4();
        }


        let content = `
        <div class='confirmPage-item'>
            <div  data-which='selection' class='press cart-item-header cart-expand ' ${!orderDetails.cart.length ? `data-cartNav='selectionPage'` : ''}>
                <div id='plus-selection' class='paymentPage-viewMore'>+</div>
                    <p>Art Selection(s)</p>
                    <p>$${orderDetails.payment.art_total.toFixed(2)}</p>
            </div>
            <div class='flexc await-reveal selection-reveal' >`
                orderDetails.cart.forEach((item, i) => {
                    content += `
                    <div style='justify-content:space-between' class='reveal-item flexr'>
                        <p style='max-width:30%'>${item.title}</p>
                        <p>${item.size}</p>
                        <p>${item.type}</p>
                        <p>$${item.price}</p>
                    </div>`;
                }); content += `
            </div>
        </div>
        <div class='confirmPage-item'>
            <div data-which='shipping' id='showShipping' class='press cart-item-header cart-expand ' ${!shipping ? `data-cartNav='infoPage'` : ''}>
                ${shipping ? `<div id='plus-shipping' class='paymentPage-viewMore'>+</div>` : ''}
                    <p>Shipping</p>
                    <p>$${shipping ? shippingCharge.toFixed(2) : ''}</p>
            </div>
            <div class='flexc await-reveal shipping-reveal' >
                    <div class='reveal-item flexr'>
                        <h4>${orderDetails.shipping.service_type}</h4>
                        <h4>$${parseInt(orderDetails.shipping.shippingCharge).toFixed(2)}</h4>
                    </div>
                    <div style='width:100%' class='flexc'>`;
                    if(orderDetails.shipping.each) {
                        orderDetails.shipping.each.forEach((ca) => {
                            let cartItem = orderDetails.cart.find((item) => {
                                return item.ID == ca.cartID
                            })
                            let packaging = (cartItem && cartItem.boxCharge) || ''
                            let shipping = ca.shipping_amount;
                            let itemTotal = parseInt(packaging) + parseInt(shipping);
                            //console.log(packaging,shipping,itemTotal)
                            content += `
                            <div class='flexr item'><p>${cartItem.title}</p><p>${cartItem.size}</p></div>
                            <div class='flexr item'><p>${cartItem.type}</p></div>
                            <div class='flexr item'><p>Packaging:</p><p>$${parseInt(packaging).toFixed(2)}</p></div>
                            <div class='flexr item'><p>Shipping Charge:</p><p>$${parseInt(shipping).toFixed(2)}</p></div>
                            <div class='flexr item'><p>Total:</p><p>$${parseInt(itemTotal).toFixed(2)}</p></div>
                            <hr>
                            `;
                        })
                    }
                    content += `</div>
            </div>
        </div>
        <div class='confirmPage-item'>
            <div id='' data-which='tax' class='cart-item-header cart-expand '>
                <p>Tax</p>
                <p>$${orderDetails.readyToPurchase ? tax.toFixed(2) : ''}</p>
            </div>

        </div>
        <div class='confirmPage-item'>
            <div id='tryPayPal' class='cart-item-header cart-expand '>
                    <p>Total</p>
                    <p>$${orderDetails.readyToPurchase ? orderDetails.payment.totalCharge.toFixed(2) : ''}</p>
            </div>
        </div>
        <div id='checkOut-set' class='confirmPage-item'>`;
        let url1 = new URL(window.location.href);
        if(orderDetails.readyToPurchase==false) {
            content += '<p>We need more info before checkout</p>'
        } else if(url1.searchParams.has('editID')) {
            content += `<div id='submitOrder' class='button'>Update Order</div>`
        } else {
            orderDetails.readyToPurchase ? content += `<div id='submitOrder' class='button'>Submit Order</div>` : ''
        }
            
        content += `</div>`;

        fadeOutCartPage();
        cartHeaderAnimate('orderOverview');
        $('.cartPage-closer').empty();
        
        renderCart(content)

    }


//
// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //



    function updateOrder(editID,field,content,action,action_by,note) {
        // console.log('updating order')
       // console.log(editID,field,content,action,action_by)
        return $.ajax({
            type: 'POST',
            url: "ajax/admin.php?updateOrder",
            data: {
                editID,
                field,
                content,
                action,
                action_by,
                note
            },
            success: function(data) {
                console.log(data);
                return new Promise((resolve,rej) => {
                    // console.log('resolving')
                    resolve(data)
                })
            }
        });
    }

    function submitOrder() {
        //${orderDetails.totalCharge}
        
        waiting();
        console.log(orderDetails);
        
        // save raw
            // $.ajax({
            //     type: 'POST',
            //     url: "ajax/shipping.php?saveRaw",
            //     data: {
            //         raw: JSON.stringify(orderDetails),
            //         email: orderDetails.email,
            //         name: orderDetails.name,
            //         uuid: orderDetails.uuid
            //     },
            //     success: function(data) {
            //         orderDetails.saveRaw = true
            //     }
        // });


        // Update Order
        let url1 = new URL(window.location.href);
        console.log(url1)
        if(url1.searchParams.has('editID')) {
            let eID = atob(url1.searchParams.get('editID'))
            delete orderDetails.cart_string
            delete orderDetails.ship_string
            updateOrder(eID,'',JSON.stringify(orderDetails),'update','customer','').then(function() {
                window.location.replace(window.location.href.split('?')[0] + `?orderStatus=${btoa(eID)}`)
            })
        } else {
            let newID = nanoid()
            orderDetails.stages = [{stamp: new Date(),stage:'created',index:0,by:'customer'}]
            orderDetails.payment = {
                current: {
                    amount_received: 0,
                    received: false,
                    total: orderDetails.payment.totalCharge,
                    discount: 0,
                    discountType: '',
                    shippingCharge: orderDetails.payment.shippingCharge,
                    art_total: orderDetails.payment.art_total,
                    subTotal: orderDetails.payment.subTotal,
                    tax: orderDetails.payment.tax
                },
                feed:[
                    {
                        stamp: new Date(),
                        by: 'customer',
                        action: 'order submitted',
                        amount: orderDetails.payment.totalCharge
                    }
                ]
            }
            
            // email grace
            $.ajax({
                type: 'POST',
                url: "ajax/shipping.php?notifyGrace",
                data: {
                    orderDetails,
                    last: linkID,
                    name: orderDetails.contact.name
                },
                success: function(data) {
                    console.log(data)
                }
            });

            // email customer
            $.ajax({
                type: 'POST',
                url: "ajax/shipping.php?confirmWithCustomer",
                data: {
                    orderDetails,
                    last: linkID,
                    email: orderDetails.contact.email
                },
                success: function(data) {

                }
            });

            
            updateOrder(newID,'created',JSON.stringify(orderDetails),'stage','customer','order created').then(function(mess) {
                showCartHeader({})
                window.history.pushState({}, 'hey there', window.location.href + `?orderStatus=${btoa(newID)}`);
                orderStatus(newID,orderDetails);
            })
            // save Order - then EMAILS
           
                    
            let linkID = btoa(newID)
                    

            
            

            

            
           
        }


    }

    function errorSubmission() {
        console.log('something went terribly wrong');
    }

    


// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //

// **************************           API'S

    function checkRates() {
        console.log(orderDetails);
        let rateResponse = [];
        $('.rate-display').css('height','200px')
        waiting('.rate-display');
        let i=0;
        orderDetails.cart.forEach((item) => {
            console.log(i)
            $.ajax({
                type: 'POST',
                url: "ajax/shipping.php?checkRates",
                data: {
                    item,
                    orderDetails
                },
                success: function(data) {
                    i++;
                    let temp = JSON.parse(data);
                    temp.cartItemID = item.ID;
                    temp.boxCharge = item.boxCharge 
                    rateResponse.push(temp);
                    if(i == orderDetails.cart.length) {
                        displayRates(rateResponse);
                    } 
                }
            })
        })

        function displayRates(rateResponse) {
            let shippingServices = ['ups_ground', 'usps_priority_mail', 'usps_priority_mail_express','ups_3_day_select','ups_2nd_day_air'];
            //console.log(ratesQuote);
            console.log(rateResponse);
            $('#checkRates').html('Choose Rate Below');
            let display = `<table id='rateTable' style=''></table>`;
            $('.rate-display').html(display);
            let rateDisplay = [];
            shippingServices.forEach((service) => {
                let obj = {service_code:service,totalCharge:0,each:[],id:uuidv4()};
                let i = 0;
                rateResponse.forEach((rate) => {
                    let thisService = rate.rate_response.rates.find((ra) => {
                        return ra.service_code == service
                    })
                    if(thisService) {
                        //console.log(thisService);
                        
                        obj.totalCharge = obj.totalCharge + thisService.shipping_amount.amount + parseInt(rate.boxCharge)
                        obj.service_type = thisService.service_type;
                        let eachObj = {
                            shipment_id: rate.rate_response.shipment_id,
                            rate_id: thisService.rate_id ,
                            rate_request_id: rate.rate_response.rate_request_id,
                            shipping_amount: thisService.shipping_amount.amount,
                            cartID: rate.cartItemID,
                            boxCharge: rate.boxCharge
                        }
                        obj.each.push(eachObj);
                        i++
                        if(i==rateResponse.length) {
                            
                            rateDisplay.push(obj);
                            let line = `
                                <tr class='press rate-item' data-id='${obj.id || ''}'>
                                    <td style='text-align:left'>${obj.service_type}</td>
                                    <td style='text-align:right' data-totalCharge='${obj.totalCharge.toFixed(2)}'>$${obj.totalCharge.toFixed(2)}</td>
                                </tr>
                            `;
                            $('#rateTable').append(line)
                            removeWaiting();
                        }

                    }
                })
            })
            console.log(rateDisplay);
            adjustCartItemHeight('charge');

            // click on rate... 
            $('[data-id]').on('click',function() {

                let rate_id = $(this).attr('data-id');
                let thisRate = rateDisplay.find(rate => rate.id == rate_id)
                
                orderDetails.shipping.shippingCharge = thisRate.totalCharge;
                orderDetails.shipping.service_code = thisRate.service_code
                orderDetails.shipping.service_type = thisRate.service_type
                orderDetails.shipping.chosenRates = true;
                orderDetails.payment.shippingCharge = thisRate.totalCharge
                orderDetails.shipping.each = thisRate.each
                cartSubTotal()
                console.log(orderDetails,thisRate);
                autoClicks()
            })

            
        }
    }

    function checkAddress() {
        waiting('.address-reveal')
        let street = $("#street").val();
        let city = $("#city").val();
        let state = $("#state").val();
        let zip = $("#zip").val();
        if(street && city && state && zip) {
            $(`#addressInput`).hide();
            $.ajax({
                type: 'POST',
                url: "ajax/shipping.php?addressValidate",
                data: {
                    street,
                    city,
                    state,
                    zip
                },
                success: function(data) {
                    removeWaiting();
                    let addressValidate = JSON.parse(data);
                    addressValidate = addressValidate[0];
                    // let oldHTML = $('.address-reveal').html()
                    // $('.confirmAddress').remove()
                    $('#addressInputPane').hide()
                    $('#verifyAddressPane').show().html(`
                        <p>${addressValidate.matched_address.address_line1}</p>
                        <p>${addressValidate.matched_address.city_locality} ${addressValidate.matched_address.state_province}</p>

                        <p>${addressValidate.matched_address.postal_code}</p>
                        <p style='color:red'>Does this address look correct?</p>
                        <div id='confirmAddress' class='button'>Confirm</div>
                        <div style='background:#878787;font-size:12px;padding:2px 10px' id='returnAddress' class='button'>Try again</div>
                    `);

                    addressHeight()
                    

                    $('#confirmAddress').on('click',function() {
                        orderDetails.shipping.street = addressValidate.matched_address.address_line1;
                        orderDetails.shipping.city = addressValidate.matched_address.city_locality;
                        orderDetails.shipping.state = addressValidate.matched_address.state_province;
                        orderDetails.shipping.zip = addressValidate.matched_address.postal_code;
                        orderDetails.shipping.addressVerified = true;
                        $('#addressInput').show();
                        autoClicks()
                        // if(!orderDetails.shipping.chosenRates) $(`[data-which='charge']`).trigger('click')
                        // infoPage();
                    });

                    $("#returnAddress").on('click',function() {
                        // $('.address-reveal').html(oldHTML)
                        $(`#addressInputPane`).show();
                        $('#verifyAddressPane').hide();
                        addressHeight()
                        
                    })



                }
            });
        } else {
            $('#verifyAddress').html(`<p style='color:red'>Missing Information</p>`);
            //adjustCartItemHeight('address');
        }


    }

    function addressHeight() {
        let fromHeight = $('.address-reveal').height();
        $('.address-reveal').css('height','auto');
        let height = $('.address-reveal').height();
        $('.address-reveal').css('height',fromHeight);
        if(height<150) {height = 150}
        
        anime({
            before: function() {
                console.log(height);
                $('.side-header-address').hide();
            },
            targets: '.address-reveal',
            marginTop: 30,
            height: {
                value: [fromHeight,height],
                duration: 400,
                easing: 'linear'
            },
            opacity: [0,1],
            duration: 800,
            easing: 'cubicBezier(.33,.01,0,1.01)',
            complete: function() {
                $('.address-reveal').addClass('visible1');
                $('.address-reveal').find('input').filter(':visible:first').focus();
                
            }
        })
    }

    function addPayPalButtons(total,orderID) {
        console.log(total,orderID)
        $('#smart-button-container').remove();    
        let paymentContent = `
                  
            <div id="smart-button-container">
                <div style="text-align: center;">
                    <div id="paypal-button-container"></div>
                </div>
            </div>


            <script>
                function initPayPalButton() {
                    paypal.Buttons({
                
                    style: {
                        shape: 'rect',
                        color: 'gold',
                        layout: 'vertical',
                        label: 'paypal',

                    },

                    createOrder: function(data, actions) {
                        return actions.order.create({
                            purchase_units: [{"amount":{"currency_code":"USD","value":${total}}}]
                        });
                    },

                    onApprove: function(data, actions) {

                        return actions.order.capture().then(function(details) {
                            console.log(details)
                            let ppID = btoa(details.id)
                            let orderID = '${btoa(orderID)}';
                            window.location.replace("https://www.ecomm.bradwrightwebdev.com/index.php?orderStatus="+orderID+"&ppId="+ppID)
                            


                        });
                    },

                    onError: function(err) {
                        console.log(err);
                    }
                    }).render('#paypal-button-container');
                }
                initPayPalButton();
            </script>
        `;
        $('#paypalinsert').html(paymentContent);
    }
//
// **************************       *****************       ********************************* //
// **************************       *****************       ********************************* //



// 






function getCarriers() {
    // get carriers
    $.ajax({
        type: 'POST',
        url: "ajax/shipping.php?getCarriers",
        data: {

        },
        success: function(data) {
         //   console.log(JSON.parse(data));
        }
    });
    //
}





let cartNameselectionPage = `<div data-cartNav-contain='selectionPage' class='container'><div data-cartNav='selectionPage' class='press item '><h5 data-cartNav-H='selectionPage'>Selections</h5></div></div>`;
let cartNameinfoPage = `<div data-cartNav-contain='infoPage' class='container'><div data-cartNav='infoPage' class='press item '><h5 data-cartNav-H='infoPage'>Info</h5></div></div>`;
let cartNametoShipping = `<div data-cartNav-contain='toShipping' class='container'><div data-cartNav='toShipping' class='press item '><h5 data-cartNav-H='toShipping'>Shipping</h5></div></div>`;
let cartNameorderOverview = `<div data-cartNav-contain='orderOverview' class='container'><div data-cartNav='orderOverview' class='press item '><h5 data-cartNav-H='orderOverview'>Payment</h5></div></div>`;
let cartNametoPurchase = `<div data-cartNav-contain='toPurchase' class='container'><div data-cartNav='toPurchase' class='press item '><h5 data-cartNav-H='toPurchase'>Purchase</h5></div></div>`;
let cartNametoExit = `<div data-cartNav-contain='toExit' class='container'><div data-cartNav='toExit' class='press item '><h5 data-cartNav-H='toExit'>Exit</h5></div></div>`;
