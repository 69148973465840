import {removeWaiting, waiting} from '../javascript/index.js';
import { nanoid } from 'nanoid'
export {loadAdmin,adStoreList,colorElList};
//console.log('grace admin is running');
let dimList = [];
let orderList = [];
let orderDetails = [];
let thisPainting = {};
let storeItemBuild = [];
let storeBuildArtList = [];
let counter = 1;
let adStoreList = [];
let adArtList = [];
let packageList = [];
let editID;
let dir = true;
let way = '';
let editColReady = false
let filterColReady = false
let showArtList = []
let storeAddObj = [];
let inputObj = {};
let colorList = [];
let styles = [];
let filterOn = false;
let editField = false;
let colorElList = [
    {
        el: 'body',
        descr: '',
        class: ['main'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: 'header',
        descr: 'top bar of the webpage',
        class: ['main'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.subHeader',
        descr: 'second bar at top of page',
        class: ['menu'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.store-set',
        descr: 'store container',
        class: ['store', 'main'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: 'reveal-header p',
        descr: 'text of cart items',
        class: ['cart', 'title', 'text'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.cartPage-closer',
        descr: 'bottom section of cart',
        class: ['cart', 'main'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.cartPage',
        descr: 'cart container',
        class: ['cart', 'main'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.grace-container p',
        descr: 'menu on homepage',
        class: ['homepage', 'menu', 'title'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.store-item-title',
        descr: 'title of art',
        class: ['store', 'title'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.store-info-button',
        descr: 'button below art element',
        class: ['store', 'button'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.store-modal',
        descr: 'store pop up window with purchase options',
        class: ['store', 'modal'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.store-modal h2',
        descr: 'title of store pop-up window',
        class: ['store', 'modal'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: 'nav',
        descr: 'second bar at top of page',
        class: ['menu'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    },
    {
        el: '.reveal-header',
        descr: 'second bar at top of page',
        class: ['cart'],
        style: [{
            id: 1,
            property: 'background',
            value: '#fff',
        },
        {
            id: 2,
            property: 'color',
            value: '#000'
        }]
    }
]

let shippingServices = ['ups_ground', 'usps_priority_mail', 'usps_priority_mail_express','ups_3_day_select','ups_2nd_day_air'];

//utils
function uniqueBy(arr, prop){
  return arr.reduce((a, d) => {
    if (!a.includes(d[prop])) { a.push(d[prop]); }
    return a;
  }, []);
}

const toFixed = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);

//addPayPalButtons();

// load Menu and collect lists
function loadAdmin() {
    console.log('loading Admin');
    let adminInput='';
    //let adminInput = `<input id='adminInput' value=''>`;
    let content = `<div class='admin-menu'></div><div class='admin-content'>${adminInput}</div>`;
    $('main').html(content);
    // $.ajax({
    //     url: "ajax/admin.php?updateStyle",
    //     type: 'POST',
    //     data: {
    //         style: colorElList
    //     },
    //     success: function(data) {
    //         console.log(data);
    //     }
    // });

    // $('#adminInput').on('keyup',function() {
    //     let thisVal = $(this).val();
    //     console.log(thisVal);
    //     if(thisVal == 'hhh') {
            adminMenu();
            getArtList();
            getDimList();
            getStoreList();
            getOrderDetails();
            getColorList();
            getPackageList();
            getStyles();
    //         $('#adminInput').remove();
    //     }
    // })


    adminExit();
}

function adminExit() {
    let content = `
        <div id='homeReturn' style='color:white;font-size:36px;' class='press'>home page</div>
        <div id='logout' style='color:white;font-size:16px;margin-right:50px' class='press'>logout</div>
    `;

    $('header').append(content);

    $("#homeReturn").on('click',function() {
        window.location.href = 'index.php';

    })

    $("#logout").on('click', logOut)
}

function logOut() {
    console.log('logging out')
    return $.ajax({
        url: "ajax/admin.php?logOut",
        success: function(data) {
            if(data=='logged out') location.reload();
            console.log(data);

        }
    })
}

function getArtList() {
    return $.ajax({
        url: "ajax/art.php?getArtList",
        success: function(data) {
            adArtList = JSON.parse(data);
            return new Promise((resolve,reject) => {
                resolve('hey');
            })
            console.log(adArtList);
        }
    });
}
function getDimList() {

    return $.ajax({
        url: "ajax/admin.php?dimList",
        success: function(data) {
            dimList = JSON.parse(data);
            //console.log(dimList)
        }
    });
}

export function getOrderDetails() {
    return $.ajax({
        url: "ajax/admin.php?orderDetails",
        success: function(data) {
            //console.log(data);
            orderDetails = JSON.parse(data);
            // orderDetails.forEach(o=>{
            //     if(typeof(o.content)=='string') o.content = JSON.parse(o.content)
            // })
            orderDetails = orderDetails.sort((a,b) => {
                let date1 = new Date(a.stamp);
                let date2 = new Date(b.stamp);
                return date2 - date1;
            })

            return new Promise((resolve,reject) => {
                resolve(orderDetails);
            })
            //console.log(orderDetails);
        }
    });
}

export function getStoreList() {
    return $.ajax({
        url: "ajax/admin.php?storeList",
        success: function(data) {
            adStoreList = JSON.parse(data);
            adStoreList.forEach((item) => {
                item.boxID = JSON.parse(item.boxID);
                item.rollID = JSON.parse(item.rollID);
            })
            console.log(adStoreList);
        }
    });
}

function getColorList() {
    return $.ajax({
        url: "ajax/admin.php?getColorList",
        success: function(data) {
            //console.log(data);
            colorList = JSON.parse(data);
            // console.log(colorList);
            return new Promise((resolve,reject) => {
                resolve('done!');
            })
        }
    });
}

function getStyles() {
    // console.log(JSON.stringify(colorElList));
    return $.ajax({
        url: "ajax/admin.php?getStyle",
        success: function(data) {
            // console.log(data);
            styles = JSON.parse(data);
            return new Promise((resolve,reject) => {
                styles.forEach((item, i) => {
                    if(item.class.length) {
                        item.class = JSON.parse(item.class);
                    }
                    if(item.style.length) {
                        item.style = JSON.parse(item.style);
                    }

                });
                // console.log(styles);
                resolve('done!');
            })
        }
    });

}

function getPackageList() {
    return $.ajax({
        url: "ajax/admin.php?getPackageList",
        success: function(data) {
        //    console.log(data);
            packageList = JSON.parse(data);
            return new Promise((resolve,reject) => {
                resolve();
            })
        }
    });
}
// menus - main and submenu
function adminMenu() {

    // Main Menu
    let content = `
    <div class='admin-menu-set'>
        <div data-adminMenu='Art' class='press admin-menu-item'>Art</div>
        <div data-adminMenu='Store' class='press admin-menu-item'>Store</div>
        <div data-adminMenu='Orders' class='press admin-menu-item'>Orders</div>
        <div data-adminMenu='Settings' class='press admin-menu-item'>Settings</div>
    </div>
    `;
    $('.admin-menu').html(content);

    // listen for clicks
    $('[data-adminMenu]').on('click',function() {
        let way = $(this).attr('data-adminMenu');
    //    console.log(way);
        //console.log(adArtList);
        $('.admin-submenu').remove();
        let subMenu = ``;

        // craft subMenus
        if(way=='Store') {
            // getStoreList()
            subMenu = `
                <div class='admin-button-set admin-submenu'>
                    <div data-menu='store' data-admin='addNewStoreItem' class='press admin-button'>Add</div>
                    <div data-menu='store' data-admin='viewEdit' class='press admin-button'>view/edit</div>
                    <div data-menu='store' data-admin='packages' class='press admin-button'>Packaging</div>
                    <div data-menu='store' data-admin='reconcile' class='press admin-button'>Reconcile</div>
                </div>
            `;
        }

        if(way=='Art') {
            subMenu = `
                <div class='admin-button-set admin-submenu'>
                    <div data-menu='art' data-admin='add' class='press admin-button'>Add</div>
                    <div data-menu='art' data-admin='active' class='press admin-button'>Active</div>
                    <div data-menu='art' data-admin='inactive' class='press admin-button'>Inactive</div>
                    <div data-menu='art' data-admin='reconcile' class='press admin-button'>Reconcile</div>
                    <div data-menu='test' data-admin='test' class='press admin-button'>test</div>
                </div>
            `;
        }

        if(way=='Orders') {
            subMenu = `
                <div class='admin-button-set admin-submenu'>
                    <div data-menu='orders' data-admin='all' class='press admin-button'>All</div>

                </div>
            `;
        }

        if(way=='Settings') {
            waiting('.admin-content');
            $.when(

                getStyles()
            ).then(function() {
                $('.admin-content').empty();
                subMenu = `
                <div class='admin-button-set admin-submenu'>
                    <div data-menu='settings' data-admin='colors' class='press admin-button'>Colors</div>
                </div>
                `;
                $(".admin-content").empty();
                $('.admin-content').before(subMenu);
                removeWaiting();
                // collect Lists
                getArtList();
                getStoreList();
                getDimList();

                // enable clicks
                adminContentClicks();

            })

        } else {
            // render content
            $(".admin-content").empty();
            $('.admin-content').before(subMenu);

            // collect Lists

            // enable clicks
            adminContentClicks();
        }



    });
}

// handle content clicks
function adminContentClicks() {
        $('.admin-button-set').off('click').on('click','[data-admin]',function() {
            $(".admin-content").empty();
            way = $(this).attr('data-admin');
            let menu = $(this).attr('data-menu');
            console.log(way,menu);

            if(menu=='art') {
                //console.log(adArtList);
                if(way=='add') {
                    addArtForm('.admin-content',true);
                }
                if(way=='active' || way=='inactive') {
                    showArt(way);
                }
                if(way=='reconcile') {
                    reconcilePics();
                }
            }


            if(menu=='store') {
                if(way=='addNewStoreItem') {
                    //artChoiceModal();
                    //addStoreItem();
                    storePerPainting();
                    dimSelect();
                }
                if(way=='viewEdit') {
                    viewEditSubMenu();
                }
                if(way=='packages') {
                    showPackages();
                }
                if(way=='reconcile') {
                    reconcileStore();
                }
            }

            if(menu=='test') {
                runTest();
            }

            if(menu=='orders') {
                if(way=='all') {
                    ordersAll();
                }
                if(way=='redoID') {
                    redoID()
                }
            }
            if(menu=='settings') {
                if(way=='colors') {
                    openColors();
                }
            }

        });


        // I think this is all useless (unused / redundant)
        $('.admin-content').off('click').on('click','[data-what]',function(e) {
            let what = $(this).attr('data-what');
            let editID = $(this).attr('data-editID');
            console.log(what,editID);
            if(what=='art-line-edit') {
                editArtLine(editID);
            }
            if(what=='art-line-delete') {
                deleteArtLine(editID);
            }
            if(what=='removePic') {
                unlinkPic(editID);
            }
            if(what=='removeRow') {
                removeRow(editID);
            }
            if(what=='uploadPhoto') {
                addArtForm('');
            }
            if(what=='addToServer') {
                $.ajax({
                    type: "POST",
                    url: "ajax/admin.php?addByFileName",
                    data: {
                        editID
                    },
                    success: function(data) {
                        console.log(data);
                        getArtList().then(function() {
                            artIntroInfo(data);
                        })


                    }
                });
            }
        });


}

// COLORS
function openColors(classFilter) {
    removeWaiting();
    let focus = '';
    console.log(styles,classFilter,colorList);
    let stylesList = []; let optionText = '';
    let tempStyles = styles;
    if(classFilter) {
        stylesList = styles.filter((el) => {
            return el.class.some((w) => {
                return w.value == classFilter
            })
        });
        optionText = classFilter;
    } else {
        stylesList = styles;
        optionText = '';
    }
    let colorbgList = colorList.filter((col) => {
        return col.type=='bg'
    })
    let colorfList = colorList.filter((col) => {
        return col.type=='font'
    })
    let allClasses = [];
    let classSelect = `<option value=''>${optionText}</option><option value=''>** clear **</option>`;
    styles.forEach((item, i) => {
        if(item.class.length) {
            item.class.forEach((clas, i) => {
                if(!allClasses.includes(clas.value)) {
                    allClasses.push(clas.value);
                    classSelect += `<option value='${clas.value}'>${clas.value}</option>`;
                }
            });
        }


    });
    console.log(allClasses);
    let content = `
        <div class='style-header' style=''>
            <div class='item flexc'>
                <input id='newEl' name='newbg'>
                <button class='press newEl' name=''>new element</button>
            </div>
            <div class='item flexc'>
                <input id='newCol' name='newCol'>
                <button class='press newCol' name=''>new color</button>
            </div>
            <div class='item flexc'>
                <select id='classSelect' name='classes'>${classSelect}</select>
                <button class='press filterClass' name=''>filter by class</button>
            </div>
            <div class='item flexc'>
                <button class='press saveAll' name=''>SAVE</button>
            </div>
        </div>
        <div class='style-set'>
            <div class='element-list'>
    `;
    // list styles
    stylesList.forEach((el) => {

        let exampleStyle = '';
        content += `
        <div data-elID='${el.ID}' class='element'>
            <div class='el-display'>
                <div class='element-name' >${el.el}</div>
                <span data-do='editElName' data-id='${el.ID}'><i class="fal fa-edit"></i></span>
                <span data-do='removeElement' data-id='${el.ID}' style='margin-left:10px'><i class="far fa-times"></i></span>
            </div>
            <div class='display cat-display'><div class='display-label'>Class</div>`;
                if(el.class.length) {
                    el.class.forEach((item) => {
                        content += `
                            <div class='cat-item'>
                                <div class='cat-item'>${item.value}</div><div style='flex:1'></div>
                                <span data-do='removeClass' data-elID='${el.ID}' data-id='${item.id}' style='margin-left:10px'><i class="far fa-times"></i></span>
                            </div>
                        `;
                    })
                }

            content += `<div data-do='addClass' data-id='${el.ID}' class='plus' style=''>+</div></div>
            <div class='display style-display'><div class='display-label'>Style</div>`;
                if(el.style.length) {
                    el.style.forEach((item, i) => {
                        content += `
                        <div class='style-item'>
                            ${item.property}:  <span class='here'>${item.value}</span>
                            <span style='flex:1'></span>
                            <span data-do='edit' data-id='${item.id}'><i class="fal fa-edit"></i></span>
                            <span data-do='removeProp' data-id='${item.id}' style='margin-left:10px'><i class="far fa-times"></i></span>
                        </div>`;
                        exampleStyle += `${item.property}:${item.value};`;
                    });

                }

            content += `<div data-do='addProp' data-id='${el.ID}' class='plus' style=''>+</div></div>
            <div style='${exampleStyle}' class='style-example'>Sample Text</div>

        </div>`;
    })
    content += `</div>`;


    content += `<div class='color-container'>`;
    colorList.forEach((item, i) => {
        content += `
            <div class='color' data-color='${item.color}' style='background:${item.color};'>
                <div class='color-options'>
                    <div class='color-option-item color-edit'>edit</div>
                    <div class='color-option-item color-remove'>X</div>
                </div>
            </div>
        `;
    });

    content += `</div></div>`;
    $(".admin-content").html(content);




    // CLICKS  **********************************************

    $('.element').on('focus','input',function() {
       // console.log($(this).val());
        focus = $(this);
    })

    $('.color-edit').on('click',function() {
        let thisColor = $(this).parents('.color').attr('data-color');

        // add content
        $(this).parents('.color').prepend(`
            <div class='changeColor-set'>
                <input id='changeColor' value='${thisColor}'>
                <div class='flexr'>
                    <div id='submitChange' class=''>save</div>
                    <div id='cancelChange' class=''>cancel</div>
                </div>
            </div>
        `);
        $('#changeColor').focus().select();

        // cancel
        $('#cancelChange').on('click',function() {
            $('.changeColor-set').remove();
        })

        // change color and rerender
        $('#submitChange').on('click',function() {
            let oldColor = $(this).parents('.color').attr('data-color');
            let newColor = $('#changeColor').val();

            // if change has been made
            if(oldColor !== newColor) {

                // alter colorList
                colorList.forEach((col) => {
                    console.log(col.color);
                    if(col.color == oldColor) {
                        col.color = newColor
                    }

                })

                // check it
                console.log(colorList);

                // alter all styles with color
                styles.forEach((style) => {
                    style.style.forEach((sty) => {
                        if(sty.value == oldColor) {
                            sty.value = newColor
                        }
                        //console.log(sty);
                    })
                })

                // re-render page
                openColors();

                // save new color in background
                $.ajax({
                    url: "ajax/admin.php?editColor",
                    type: 'POST',
                    data: {
                        newColor,
                        oldColor
                    },
                    success: function(data) {
                        console.log(data);

                    }
                })

                // save styles in background
                saveStyles();

            }

        })
    })

    $(".style-set").on('click','[data-do]',function() {
        let doIt = $(this).attr('data-do');
        let id = $(this).attr('data-id');
        let elID = $(this).parents('.element').attr('data-elID');
        let value = $(this).siblings('.here').text();
        let thisSty = tempStyles.find((sty) => {
            return sty.ID == elID
        })

        console.log(doIt,id,elID,value);
        if(doIt=='editElName') {
            let parent = $(this).parent('.el-display');
            let returnContent = parent.html();
            console.log('editing name');
            let val = styles.find((sty) => {
                return sty.ID == id
            })

            $(this).siblings('.element-name').html(`
                <input value='${val.el}' data-elID='${elID}' id='elNameEditVal'>
                <p data-editEl='cancel' data-id='${val.el}'>Cancel</p>
                `);
            $("#elNameEditVal").focus().select();
            $('[data-editEl').on('click',function() {

                if(what=='cancel') {
                    $(this).parents('.el-display').html(returnContent)
                }
            })
        }

        if(doIt=='removeElement') {
            let returnContent = $(this).parent('.el-display').html();

            console.log('editing name',returnContent);
            let val = styles.find((sty) => {
                return sty.ID == id
            })
            $(this).siblings('.element-name').html(`
                <p style='color:blue;cursor:pointer;margin-bottom:10px' data-elRemove='confirmElRemove' data-id='${id}'>Yes I want to remove ${val.el} completely</p>
                <p data-elRemove='cancelElRemove'>Cancel</p>
                `);

            $('[data-elRemove').on('click',function() {
                let what = $(this).attr('data-elRemove');
                let elEditID = $(this).attr('data-id');
                if(what=='cancelElRemove') {
                    console.log(returnContent)
                    $(this).parents('.el-display').html(returnContent)
                }
                if(what=='confirmElRemove') {
                    console.log('removing',elEditID);
                    $.post('ajax/admin.php?removeElement',{ID: elEditID},function(mess1) {
                        console.log(mess1);
                    $.when(
                        getColorList(),
                        getStyles()
                    )
                    .then(function() {
                        openColors(classFilter);
                    })

                    })

                }
            })

        }

        if(doIt=='edit') {
            if($('.changeVal').length > 0) {
                let tempelID = $('.changeVal').attr('data-elID');
                let tempid = $('.changeVal').attr('data-id');
            }
            console.log('edit trial');
            $(this).siblings('.here').replaceWith(`
                <input class='changeVal' data-which='value' data-b='${elID}-${id}' data-elID='${elID}' data-id='${id}' value='${value}'>

            `);
            $(`input[data-b='${elID}-${id}']`).focus().select();
            // $(`[data-elID='${elID}']`).children(`[data-id='${id}']`).css('background','black');
        }
        if(doIt=='removeProp') {
            thisSty.style = thisSty.style.filter((sty) => {
                return sty.id !== id
            })
            console.log(thisSty);
            $(this).parents('.style-item').prepend(`<div style='position:absolute;width:80%;height:50%;top:0;left:0;border-bottom:1px solid black;'></div>`)
        }
        if(doIt=='removeClass') {
            thisSty.class = thisSty.class.filter((sty) => {
                return sty.id !== id
            })
            $(this).parents('.cat-item').prepend(`<div style='position:absolute;width:80%;height:50%;top:0;left:0;border-bottom:1px solid black;'></div>`)

            console.log(thisSty);
        }

        if(doIt=='addProp') {
            console.log('add prop',elID);
            let newID;
            newID = thisSty.style.length + 1;
            console.log(thisSty.style.length,newID);
            thisSty.style.push({id:newID.toString()})
            let newProp = thisSty.style.find((sty) => {
                return sty.id == newID
            })
            console.log(newProp,tempStyles);
            $(`[data-elID='${elID}']`).find('.style-display').append(`
                <div class='style-item'>
                    <input data-which='property' data-b='${elID}-${id}' data-elID='${elID}' data-id='${newID}'>:
                    <input data-which='value' data-b='${elID}-${id}' data-elID='${elID}' data-id='${newID}'>
                </div>`);
        }
        if(doIt=='addClass') {
            let newID = 0;
            if(thisSty.class.length) {
                thisSty.class.forEach((cla)=> {
                    if(cla.id > newID) {
                        newID = parseInt(cla.id) + 1;
                    }
                });
            } else {
               newID = 1;
            }

            console.log(newID);
            thisSty.class.push({id:newID.toString()});
            $(`[data-elID='${elID}']`).find('.cat-display').append(`
                <div class='cat-item'>
                    <input data-which='class' data-elID='${elID}' data-id='${newID}'>
                </div>`);

        }
    })



    $(".element").on('input','input',function() {
        let val = $(this).val();
        let which = $(this).attr('data-which');
        let thisElID = $(this).attr('data-elID')
        let thisid = $(this).attr('data-id');

        let thisEl = tempStyles.find((style) => {
            return style.ID == thisElID
        })

        if($(this).parents('.cat-display').length) {
            let thisClass = thisEl.class.find((el) => {
                return el.id == thisid
            })
            thisClass.value = val;

        }
        if($(this).parents('.style-display').length) {
            let thisProp = thisEl.style.find((el) => {
                return el.id == thisid
            })
            thisProp[which] = val;
        }

        if($(this).parents('.el-display').length) {
            thisEl.el = val
            //thisElName.value = val;

        }
        console.log(tempStyles,val);
    })

    // save all changes
    $(".saveAll").on('click', function() {
        waiting('.admin-content');
        styles = tempStyles;
        focus = undefined;
        // update server then run openColors()
        console.log(styles);
        $.when(
            saveStyles(),
        )
        .then(function() {
            getColorList(),
            getStyles()
        })
        .then(function() {
            openColors(classFilter);
        })


    })

    $('.color').on('click',function(e) {
        if($(e.target).hasClass('color-remove')) {
            $('.color-remove').on('click',function() {
                waiting('.admin-content');
                let thisColor = $(this).parent('[data-color]').attr('data-color');
                console.log(thisColor);
                colorList = colorList.filter((col) => {
                    return col.color !== thisColor
                })
                $.ajax({
                    url: "ajax/admin.php?removeColor",
                    type: 'POST',
                    beforeSend: function() {

                    },
                    data: {
                        color: thisColor
                    },
                    success: function(data) {
                        console.log(colorList);
                        openColors();
                    }
                });
            })
        } else {
            if(focus) {
                let thisColor = $(this).attr('data-color');
                $(focus).val(thisColor);
                console.log(focus);
                let thisElID = focus.attr('data-elID')
                let thisid = focus.attr('data-id');
                let thisEl = tempStyles.find((style) => {
                    return style.ID == thisElID
                })
                let thisProp = thisEl.style.find((el) => {
                    return el.id == thisid
                })
                thisProp.value = thisColor;
            }

        }



    })

    $(".newEl").on('click',function() {
        let val = $("#newEl").val();
        if(val && !colorList.includes(val)) {
            //colorList.push(val);
            $.ajax({
                url: "ajax/admin.php?addElement",
                type: 'POST',
                data: {
                    element: val
                },
                success: function(data) {
                    console.log(data);
                    $.when(
                        getColorList(),
                        getStyles()
                    )
                    .then(function() {
                        openColors();
                    })

                }
            });
        }
    });

    $(".newCol").on('click',function() {
        let val = $("#newCol").val();
        if(val && !colorList.includes(val)) {
            //colorList.push(val);
            $.ajax({
                url: "ajax/admin.php?addColor",
                type: 'POST',
                data: {
                    color: val
                },
                success: function(data) {
                    console.log(data);
                    $.when(
                        getColorList(),
                        getStyles()
                    )
                    .then(function() {
                        openColors();
                    })

                }
            });
        }
    });

    $('.filterClass').on('click',function() {
        openColors($('#classSelect').val());
    })

    $(".element-name").on({
        mouseenter: function() {
            let thisID = $(this).parent('.element').attr('data-elID');
            let thisStyle = styles.find((style) => {
                return style.ID == thisID
            })
            if(thisStyle) {
                let descr = thisStyle.descr;
                console.log(thisStyle, descr);
                $(this).prepend(`<div id='label' style=''>${descr}</div>`);
            }

        },
        mouseleave: function() {
            $("#label").remove();
        }
    })



    $('.removeColor').on('click',function() {
        let removeColor = $(this).attr('data-color');
        $.ajax({
            url: "ajax/admin.php?removeColor",
            type: 'POST',
            data: {
                removeColor
            },
            success: function(data) {
                getColorList().then(function() {
                    openColors();
                })

            }
        });
    })

    $('.newColor').on('click',function() {
        let newColor = $('#newColor').val();
        $.ajax({
            url: "ajax/admin.php?newColor",
            type: 'POST',
            data: {
                newColor
            },
            success: function(data) {
                getColorList().then(function() {
                    openColors();
                })

            }
        });
    })

    $('.updateColor').on('click',function() {
        let thisID = $(this).attr('data-colorID');
        let newColor = $('#'+thisID).val();
        console.log(thisID,newColor)
        $.ajax({
            url: "ajax/admin.php?updateColor",
            type: 'POST',
            data: {
                newColor,
                id: thisID
            },
            success: function(data) {
                console.log(data);
                getColorList().then(function() {
                    openColors();
                })

            }
        });
    })

    // FUNCTIONS  **********************************************

    // save styles
    function saveStyles() {
        return $.ajax({
            url: "ajax/admin.php?updateStyle",
            type: 'POST',
            beforeSend: function() {
            },
            data: {
                styles
            },
            success: function(data) {
                // console.log(data);
                return new Promise((resolve,reject) => {
                    resolve();
                })


            }
        });
    }

}

// ORDERS
let ordersPrimary = ['name','totalCharge'];
let ordersNoEdit = ['uuid','created']
let ordersExcept = ['cart_string','cart','ship_string','uuid'];


function ordersAll() {
    console.log(orderDetails)
    let uniqueIDs = []
    orderList = []
    orderDetails.forEach((v, i, a) => {
        if(!uniqueIDs.includes(v.orderID)) uniqueIDs.push(v.orderID)
    })
    uniqueIDs.forEach(u=>{
        let thisOrder = orderDetails.filter(o => o.orderID == u)
        thisOrder = thisOrder.sort((a,b) => {
            let date1 = new Date(a.content.stamp);
            let date2 = new Date(b.content.stamp);
            return date2 - date1;
        })
        orderList.push(thisOrder[0])
    })
    orderList.forEach(u=> {
        if((u.action=='update' || u.field=='created') && typeof(u.content)=='string') u.content = JSON.parse(u.content)
    })
    // console.log(orderList)

    let content = `
    <h2 style='border-bottom:1px solid white;margin:25px 0 15px;height:35px'>Open Orders</h2>
    <table class='admin-table'><thead><tr><th></th><th>created</th><th>name</th><th>Total</th><th>payment<br>received</th><th>stage</th></tr></thead><tbody>`;
    

    let currentOrders = []
    let closedOrders = []
    let removedOrders = []
    let cancelledOrders = []
    orderList.forEach(o=> {
        
        if(o.valid=='1') {
            // console.log(o)
            let thisStage
            if(o.content.stages) {
                thisStage = o.content.stages[o.content.stages.length - 1]
                // console.log(thisStage)
                if(thisStage.index < 6) currentOrders.push(o)
                if(thisStage.index == 6) closedOrders.push(o)
                if(thisStage.index == 8) cancelledOrders.push(o)
            } 
    
        } else {
            removedOrders.push(o)
        }

    })
    
    console.log(currentOrders,closedOrders,removedOrders)
    currentOrders.forEach((item, i) => {
        // handle dates / Stamp
        let date = new Date(item.stamp)
        // console.log(item)
        content += `
        <tr  class='order-tr' style='height:50px'>
            
            `;
        content += `
            <td data-edit='${item.orderID}'>EDIT</td>
            <td>${date.toLocaleString('en-US')}</td>
            <td>${item.content.contact.name || item.content.name}</td>
            <td>$${item.content.payment.current.total ? item.content.payment.current.total.toFixed(2) : ''}</td>
            <td>${item.content.payment && item.content.payment.current.received || 'None'}</td>`
        content += `<td>${item.content.stages && item.content.stages[item.content.stages.length -1].stage}</td>`;
        content += `<td data-remove='${item.ID}'>Remove</td></tr>`;
        
    });
    content += `</tbody></table><h2 style='border-bottom:1px solid white;margin:25px 0 15px;height:35px'>Closed Orders</h2><table class='admin-table'><tbody>`
    closedOrders.forEach(e=> {
        content += `
        <tr style='height:35px'>
            <td style='cursor:pointer' data-edit='${e.orderID}'>EDIT</td>
            <td colspan='3'>${e.content.contact.name}</td>
            <td colspan='3'>closed</td>
        </tr>`
    })
    content += `</tbody></table><h2 style='border-bottom:1px solid white;margin:25px 0 15px;height:35px'>Cancelled Orders</h2><table class='admin-table'><tbody>`
    cancelledOrders.forEach(e=> {
        content += `
        <tr style='height:35px'>
            <td style='cursor:pointer' data-edit='${e.orderID}'>EDIT</td>
            <td colspan='3'>${e.content.contact.name}</td>
            <td colspan='3'>${e.content.stages && e.content.stages[0].stage}</td>
        </tr>`
    })
    content += `</tbody></table><h2 style='border-bottom:1px solid white;margin:25px 0 15px;height:35px'>Hidden Orders</h2><table class='admin-table'><tbody>`
    // Ended Orders
    removedOrders.forEach(e=> {
        console.log(e)
        content += `
        <tr style='height:35px'>
            <td style='cursor:pointer' data-reinstate='${e.ID}'>Reinstate</td>
            <td colspan='3'>${e.content.name || e.content.contact.name}</td>
            <td colspan='3'>${e.content.stages && e.content.stages[0].stage}</td>
        </tr>`
    })
   
    content += `</tbody></table>`;

    $(".admin-content").html(content);

    $('[data-edit]').off('click').on('click',function() {
        editID = $(this).attr('data-edit');
        editOrder(editID,true);
    });
    $('[data-remove').on('click',function() {
        let id = $(this).attr('data-remove');
        alterDetail(id,'valid','0').then(function() {
            getOrderDetails().then(function() {
                ordersAll()
            })
        });
    })
    $('[data-reinstate').on('click',function() {
        let id = $(this).attr('data-reinstate');
        alterDetail(id,'valid','1').then(function() {
            getOrderDetails().then(function() {
                ordersAll()
            })
        });
    })

    $('[data-view]').off('click').on('click',function() {
        let thisView = $(this).attr('data-view');
        $(`[data-hidden-orderInfo='${thisView}'`).show();
    });

    $('[data-hide]').off('click').on('click',function() {
        let thisView = $(this).attr('data-hide');
        console.log(thisView);
        $(`[data-hidden-orderInfo='${thisView}'`).hide();
    });

}

export let stages = (lastUpdate) => {

    let standardOffer = [
        {
            title: 'status link',
            content: lastUpdate ? [`\r\n <br><br><a style='font-size:22px;' href='https://www.ecomm.bradwrightwebdev.com/index.php?orderStatus=${btoa(lastUpdate.orderID)}'>View Status of your Order</a> \r\n`] : ''
        },
        {
            title: 'qce',
            content: [
                `If you have any questions or concerns<br>please don't hesitate to email me at `,
                `brad@bradwrightwebdev.com`,
            ]
        },
        {
            title: 'sig',
            content: [`\r\n <br><br>Best Regards,<br><br>Brad Wright\r\n`]
        }

    ]

    let tText = {
        qce: `If you have any questions or concerns<br>please don't hesitate to email me at brad@bradwrightwebdev.com`,
        sig: `\r\n <br><br>Best Regards,<br><br>Brad Wright\r\n`
    }
    if(lastUpdate && typeof(lastUpdate.content) !== 'object')  {
        lastUpdate.content = JSON.parse(lastUpdate.content)
    }

    let firstName = ''
    if(lastUpdate) {
        firstName = lastUpdate && lastUpdate.content.contact.name ? lastUpdate.content.contact.name.split(' ')[0] : ''
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
    } else {
        firstName = ''
    }

    // console.log(firstName)
    // if(!thisOrder) {
    //     thisOrder = [];
    // }
    let dullStyle = 'flex:0;background:#878787;color:white;border:none;filter:none'
    let temp = {
        email: {
            content: `Update customer with an email`,
            js: `setEmail`,
            style: dullStyle
        },
        refund: {
            content: `Refund payment`,
            js: `refund`,
            style: dullStyle
        },
        cancel: {
            content: `Cancel Order`,
            js: `cancel`,
            style: dullStyle
        }
    }
        
        
    return [
        {
            index: 0,
            stage: 'created',
            call: [
                {
                    content: 'Send email for payment',
                    js: `setEmail`
                },
            ],
            email_offer: [
                {
                    title: 'I need payment',
                    content: [
                        `Thanks again for your order ${firstName}! <br><br>I have not received your payment yet.<br><br>If this feels correct please click the link below to complete the payment.<br>If this email has reached you in error please reply to this email with any payment information you have.`,
                    ]
                },
                ...standardOffer
            ],
            status_text: [
                `<h4>Thanks for your order ${firstName}!</h4>`,
                `<h5>Please submit payment via Paypal below</h5>`,
                `<div id='ffWindow'></div>`,
                `<div id='paypalinsert'></div>  `
            ],
            status_comp: [
                // paypalButtons,
                // cancelO,
                // updateO
            ]
        },
        {
            index: 1,
            stage: 'payment received',
            call: [
                {
                    content: 'submit payment through paypal',
                    js: `paypal`
                },
                {
                    content: 'Send email for payment',
                    js: `setEmail`
                },
                {
                    content: `Customer paid by Venmo`,
                    js: `payment received`,
                    type: 'stage'
                },
                {
                    content: `Customer paid with Cash`,
                    js: `payment received`,
                    type: 'stage'

                }
            ],
            email_offer: [
                {
                    title: 'I need payment',
                    content: [
                        `Thanks again for your order ${firstName}! <br><br>I have not received your payment yet.<br><br>If this feels correct please click the link below to complete the payment.<br>If this email has reached you in error please reply to this email with any payment information you have.`,
                    ]
                },
                {
                    title: 'Thanks for the payment',
                    content: [
                        `Payment Received - Thanks ${firstName}!`,
                        `I am confirming your order and will reach out again when I have shipped your artwork`,
                    ]
                },
                ...standardOffer
            ],
            status_text: function() {
                if(!lastUpdate.content.payment.current.received) {
                    return [
                        `<h3>Thanks for your order ${firstName}!</h3>`,
                        `<p>Please click the PayPal link below to complete the payment.</p>`,
                        `<div data-id='${lastUpdate ? lastUpdate.orderID : ''}' id='ffWindow'></div>`,
                        `<div id='paypalinsert'></div>`,
                        `<div id='statusButtons'></div>`,
                    ]
                } else {
                    return [
                        `<h3>Thanks for your order ${firstName}!</h3>`,
                        `<p>I have received your payment and will now verify your order and confirm when everything is set.</p>`,
                        `<p>Please visit this page or check your email for updates</p>`,
                    ]
                }
            },
            status_comp: [
                //cancelO,
            ]

        },
        {
            index: 2,
            stage: 'confirmed',
            call: [
                {
                    content: 'Confirm this order',
                    js: `confirmed`,
                    type: 'stage'
                },
                temp.email,
            ],            
            email_offer: [
                {
                    title: 'Confirm the Order',
                    content: ''
                },
                {
                    title: 'Issue with Order',
                    content: [
                        `Thank you for your order.`,
                        `Unfortunately there is an issue...`,
                    ]
                },
                ...standardOffer
                
            ],
            status_text: [
                `<h3>${firstName}!</h3>`,
                `<h4>Your order has been confirmed.  </h4>`,
                `<p>Next I'll sort out the best packaging options and notify you when the artwork is in the mail.</p>`,
            ]
        },
        {
            index: 3,
            stage: 'packaged',
            call: [
                {
                    content: `I have packaged this artwork`,
                    js: `packaged`,
                    type: 'stage'
                },
                temp.email,
                
            ],
            email_offer: [
                {
                    title: 'Item(s) packaged',
                    content: [
                        `I have packaged your artwork and will notify you when it ships.`,
                        `...should be in the next couple of days`
                    ]
                },
                ...standardOffer
            ],
            status_text: [
                `<h3>I have packaged your artwork.  </h3>`,
                `<h4>I'm on my way to the store to send it.  I'll confirm with you and include the tracking number so that you can await its arrival with properly timed excitement.</h4>`,
            ]
        },
        {
            index: 4,
            stage: 'shipped',
            call: [
                {
                    content: `I have shipped this artwork`,
                    js: `shipped`,
                    type: 'stage'
                }
            ],
            email_offer: [
                {
                    title: 'Item has shipped',
                    content: [
                        `Great News!`,
                        `Your item has shipped.`,
                        `Here is the tracking number in case you'd like to await its arrival with more precision:`,
                        `${lastUpdate.content.shipping.trackingNum || '****  # unavailable ****'}`,
                    ]
                },
                ...standardOffer
            ],
            status_text: [
                `<h4>Your artwork is on the way!</h4>`,
                `<h4>The shipping number for your package is ${lastUpdate.content.shipping.trackingNum || '****  # unavailable  ****'}</h4>`,
                `<div class='button' style='font-size:80%' >If it has arrived already click here</div>`
            ]
        },
        {
            index: 5,
            stage: 'delivered',
            call: [
                {
                    content: `This artwork has been delivered`,
                    js: `delivered`,
                    type: 'stage'
                }
            ],
            email_offer: [
                {
                    title: 'Item Delivered',
                    content: [
                        `Your item has been delivered!`, 
                        `Please verify with me that everything arrived without any issues`,
                        `If you have any issues please reply to this email or logon to my website and submit a note there.`,
                        `Tell your friends!`,
                    ]
                },
                ...standardOffer
            ],
            status_text: [
                `<h4>Your artwork has been delivered.</h4>`,
                `<h4>Please verify with me that everything arrived without any issues</h4>`,
            ]
        },
        {
            index: 6,
            stage: 'closed',
            call: [
                {
                    content: 'Close this Order',
                    js: 'closed',
                    type: 'stage'
                },
            ],
            email_offer: [
                {
                    title: 'Order Closed',
                    content: [
                        `Your order has been closed.`, 
                    ]
                },
                ...standardOffer
            ],
            status_text: [
                `<h4>Your order has been closed</h4>`,
                `<h4>As such, order information is no longer available.</h4>`,
                `<h5>If you wish to rehash the past, I'm happy to reminisce, please send me an email through the usual means, or send a message through this website.`,
            ]
        },
        {
            index: 7,
            stage: 'refunded',
            call: [
                {
                    content: 'Refund this Order',
                    js: 'refunded',
                    type: 'stage'
                },
            ],
            email_offer: [
                {
                    title: 'Order Refunded',
                    content: [
                        `Your total in the amount of ${lastUpdate.content.payment.total} has been refunded.`, 
                        `If you have any issues please reply to this email or logon to my website and submit a note there.`,
                    ]
                },
                ...standardOffer
            ],
            status_text: [
                `<h4>Your Order charge of ${lastUpdate.content.payment.total} has been refunded.</h4>`,
            ]

        },
        {
            index: 8,
            stage: 'cancelled',
            call: [
                {
                    content: 'Cancel this Order',
                    js: 'cancelled',
                    type: 'stage'
                },
            ],
            status_text: [
                `<h3>Your order has been cancelled</h3>`,
            ],
            email_offer: [
                {
                    title: 'Order Cancelled',
                    content: [
                        `Your order has been cancelled.`, 
                    ]
                },
                ...standardOffer
            ],
            status_text: [
                `<h4>Your Order has been cancelled.</h4>`,
            ]
        }
    ]
   

   
}

function sortDate(arr) {
    return arr.sort((a,b) => {
        let date1 = new Date(a.stamp);
        let date2 = new Date(b.stamp);
        return date2 - date1;
    })
    
}

function editOrder(editID) {
    removeWaiting()
    let current = true
    let editing = false
    let currentOP = ''
    let changeOrder
    // console.log(editing)
    
    // filter and sort thisDetails by date
    let thisDetails = orderDetails.filter(o => o.orderID == editID);
    
    thisDetails.forEach(d=>{
        if((d.action=='update' || d.field=='created') && typeof(d.content)=='string') d.content = JSON.parse(d.content)
    })
    thisDetails = sortDate(thisDetails)
    let thisOrder = thisDetails.filter(d=> (d.field=='created' || d.action=='update') && d.valid=='1')
    thisOrder = thisOrder[0]
    console.log(thisOrder)
    // showStages
    let thisStage = stages(thisOrder).find(s => s.index==thisOrder.content.stages[0].index)
    console.log(thisStage)
    // if(thisOrder.content.payment && thisOrder.content.payment.received==true && thisStage.index==0) {
    //     console.log('payment info included',thisStage.index)
    //     updateOrder(thisOrder.orderID,'payment received',JSON.stringify(thisOrder.content),'stage','auto','').then(function() {
    //         editID(editID)
    //     })
    // }
    // confirm messages
    if(thisOrder.content.messages) {
        thisOrder.content.messages.forEach(m=>{
            if(m.content == 'duration') {
                let diff = new Date() - new Date(m.stamp)
                if(diff < 0) m.view = false
            }
            if(m.content == 'stage') {
                if(m.stage !== thisStage.index) m.view = false
            }
        })
    }

    // console.log(thisDetails);
    let c = `<div class=' flexr' style='align-items:stretch'><div style=''><h3 style='text-align:center'>Stages</h3><div class='editOrder-set stage-set'><div class='flexr' style='align-items:stretch;'><div class='stage-window'>`
    let u=0;
    stages(thisOrder).forEach(s=>{
        let done; let js;
        if(u <= thisStage.index) {done = `done`;js='';} else {done = '';js=s.js}
        c += `<div data-calls='${s.index}' class='${done} item'>${s.stage}</div>`
        u++
    })
    c += `</div>`  // end stage-window
    c += `<div class='actionSet'></div></div>
    <div class='button' data-js='viewStatusPage' style='width:60%;margin:20px auto;cursor:pointer'>View Customer's Status Page</div></div></div>`;  // end action-set && stage-set

    // Order content and Edit
    c += `<div style='flex:1'><h3>Order Content</h3><div class='editOrder-set order-set'></div></div></div>`  // end order-set && top-half

    // Update Feed
    let feed = ['stamp','action','field','content','note','action_by']
    c += `<div class='feed-set editOrder-set'><h3 >Update Feed</h3>
        <div data-js='revealFeed' style='position:absolute;right:0;top:0;width:100px;height:50px;cursor:pointer'>reveal all</div>
        <table class='admin-table' style='width:100%'><thead><tr style='border-bottom:1px solid black'>`;

    feed.forEach(h=> {
        c += `<th>${h}</th>`;
    })

    c += `</thead><tbody>`;
    let p=0;
    // console.log(thisDetails)
    thisDetails.forEach((de)=>{
        if(de.valid=='1') {
            if(de.action=='stage') p++
            if(de.field=='["views"]') {} else {
                c += `<tr style='height:40px'>`
                feed.forEach(h=>{
                    let limit=''
                    if(h=='stamp') de[h] = new Date(de[h]).toLocaleString('en-US')
                    if(h=='content') {
                        let window = ''
                        c += `<td><span data-showOrder='${de.ID}' data-landing='${window}' style='cursor:pointer' class='limitCell'>Click to send to Order Pane</span></td>`
                    } else {
                        c += `<td><span class='${limit}'>${de[h]}</span></td>`

                    }
                })
                c +=  `<td data-js='revokeStage' data-id='${de.ID}' style='width:60px;font-size:85%;cursor:pointer'>Remove</td>`
                c += `</tr>`;
            }

        }
        if(de.valid=='0') {
            //if(de.action=='stage') p++
            c += `<tr class='invalid hide' style='height:40px'>`
            feed.forEach(h=>{
                let limit=''
                if(h=='stamp') de[h] = new Date(de[h]).toLocaleString('en-US')
                if(h=='content') {
                    let window = ''
                    c += `<td><span data-showOrder='${de.ID}' data-landing='${window}' style='cursor:pointer' class='limitCell'>Click to send to Order Pane</span></td>`
                } else {
                    c += `<td><span dclass='${limit}'>${de[h]}</span></td>`

                }
            })
            c +=  `<td data-js='reinstateDetail' data-id='${de.ID}' style='width:60px;font-size:85%;cursor:pointer'>Reinstate</td>`
            c += `</tr>`;

        }
    })
    c+ `</tbody></table></div>` // end table and feed-set

    c += `</div>` // end order-set


    $('.admin-content').html(c);
    

    displayOrderPane(thisOrder)


    function displayOrderPane(order,info) {
        console.log(order,info)
        let updateArt = {}
        if((order.action=='update' || order.field=='created') && typeof(order.content)=='string') order.content = JSON.parse(order.content)
        let orderInfo = order.content
        if(thisOrder.ID == order.ID) {
            current = true
        } else {
            current = false
        }

        // stage info
        let stageLabel = ''; let stageStamp = ''; let orderStage; let stageBy = '';
        if(orderInfo.stages) {
            orderStage = orderInfo.stages[orderInfo.stages.length-1]
        } else {
            orderStage = determineStage(order.orderID);
            orderStage.stamp = order.stamp
        }
        console.log(orderStage)
        stageLabel = orderStage.stage || orderStage.name
        stageStamp = new Date(orderStage.stamp)
        let stageNum = orderStage.number
        stageBy = orderStage.by || ''

        // console.log(orderInfo,current)
        let content = ``;
        let order_c = {
            contact: [{title:'name',path:'name'},{title:'email',path:'email'}],
            selection: ['artID','storeID','canvas'],
            shipping: ['name','street','city','state','zip','shippingCharge','actualShippingCharge','trackingNum','service_code'],
            shipping_each: ['boxCharge','shipping_amount',]
        }
        content += ``;
        if(editing) {
            $('.order-set').css('border','12px solid green')
        } else {
            $('.order-set').css('border','none')
        }
        content += `<div id='orderMenuEdit-set' class='flexr' >
            <div class='menu'>
                <div data-click='summary' class='item'>Summary</div>
                <div data-click='stages' class='item'>Stage</div>
                <div data-click='contact' class='item'>Contact</div>
                <div data-click='selection' class='item'>Selection</div>
                <div data-click='shipping' class='item'>Shipping</div>
                <div data-click='payment' class='item'>Payment</div>
                <div data-click='discount' class='item'>Discount</div>
                <div data-click='note' class='item small'>Note</div>
                <div data-click='message' class='item small'>Message</div>
                <div data-click='email' class='item small'>Email</div>
                <div data-click='views' class='item small'>Views</div>
                <div data-click='clear' class='item small'>Hide</div>
            </div>`
        content += `<div class='window-set'>`
        content += `<div class='order-c summary window'>`
        content += `<div class='item'><p>name</p><h5>${orderInfo.contact.name}</h5></div>`
        content += `<div class='item'><p>email</p><h5>${orderInfo.contact.email}</h5></div>`
        content += `<div class='item'><p>total</p><h5>total: $${parseInt(orderInfo.payment.current.total).toFixed(2)}</h5></div>
        <div class='item'><p>street</p><h5>${orderInfo.shipping && orderInfo.shipping.street || ''}</h5></div>
        <div class='item'><p>zip</p><h5>${orderInfo.shipping && orderInfo.shipping.zip || ''}</h5></div>
        <div class='item'><p>service</p><h5>${orderInfo.shipping && orderInfo.shipping.service_type || ''}</h5></div>
        <div class='item'><p>shipping</p><h5>shipping: $${parseInt(orderInfo.shipping && orderInfo.shipping.shippingCharge || '').toFixed(2)}</h5></div>
        <div class='item'><p>current stage</p><h5>${stageLabel}</h5></div>`
        content += `</div><div class='order-c contact window hide'>`
        if(!editing) {
            content += `<div class='item'><p>name</p><h5>${orderInfo.contact.name}</h5></div>`
            content += `<div class='item'><p>email</p><h5>${orderInfo.contact.email}</h5></div>`
        } else {
            content += `<div class='item'><p>name</p><input class='alter' data-pane='contact' name='name' data-path='name' value='${orderInfo.contact.name}'></div>
            <div class='item'><p>email</p><input class='alter' data-pane='contact' name='email' data-path='email' value='${orderInfo.contact.email}'></div>`
        }
        content += `</div><div class='order-c stages window hide'>`
        
        content += `
        <div class='item'><p>current stage</p><h5>${stageLabel}</h5></div>
        <div class='item'><p>stamp</p><h5>${stageStamp.toLocaleString()}</h5></div>
        ${stageBy !== '' ? `<div class='item'><p>by</p><h5>${stageBy}</h5></div>` : ''}
        `
        if(editing) {
            content += `<div class='item'><p>change stage</p><select id='stageSelect'>
            <option data-index='0' ${stageLabel == 'created' ? 'selected' : ''} value='created'>created</option>
            <option data-index='1' ${stageLabel == 'payment received' ? 'selected' : ''} value='payment received'>payment received</option>
            <option data-index='2' ${stageLabel == 'confirmed' ? 'selected' : ''} value='confirmed'>confirmed</option>
            <option data-index='3' ${stageLabel == 'packaged' ? 'selected' : ''} value='packaged'>packaged</option>
            <option data-index='4' ${stageLabel == 'shipped' ? 'selected' : ''} value='shipped'>shipped</option>
            <option data-index='5' ${stageLabel == 'delivered' ? 'selected' : ''} value='delivered'>delivered</option>
            <option data-index='6' ${stageLabel == 'closed' ? 'selected' : ''} value='closed'>closed</option>
            <option data-index='8' ${stageLabel == 'cancelled' ? 'selected' : ''} value='cancelled'>cancelled</option>
            <option data-index='7' ${stageLabel == 'refunded' ? 'selected' : ''} value='refunded'>refunded</option>
            </select></div>
            <div class='item' style='justify-content:flex-end'><div style='max-width:80px' class='button changeStage' data-type=''>Apply New Stage</div></div>`
        } else {
            if(orderInfo.stages) {
                content += `<div class='item'><h4 style='margin:15px auto 5px;font-size:120%;border-bottom:1px solid white'>feed</h4></div><div style='width:100%;max-height:100px;border-top:1px solid #878787;border-bottom:1px solid #878787;overflow:auto;padding-top:22px;box-sizing:border-box'>`
                let listStages = sortDate(orderInfo.stages)
                orderInfo.stages.forEach(s=>{
                    let stmp = new Date(s.stamp)
                    content += `<div class='item'><h5>${s.stage}</h5><p>${stmp.toLocaleString()}</p><p>by: ${s.by || 'na'}</p></div>`
                    
                })
                content += `</div>`
            }
        }
        content += `</div><div class='order-c selection window hide'>`
        if(editing) content += `<div class='button-set'>
            <div class='button newCartItem'>Add Cart Item</div>
            <div class='button' id='shippingRecalc'>Recalculate Shipping</div>
            </div>`
        //  Render Selection Pane
        if(orderInfo.cart) orderInfo.cart.forEach((e,i)=>{
            content += renderCartItem(e,i)
        })


        content += `</div><div class='order-c shipping window hide'>`
        order_c.shipping.forEach(s=>{
            // if(orderStage.index > 5) {
            //     content += `<div class='item'><p>Actual Shipping:</p><input id='actualShipping'></div>` 
            // }
            content += `<div class='item'>`
            if(s=='shippingCharge') {
                content += `<p>${s}</p>${editing ? `$<input name='' class='alter' data-path='${s}' data-pane='shipping' value='${parseInt(orderInfo.shipping.shippingCharge).toFixed(2) || ''}'>` : `<h5>$${parseInt(orderInfo.shipping.shippingCharge).toFixed(2) || ''}</h5>`}` 
            } else if(s=='service_code') {
                let select = `<select data-path='service_code' class='alter' name='service_code' data-pane='shipping'>`
                shippingServices.forEach(s=> {
                    let selected = s == orderInfo.shipping.service_code ? 'selected' : ''
                    select += `<option ${selected} value='${s}'>${s}</option>`
                })
                select += `</select>`
                content += `<p>${s}</p>${editing ? `${select}` : `<h5>${orderInfo.shipping[s] || ''}</h5>`}`
            } else {
                content += `<p>${s}</p>${editing ? `<input name='' class='alter' data-path='${s}' data-pane='shipping' value='${orderInfo.shipping[s] || ''}'>` : `<h5>${orderInfo.shipping[s] || ''}</h5>`}`
            }
            content += `</div>`
        })
        content += `
            <div class='address-reveal '></div>
            <div class='button-set flexr'>
                <div data-click='per-item' class='button'>View per Item</div>
                ${editing ? `<div id='shippingRecalc' class='button'>Recalculate Shipping Costs</div>
                <div id='verifyAdd' class='button'>Verify Address</div>` : ''}
            </div>
        </div> 

        <div class='order-c per-item window hide'>`;
        if(orderInfo.shipping && orderInfo.shipping.each) orderInfo.shipping.each.forEach(s=> {
            let t = orderInfo.cart.find(c=> c.ID==s.cartID);
            if(t) {
                content += `<div class='item'><h5>${t.title}</h5></div>`
                content += `<div class='item'><p>${t.size}</p><p>${t.type}</p></div>`
                order_c.shipping_each.forEach(k => {
                    content += `<div class='item'><p>${k}</p><h5>$${parseInt(s[k]).toFixed(2)}</h5></div>`
                }) 
                if(orderInfo.shipping.each.length > 0) content += `<hr>`

            }
        })
        content += `</div>
        <div class='order-c payment window hide'>`;
        content += `
            <div class='item'><p>subtotal:</p><h5>$${orderInfo.payment.current.subTotal}</h5></div>
            <div class='item'><p>discount:</p><h5>${orderInfo.payment.current.discountType=='dollar' ? '$' : ''} ${orderInfo.payment.current.discount && orderInfo.payment.current.discount || ''}${orderInfo.payment.current.discount && orderInfo.payment.current.discountType=='percent' ? '%' : ''}</h5></div>
            <div class='item'><p>tax:</p><h5>$${orderInfo.payment.current.tax.toFixed(2)}</h5></div>
            <div class='item'><p>Total charge:</p><h5>$${orderInfo.payment.current.total}</h5></div>
            <div class='item'><p>Amount received:</p><h5>$${orderInfo.payment.current.amount_received || 0}</h5></div>
        `
        if(orderInfo.payment.current) {
            content += `
            <div class='item'><p>method:</p><h5>${orderInfo.payment.current.method}</h5></div>
            <div class='item'><p>number:</p><h5>${orderInfo.payment.current.number}</h5></div>
            <div class='item'><p>when:</p><h5>${order.stamp}</h5></div>`
        } else {
            content += `<div class='item'><h5>No payment info</h5></div>`
        }
        if(orderInfo.stages) {
            content += `<div class='item'><h4 style='margin:15px auto 5px;font-size:120%;border-bottom:1px solid white'>feed</h4></div><div style='width:100%;max-height:100px;border-top:1px solid #878787;border-bottom:1px solid #878787;overflow:auto;padding-top:22px;box-sizing:border-box'>`
            let listStages = sortDate(orderInfo.stages)
            orderInfo.payment.feed.forEach(s=>{
                let stmp = new Date(s.stamp)
                content += `<div class='item'><h5>${s.action}</h5><p>${stmp.toLocaleDateString()}</p>${s.method ? `<p>${s.method}</p>` : ''}${s.number ? `<p>#:${s.number}</p>` : ''}<p>${s.by}</p></div>`
                
            })
            content += `</div>`
        }
        if(editing) {
            content += `
            <div class='button-set flexc'>
                <hr>
                <p>paypal #</p><input id='paypalNum' style='max-width:200px;margin:0 auto' value='${orderInfo.payment.current && orderInfo.payment.current.number || ''}'>
                <div data-type='paypal' class='changePayment button centered'>Submit Paypal Number</div>
                <hr>
                <div data-type='clear' class='changePayment button centered'>Clear Payment Data</div>
                <div data-type='paypalButtons' class='changePayment button centered'>show Paypal buttons</div>
                <hr>
                <p>refund id#:</p><input id='refundNumber' style='max-width:200px;margin:0 auto' value=''>
                <p>refund amount:</p><input id='refundAmount' style='max-width:200px;margin:0 auto' value='${orderInfo.payment.current && orderInfo.payment.current.total || ''}'>
                <div data-type='refunded' class='changePayment button centered'>Refund Issued</div>
                <hr>
                <div data-type='venmo' class='changePayment button centered'>Customer Paid w/ Venmo</div>
                <div data-type='cash' class='changePayment button centered'>Customer Paid w/ Cash</div>
            </div>
            ` 
        }
           
        content += `</div>
        <div class='order-c email flexc window hide'>
            <input style='padding:8px;width:80%;border-radius:8px' placeholder='enter subject' id='subject' value=''>
            <p style='font-size:85%;color:black;margin:15px 0'>** type &ltbr&gt for line breaks **</p><textarea  id='body' placeholder='Email body here' style='padding:15px;border-radius:12px;width:85%;font-size:11px' rows='12'  ></textarea>
            <div class='button-set' style='flex-flow:row wrap'>`
            if(thisStage.email_offer) {
                thisStage.email_offer.forEach(o=> {
                    content += `<div data-tAdd='' style='' class='button'>${o.title}</div>`
                })
            }
            content += `</div>
            <h4>Note</h4><textarea  id='note' style='padding:15px;border-radius:12px;width:75%' rows='3' placeholder='add a note to yourself for future reference, customer will not see this'></textarea>
            <div class='admin-button-set'>
                <div class='admin-button' style='background:#878787' data-js='testEmail'>Send Test Email</div>
                <div class='admin-button' data-js='sendEmail'>Send Email</div>
            </div>
        </div>
        <div style='margin:20px auto' class='order-c views window hide'>`
            if(orderInfo.views) {
                orderInfo.views.forEach(v=>{
                    let date = new Date(v.stamp)
                    content += `<div class='item'><p>View:</p><h4>${date.toLocaleString()}</h4></div>`
                })
            } else {
                content += `<div class='item'><h4>No Views</h4></div>`
            }
        content += `</div>
        <div style='margin:20px auto' class='order-c note window hide'>`
            if(orderInfo.notes) {
                orderInfo.notes.forEach(m=>{
                    let date = new Date(m.stamp)
                    date = date.toLocaleTimeString();
                    if(m.view) {
                        content += `
                        <div class='item' style='justify-content:center;font-size:110%'>${m.body}</div>
                        <div class='item'><p>from:</p><h4>${m.from}</h4></div>
                        <div class='item'><p>time/day:</p><h4>${date}</h4></div>
                        ${editing ? `<div class='item'><div class='button changeComm' data-id='${m.id}' data-type='remove' data-what='notes' >Remove</div></div>` : ''}
                        <hr>`
                    } else {
                        content += `
                        <div class='item' style='font-size:85%'><p>Note removed</p><h5>${m.body.substring(0,20)}</h5></div>
                        ${editing ? `<div class='item'><div class='button changeComm' data-id='${m.id}' data-type='reinstate' data-what='notes'>Resinstate</div></div>` : ''} <hr>`
                    }
                })
            } else {
                content += `<div class='item'>No Notes</div>`
            }
            if(editing) {
                content += `
                <textarea  id='noted' style='padding:15px;border-radius:12px;width:250px' rows='3'  placeholder='this message will appear on the customers status page'>${!current && order.action=='note' ? orderInfo : ''}</textarea>
                <div class='admin-button-set'>
                    ${current ? `<div class='admin-button changeComm' data-type='note'>Apply Note</div>` : ''}
                </div>`
            }
        content += `</div><div style='margin:20px auto' class='order-c message window hide'>`

            if(orderInfo.messages) {
                orderInfo.messages.forEach(m=>{
                    let date = new Date(m.stamp)
                    date = date.toLocaleString()
                    if(m.view) {
                        content += `
                        <div class='item' style='justify-content:center;font-size:110%'>${m.body}</div>
                        <div class='item'><p>from:</p><h4>${m.from}</h4></div>
                        <div class='item'><p>time/day:</p><h4>${date}</h4></div>
                        ${editing ? `<div class='item'><div class='button changeComm' data-id='${m.id}' data-type='remove' data-what='messages' >Remove</div></div>` : ''}
                        <hr>`
                    } else {
                        content += `
                        <div class='item' style='font-size:85%'><p>Note removed</p><h5>${m.body.substring(0,20)}</h5></div>
                        ${editing ? `<div class='item'><div class='button changeComm' data-id='${m.id}' data-type='reinstate' data-what='messages'>Resinstate</div></div>` : ''} <hr>`
                    }
                })
            } else {
                content += `<div class='item'>No Messages</div>`
            }
            if(editing) {
                content += `
                <textarea  id='message' style='padding:15px;border-radius:12px;width:250px' rows='3'  placeholder='this message will appear on the customers status page'>${!current && order.action=='message' ? orderInfo : ''}</textarea>
                <div style='margin:20px 0' class='item'><p>Remove this message when:</p>
                    <select style='margin-left:25px' name='' id='until'>
                        <option data-removeMethod='duration' data-content='1' value=''>1 Day</option>
                        <option data-removeMethod='duration' data-content='5' value=''>5 Days</option>
                        <option data-removeMethod='duration' data-content='10' value=''>10 Days</option>
                        <option data-removeMethod='other' data-content='stage' value=''>Next Stage</option>
                        <option data-removeMethod='user' data-content='views' value=''>Customer Views</option>
                        <option data-removeMethod='user' data-content='removes' value=''>Customer Removes</option>
                    </select>
                </div>
                <div class='admin-button-set'>
                    <div class='admin-button changeComm' data-type='message'>Apply Message</div>
                </div>`
            }
        let pay = orderInfo.payment
        content += `
        </div>

        <div style='margin:20px auto;align-items:stretch' class='order-c discount flexc window hide'>
            <h4>Discount</h4>
            <div class='flexr item'><p>art total:</p><h4>$${pay.current.art_total}</h4></div>
            <div class='flexr item'><p>Discount:</p><h4>${pay.current.discount ? `${pay.current.discountType=='dollar' ? '$' : ''}${pay.current.discount || ''}${pay.current.discountType=='percent' ? '%' : ''}` : 'none'}</h4></div>
            <div class='flexr item'><p>shipping:</p><h4>$${pay.current.shippingCharge}</h4></div>
            <div class='flexr item'><p>SubTotal:</p><h4>$${pay.current.subTotal}</h4></div>`
            if(editing) {
                content += `
                <hr>
                <div style='align-items:stretch;margin:15px 0;justify-content:center' class='flexr item'>
                    <div class='flexc'><p>Amount:</p><input id='amount' type='number' value='${pay.current.discount ? pay.current.discount : ''}' ></div>
                    <div class='flexc' style='margin-left:25px;justify-content:space-between''>
                        <p>Type:</p>
                        <div data-discountType='percent' ${pay.current.discount && pay.current.discountType=='percent' ? "style='background:red'" : ''} class='button dis-button'>%</div>
                        <div data-discountType='dollar' ${pay.current.discount && pay.current.discountType=='dollar' ? "style='background:red'" : ''} class='button dis-button'>$</div>
                    </div>
                </div>
                <hr>
                <div style='align-items:stretch;justify-content:center' class='flexr item'>
                    ${pay.current.discount==0 ? `<div class='button dis-button changeDiscount' data-type='addDiscount'>Apply Discount</div>` : ''}
                    ${pay.current.discount ? `<div class='button dis-button changeDiscount' data-type='removeDiscount'>Remove Discount</div>` : ``}
                </div>
                <textarea  id='note2' style='padding:10px;border-radius:12px;width:250px;margin:12px auto' rows='3'   value='${pay.current.discount && pay.current.discount.note || ''}'>${pay.current.discount && pay.current.discount.note || ''}</textarea>
                `
            } else {
                content += `<div class='flexr item'><p>Note:</p><h4>${pay.current.discount ? pay.current.discountNote : ''}</h4></div>`
            }
            content += `<div style='color:#878787;font-size:85%' class='flexr item'><p>Tax:</p><h4 id='tax'>$${(pay.current.subTotal * .07).toFixed(2)}</h4></div>
            <div class='flexr item'><p>Total:</p><h4 id='total'>$${pay.current.total && pay.current.total.toFixed(2)}</h4></div>
            
        </div>`
        content += `</div></div>` // end window-set && orderMenuEdit-set
        content += `<div class='button-set flexr' style='width:80%;margin:20px auto;order:3'>`
        if(editing) content += `<div data-id='${order.orderID}' class='button returnToCurrent'>Clear Changes</div>
        <div data-id='${order.orderID}' class='button' id='saveUpdate'>Save Changes</div>`
        if(current && !editing) content += `<div data-id='${order.orderID}' class='button' id='editOrder'>Edit Details</div>`
        if(!current) content += `<p>You are viewing past activity</p><div class='button returnToCurrent'>Return to most recent</div>`
        content += `</div>`
        content += `${editing ? `<p style='order:4'>Edited</p>` : ''}<div class='flexr' id='edited' style='justify-content:flex-start;width:80%;margin:8px auto;padding:10px 0;order:5;border-top:1px solid #878787'></div>`
        
        
        // render content
        $('.editOrder-set.order-set').html(content)

        renderEdited()
        // console.log(changeOrder)
        function renderEdited() {
            let eCon = ``;
            if(changeOrder && changeOrder.edited) {
                changeOrder.edited.forEach(e=>{
                    eCon += `<div style='margin:0 10px;background:grey;padding:8px 22px;border-radius:5px;color:white;position:relative;'>
                    <div class='changeEdited' data-type='remove' style='position:absolute;right:0;top:0;width:15px;height:15px;font-size:14px;cursor:pointer'>X</div>
                    <p class='viewEditedPane'>${e}</p>
                    </div>`
                })
                eCon += `<div style='font-size:22px;cursor:pointer;background:#454f6d;color:white;padding:5px 12px;margin:0 5px 0 auto' id='inputEdited'>+</div><div class='hide' value='' id='newEdited'><input id='editedInput' style='margin:0 8px'><h4 id='submitNewEdited' style='padding:5px 22px;background:green;border-radius:8px;cursor:pointer;color:white'>ADD</h4>`
            }
            $('#edited').html(eCon)
            $('#inputEdited').on('click',function() {
                $('#newEdited').toggleClass('hide')
                if($("#newEdited").hasClass('hide')) {
                    $('#inputEdited').html('+') 
                } else {
                    $('#editedInput').focus()
                    $('#inputEdited').html('-')
                }
            })
            $('#submitNewEdited').on('click',function() {
                console.log('add has been clicked')
                addToEdited($('#editedInput').val())
            })
            $('.changeEdited').on('click',function() {
                if($(this).attr('data-type')=='remove') {
                    removeFromEdited($(this).siblings('p').text())
                }
            })
            $('#editedInput').on('keypress',function(e) {
                if(e.which == 13) {
                    $("#submitNewEdited").trigger('click')
                }
            });
            $('.viewEditedPane').on('click',function() {
                let text = $(this).text()
                console.log(text)
                $(`[data-click='${text}']`).trigger('click')
                
            })
        }

        function populateTitle(cartItem) {
            let content = ``; let list = []; let renderList = []
            if(cartItem) content += `<option selected value='${cartItem.artID}'>${cartItem.title}</option>`
            adArtList = adArtList.sort((a,b)=>{
                if(a.title<=b.title) return -1
                if(a.title>b.title) return 1
            })
            adArtList.forEach(a=>{
                if(!list.includes(a.title))  {
                    list.push(a.title)
                    content += `<option value='${a.ID}'>${a.title}</option>`
                }
            })
            return content
        }

        function populateSize(sid) {
            let content = ``
            //let item
            let sItem
            let aid = $(".title-select").find(':selected').val()

            if(sid) {
                sItem = adStoreList.find(a=>a.ID==sid)
                content += `<option selected value='${sItem.ID}'>${sItem.size}${sItem.type=='original' ? ' - O' : ''}</option>`
                if(!aid) aid = sItem.artID
            }
            if(!aid && !sid) {} else {
                let renderList = adStoreList.filter(a=>a.artID==aid) 
                renderList.forEach(r=> {
                    content += `<option value='${r.ID}' >${r.size}${r.type=='original' ? ' - O' : ''}</option>`
                })
            }
            return content
        }

        function renderCartSelect(item,cI) {
            //console.log(cI)
            let sC = `<select class='${item}-select' name='${item}'>`
            
            if(item=='title') sC += populateTitle(cI)

            if(item=='size') sC += populateSize(cI && cI.storeID || '')

            if(item=='type') {
                sC += `<option selected value='${cI && cI.type}'>${cI && cI.type || ''}</option>`
                sC += `<option value='original'>original</option>`
                sC += `<option value='print'>print</option>`
                sC += `<option value='print on canvas'>print on canvas</option>`
            }
            sC += `</select>`
            return sC
        }

        function renderCartItem(e,i) {
            //console.log(e)
            let count = $('[data-cartItem]').length
            //console.log(i,count)
            let cartContent = `
            <div class='flexr' data-cartItem='${i || count}' style='align-items:stretch;width:100%'>
                <div class='flexc cart-edit'>
                    ${editing ? renderCartSelect('title',e) : `<p style='width:100%'>${e.title}</p>`}
                    ${editing ? renderCartSelect('size',e) : `<p style='width:100%'>${e.size}</p>`}
                    ${editing ? renderCartSelect('type',e) : `<p style='width:100%'>${e.type}</p>`}
                </div>
                <img style='margin:0 20px' src='https://www.graceannart.com/assets/images/thumb/${e && e.fileName || ''}' width='70'>
                <div class='flexr'>
                ${editing ? `
                <div class='button-set'>
                    <div class='button removeFromCart' style='font-size:80%' >Remove<br>from Cart</div>
                    <div class='button updateCart' style='font-size:80%' >Update<br>item's changes</div>
                </div>` : ''}
            </div></div><hr style='margin:20px auto;width:80%'>`
            return cartContent
        }

        function checkRates() {
            console.log(changeOrder);
            let rateResponse = [];
            $('.rate-display').css('height','200px')
            waiting('.rate-display');
            let i=0;
            changeOrder.content.cart.forEach((item) => {
                $.ajax({
                    beforeSend: function() {
                    },
                    type: 'POST',
                    url: "ajax/shipping.php?checkRates",
                    data: {
                        item,
                        orderDetails: changeOrder.content
                    },
                    success: function(data) {
                        i++;
                        //console.log(data);
                        let temp = JSON.parse(data);
                        temp.cartItemID = item.ID;
                        temp.boxCharge = item.boxCharge 
                        rateResponse.push(temp);
                        console.log
                        if(i == changeOrder.content.cart.length) {
                            //console.log('displaying rates')
                            displayRates(rateResponse);
                        } else {
                            console.log('not yet')
                        }
                    }
                })
            })
        
            function displayRates(rateResponse) {
                console.log(rateResponse);
                $('#checkRates').html('Choose Rate Below');
                $('.rate-display').html(`<table id='rateTable' style=''></table>`);
                let rateDisplay = [];
                shippingServices.forEach((service) => {
                    //console.log(service)
                    let obj = {service_code:service,totalCharge:0,each:[],id:nanoid()};
                    let i = 0;
                    rateResponse.forEach((rate) => {
                        let thisService = rate.rate_response.rates.find((ra) => {
                            return ra.service_code == service
                        })
                        // console.log(thisService,service);
                        if(thisService) {
                            
                            obj.totalCharge = obj.totalCharge + thisService.shipping_amount.amount + parseInt(rate.boxCharge)
                            obj.service_type = thisService.service_type;
                            let eachObj = {
                                shipment_id: rate.rate_response.shipment_id,
                                rate_id: thisService.rate_id ,
                                rate_request_id: rate.rate_response.rate_request_id,
                                shipping_amount: thisService.shipping_amount.amount,
                                cartID: rate.cartItemID,
                                boxCharge: rate.boxCharge
                            }
                            
                            obj.each.push(eachObj);
                            //console.log(obj)
                            i++
                            if(i==rateResponse.length) {
                                rateDisplay.push(obj);
                                let line = `
                                    <tr class='press rate-item' data-id='${obj.id || ''}'>
                                        <td style='text-align:left'>${obj.service_type}</td>
                                        <td style='text-align:right' data-totalCharge='${obj.totalCharge.toFixed(2)}'>$${obj.totalCharge.toFixed(2)}</td>
                                    </tr>
                                `;
                                $('#rateTable').append(line)
                            }
                        }
                    })
                })
        
                console.log(rateDisplay);
        
                $('[data-id]').on('click',function() {
                    console.log(changeOrder)
                    let rate_id = $(this).attr('data-id');
                    let thisRate = rateDisplay.find(rate => rate.id == rate_id)
                    changeOrder.content.shipping.shippingCharge = thisRate.totalCharge;
                    changeOrder.content.shipping.service_code = thisRate.service_code
                    changeOrder.content.shipping.service_type = thisRate.service_type
                    changeOrder.content.shipping.chosenRates = true;
                    changeOrder.content.shipping.each = thisRate.each
                    console.log(changeOrder.content.shipping);
                    addToEdited('shipping')
                    $('.rate-display').remove()
                    $("[data-path='shippingCharge']").val(changeOrder.content.shipping.shippingCharge)
                    $("[data-path='service_code']").find(`option[value='${changeOrder.content.shipping.service_code}']`).attr('selected','selected')
                    $("[data-path='shippingCharge']").val(changeOrder.content.shipping.shippingCharge)
                })
        
                removeWaiting();
            }
        
        }
        
        function checkAddress() {
            console.log(changeOrder)
            // waiting('.address-reveal')
            let street = $("[data-path='street']").val();
            let city = $("[data-path='city']").val();
            let state = $("[data-path='state']").val();
            let zip = $("[data-path='zip']").val();
            console.log(street,city,state,zip);
            if(street && city && state && zip) {
                $(`#checkAddress`).hide();
                $.ajax({
                    type: 'POST',
                    url: "ajax/shipping.php?addressValidate",
                    data: {
                        street,
                        city,
                        state,
                        zip
                    },
                    success: function(data) {
                        //console.log(data)
                        removeWaiting();
                        let addressValidate = JSON.parse(data);
                        addressValidate = addressValidate[0];
                        $('.address-reveal').html(`
                            <p>${addressValidate.matched_address.address_line1}</p>
                            <p>${addressValidate.matched_address.city_locality} ${addressValidate.matched_address.state_province}</p>
        
                            <p>${addressValidate.matched_address.postal_code}</p>
                            <p style='color:red'>Does this address look correct?</p>
                            <div id='confirmAddress' class='button'>Confirm</div>
                            `);
                        console.log(addressValidate.matched_address);
                        //adjustCartItemHeight('address');
                        $('#confirmAddress').on('click',function() {
                            changeOrder.content.shipping.street = addressValidate.matched_address.address_line1;
                            changeOrder.content.shipping.city = addressValidate.matched_address.city_locality;
                            changeOrder.content.shipping.state = addressValidate.matched_address.state_province;
                            changeOrder.content.shipping.zip = addressValidate.matched_address.postal_code;
                            changeOrder.content.verified = true;
                            //infoPage();
                            $('#checkAddress').show();
                            console.log(changeOrder)
                            $("[data-path='street']").val(changeOrder.content.shipping.street);
                            $("[data-path='city']").val(changeOrder.content.shipping.city);
                            $("[data-path='state']").val(changeOrder.content.shipping.state);
                            $("[data-path='zip']").val(changeOrder.content.shipping.zip);
                            $('.address-reveal').empty()
                        });
        
        
        
                    }
                });
            } else {
                $('#verifyAddress').html(`<p style='color:red'>Missing Information</p>`);
                //adjustCartItemHeight('address');
            }
        
        
        }

        $(".newCartItem").on('click',function() {
            $('.window.selection').append(renderCartItem())
            addToEdited('selection')
            console.log(changeOrder)
        })

        $(".selection").on('click',".removeFromCart",function() {
            let index = $(this).parents('[data-cartItem]').attr('data-cartItem') 
            $(this).parents(`[data-cartItem='${index}']`).addClass('coverBlur')
            $(this).parents(`[data-cartItem='${index}']`).after(`<div class='button removeUndo'>Undo</div>`)
            let removing = changeOrder.content.cart[index]
            changeOrder.content.cart.splice(index,1)
            console.log(changeOrder)
            addToEdited('selection')
            $('.removeUndo').on('click',function() {
                changeOrder.content.cart.splice(index,0,removing)
                $(this).prev('[data-cartItem]').removeClass('coverBlur')
                $(this).remove()
                console.log(changeOrder)
            })
        })

        $(".selection").on('click',".updateCart",function() {
            console.log(JSON.parse(JSON.stringify(changeOrder)))
            addToEdited('selection')
            //changeOrder.content.cart = []
            let index = $(this).parents('[data-cartItem]').attr('data-cartItem') 
            let sid = $(this).parents('[data-cartItem]').find('.size-select').find(':selected').val()
            let item = adStoreList.find(a=>a.ID==sid)
            let artItem = adArtList.find(a=>a.ID==item.artID)
            console.log(item,artItem)
            let obj = {}
            for(let k in item) {
                obj[k] = item[k]
            }
            obj.shippingWeight = item.weight
            obj.storeID = item.ID
            obj.title = item.title
            obj.ID = nanoid()
            obj.fileName = artItem.fileName
            obj.title = adArtList.find(a=>a.ID==obj.artID).title
            console.log(obj)
            if(index < changeOrder.content.cart.length) {
                console.log('editing existing')
                changeOrder.content.cart.splice(index,1,obj)
            } else {
                changeOrder.content.cart.push(obj)
                console.log('add new item')
            }
            console.log(changeOrder)
        })

        $(".window").on('click', '#shippingRecalc',function() {
            $(this).parents('.button-set').after(`<div class='rate-display'></div>`)
            checkRates()
        })

        $('#verifyAdd').on('click', function() {
            checkAddress()
        })

        $(".selection").on('change',".title-select",function() {
            //console.log(adStoreList)
            $(this).parents('[data-cartItem]').find('.size-select').html(populateSize())
            console.log($(this).find(':selected').text())
            let fileName = adArtList.find(a=> a.title==$(this).find(':selected').text()).fileName
            console.log(fileName)
            $(this).parents('[data-cartItem]').find('img').attr('src',`https://www.ecomm.bradwrightwebdev.com/assets/images/${fileName}`)
        })
        
        $('.selection').on('change','.size-select',function() {
            $(this).siblings('.type-select').find(`option[value='print']`).attr('selected','selected')
            addToEdited('selection')
        })

        $('.selection').on('change','.type-select',function() {
            addToEdited('selection')
            let val = $(this).val()
            if(val=='original') {
                let sid = adStoreList.find(a=> {
                    return a.artID==$(this).siblings(".title-select").find(':selected').val() && a.type=='original'
                }).ID 
                $(this).parents('[data-cartItem]').find('.size-select').html(populateSize(sid))
            }
        })

        $('[data-click').off('click').on('click',function() {
            let click = $(this).attr('data-click');
            $('.order-c').toggleClass('hide',true);
            currentOP = click
            if(currentOP=='email') {
                $('#editOrder').hide()
            } else {
                $('#editOrder').show()
            }
            if(click=='clear') return
            $('.'+click).toggleClass('hide')
            // onsole.log(currentOP)
        })
        
        $('.window-set').on('click','.changeComm',function() {
            let type = $(this).attr('data-type')
            if(type=='message') {
                addToEdited('message')
                let val = $('#message').val()
                let until = $('#until option:selected').attr('data-removeMethod')
                let content = $('#until option:selected').attr('data-content')
                let obj = {body:val,stamp:new Date(),removeMethod:until,content:content,view:true,id:nanoid(),from:'admin',stage:thisStage.index}
                console.log(obj)
                if(changeOrder.content.messages) changeOrder.content.messages.push(obj)
                if(!changeOrder.content.messages) changeOrder.content.messages = [obj]
                displayOrderPane(changeOrder,'message')
            }
            if(type=='remove') {
                let id = $(this).attr('data-id')
                let what = $(this).attr('data-what')
                let item = changeOrder.content[what].find(n=>n.id==id)
                item.view = false
                console.log(note)
                if(what=='notes') displayOrderPane(changeOrder,'note')
                if(what=='messages') displayOrderPane(changeOrder,'message')
            }
            if(type=='reinstate') {
                let id = $(this).attr('data-id')
                let what = $(this).attr('data-what')
                let item = changeOrder.content[what].find(n=>n.id==id)
                item.view = true
                console.log(note)
                if(what=='notes') displayOrderPane(changeOrder,'note')
                if(what=='messages') displayOrderPane(changeOrder,'message')
            }
            if(type=='note') {
                addToEdited('note')
                let val = $('#noted').val()
                let obj = {body:val,stamp:new Date(),view:true,id:nanoid(),from:'admin'}
                console.log(obj)
                if(changeOrder.content.notes) changeOrder.content.notes.push(obj)
                if(!changeOrder.content.notes) changeOrder.content.notes = [obj]
                displayOrderPane(changeOrder,'note')
            }
            
            console.log(changeOrder.content)

        })

        $('.window-set').on('click','.changeDiscount',function() {
            console.log($('#amount').val())
            let thing = $(this).attr('data-type') 
            if($('#amount').val() !== '') {
                let dis = parseInt($('#amount').val())
                if(thing=='addDiscount') {
                    addToEdited('discount')
                    let type = $(`.selected.dis-button`).attr('data-discountType')
                    console.log(type)
                    let sub
                    let amount = 0
                    let current = changeOrder.content.payment.current
                    let prevAmount = current.art_total
                    if(type=='percent') {
                        amount = current.art_total * (dis / 100)
                    } 
                    if(type=='dollar') {
                        amount = dis
                    }
                    console.log(amount,dis)
                    current.discount = dis
                    current.discountType = type
                    current.subTotal = current.art_total + current.shippingCharge - amount
                    current.tax = Math.round(current.subTotal * 0.07)
                    current.total = current.subTotal + current.tax
                    current.discountNote = $('#note2').val()
                    changeOrder.content.payment.feed.unshift({stamp:new Date(),action:'add discount',by:'admin',type,dis})
                    console.log(changeOrder)
                }
            } 

            if(thing=='removeDiscount') {
                console.log(changeOrder)
                let current = changeOrder.content.payment.current
                current.subTotal = current.art_total + current.shippingCharge
                current.tax = Math.round(current.subTotal * 0.07)
                current.total = current.subTotal + current.tax
                current.discount = 0
                current.discountType = ''
                current.discountNote = $('#note2').val()
                changeOrder.content.payment.feed.unshift({stamp:new Date(),action:'remove discount',by:'admin'})
                console.log(changeOrder)
                removeFromEdited('discount')
            }
            displayOrderPane(changeOrder,'discount')

        })

        $('.window-set').on('click','.changePayment',function() {
            addToEdited('payment')
            let thing = $(this).attr('data-type') 
            // console.log($('#paypalNum').val())
            if(thing=='paypalButtons') {
                let content = addPayPalButtons(orderInfo.payment.current.total)
                $('.payment').append(`<div style='width:50%;margin: 0 auto;'><h2>${orderInfo.payment.current.total}</h2>${content}</div>`)
                $("[data-click='payment']").trigger('click')
            } else {
                let current = changeOrder.content.payment.current
                if(thing=='refunded') {
                    current.method = ''; 
                    current.amount_received = current.amount_received - parseInt($("#refundAmount").val())
                    current.number = $("#refundNumber").val()
                    changeOrder.content.payment.feed.unshift({stamp:new Date(),action:'refunded',by:'admin',amount:parseInt($("#refundAmount").val()) || current.total,number:$("#refundNumber").val() })
                } else {
                    if(thing=='cash') {current.method = 'cash'; current.received = true}
                    if(thing=='venmo') {current.method = 'venmo'; current.received = true}
                    if(thing=='paypal') {current.method = 'paypal'; number = $("#paypalNum").val(); received = true}
                    if(thing=='clear') {current.method = ''; current.received = false}  
                    changeOrder.content.payment.feed.unshift({stamp:new Date(),action:'update method',by:'admin'})
                }
                displayOrderPane(changeOrder,'payment')

            }
        })
        
        $('.window-set').on('change','#stageSelect',function() {
            console.log($(this).val())
            
        })  

        $('.window-set').on('click','.changeStage',function() {
            addToEdited('stage')
            let stageName = $('#stageSelect option:selected').val()
            let obj = {
                stage: stageName,
                stamp: new Date(),
                index: $('#stageSelect option:selected').attr('data-index'),
                by: 'admin'
            }
            let last = changeOrder.content.stages[changeOrder.content.stages.length -1].stage
            console.log(obj)
            if(last && last==stageName) {} else {
                changeOrder.content.stages.push(obj)
                displayOrderPane(changeOrder,'stages')
            }
        })

        if(info) {
            $(`[data-click='${info}']`).trigger('click')
        }

        $(".returnToCurrent").on('click',function() {
            editing = false
            changeOrder = {}
            console.log(thisOrder)
            editing = false
            displayOrderPane(thisOrder,currentOP)
        })

        $('#editOrder').on('click',function() {
            $('.order-set').empty()
            editing = true
            changeOrder = JSON.parse(JSON.stringify(thisOrder))
            changeOrder.edited = []
            console.log(changeOrder)
            displayOrderPane(changeOrder,currentOP)
        })

        $('#saveUpdate').on('click',function() {
            console.log(changeOrder);
            waiting('.order-set')
            updateOrder(editID,JSON.stringify(changeOrder.edited),JSON.stringify(changeOrder.content),'update','admin','');
    
        })

        $('[data-discountType]').on('click',function() {
            console.log(changeOrder)
            $('[data-discountType]').css('background','').removeClass('selected')
            $(this).css('background','green').addClass('selected')
        })

        $('[data-tAdd]').on('click',function() {
            let already = $('#body').val()
            let title = $(this).text()
            let addIt = thisStage.email_offer.find(o=>o.title==title)
            console.log(thisStage,title,addIt)
            let newBody = ''
            addIt.content.forEach(a=>{
                newBody += `\r\n<br>${a} \r\n <br>`    
            })
            $('#body').val(already + newBody)
        })
        
        $('.editOrder-set').on('input','.alter',function(e) {
            let el = $(e.target)
            let pane = el.attr('data-pane')
            let path = el.attr('data-path')
            if(pane=='shipping') {
                changeOrder.content.shipping.chosenRates = false
                changeOrder.content.shipping[path] = el.val()
            } if(pane=='contact') {
                changeOrder.content.contact[path] = el.val()
            } else {
                changeOrder.content.contact[path] = el.val()
            }
            //if(!changeOrder.edited) changeOrder.edited = []
            addToEdited(pane)
            // console.log(changeOrder,thisOrder,path)
        })

        function addToEdited(thing) {
            if(!changeOrder.edited.includes(thing)) changeOrder.edited.push(thing)
            renderEdited()
        }
    
        function removeFromEdited(thing) {
            var index = changeOrder.edited.indexOf(thing);
            if (index !== -1) {
                changeOrder.edited.splice(index, 1);
            }
            renderEdited()
        }

    }

    

    
    // clicks  ********************************************************

    $('[data-showOrder]').on('click',function() {
        let thisID = $(this).attr('data-showOrder')
        let o1 = orderDetails.find(o=>o.ID==thisID)
        console.log(o1)
        let pane = ''
        if(o1.action=='stage') {
            pane = o1.field
            return
        } else if(o1.action=='note') {
            pane = 'note'
        } else if(o1.action=='update') {
            if(o1.field) pane = JSON.parse(o1.field)[0]
        }
        displayOrderPane(o1,pane)
    })

    


    $('[data-calls]').on('click',function() {
        let thisCall = $(this).attr('data-calls')
        let calls = stages(thisOrder).find(s=>s.index==thisCall).call
        let c = ``;
        calls.forEach(n=>{
            c += `<div data-js="${n.js}" data-type='${n.type && n.type=='stage' ? 'stage' : ''}'  style='${n.style ? n.style : ''}' class='item'>`
            
            c += `<div  >${n.content}</div>`;
            c += `</div>`
        })
        $('.actionSet').html(c)

    })

     

    $(`[data-calls=${thisStage.index.toString()}]`).trigger('click')


    // Do stuff
    $('.admin-content').off('click').on('click','[data-js]',function() {
        let js = $(this).attr('data-js')
        let jsType = $(this).attr('data-type')
        console.log(js,thisOrder,changeOrder || 'not there...')

        // move to different section
        if(js=='paypal') {
            $('#editOrder').trigger('click')
            $("[data-click='payment']").trigger('click')
            let content = addPayPalButtons(thisOrder.content.payment.total)
            $('.payment').append(`<div style='width:50%;margin: 0 auto;'><h2>${thisOrder.content.payment.total}</h2>${content}</div>`)
        }

        if(jsType=='stage') {
            $("[data-click='stages']").trigger('click')
            $('#editOrder').trigger('click')
            $(`#stageSelect option[value='${js}']`).attr('selected','selected')
            setTimeout(function() {
                $(".changeStage").trigger('click')
            },300)

            if(js=='payment received' || js=='shipping') {
                setTimeout(function() {
                    $(`[data-click='${js}']`).trigger('click')
                },1000)
            }
        }
      
        // if(js=='confirmed') {

        // }
        // if(js=='packaged') {
        //     thisOrder.content.stages.push({stamp: new Date(),by:'admin',stage:'packaged',index:3})
        //     updateOrder(editID,'packaged','','stage','admin','')
        // }
        // if(js=='shipped') {
        //     thisOrder.content.stages.push({stamp: new Date(),by:'admin',stage:'shipped',index:4})
        //     updateOrder(editID,'shipped','','stage','admin','')
        // }
        // if(js=='delivered') {
        //     thisOrder.content.stages.push({stamp: new Date(),by:'admin',stage:'delivered',index:5})
        //     updateOrder(editID,'delivered','','stage','admin','')
        // }
        // if(js=='closeOrder') {
        //     thisOrder.content.stages.push({stamp: new Date(),by:'admin',stage:'closed',index:6})
        //     updateOrder(editID,'closed','','stage','admin','')
        // }
        // if(js=='cancelled') {
        //     thisOrder.content.stages.push({stamp: new Date(),by:'admin',stage:'cancelled',index:8})
        //     updateOrder(editID,'closed','','stage','admin','')
        // }
        if(js=='refund')  alert('not set up yet')
        if(js=='revokeStage') {
            waiting('.admin-content')
            alterDetail($(this).attr('data-id'),'valid','0').then(function() {
                getOrderDetails().then(function() {

                    editOrder(editID)
                })
            })
        }
        if(js=='reinstateDetail') {
            waiting('.admin-content')
            alterDetail($(this).attr('data-id'),'valid','1').then(function() {
                getOrderDetails().then(function() {

                    editOrder(editID)
                })
            })
        }
        // if(js=='sendStatusUpdate') sendStatusUpdate(editID)
        if(js=='testEmail') sendEmail(false,'brad@eqsquash.com',$('#subject').val(),$('#body').val(),$('#note').val())
        if(js=='sendEmail') sendEmail(true,orderInfo.email,$('#subject').val(),$('#body').val(),$('#note').val())
        if(js=='setEmail')  $("[data-click='email']").trigger('click')
        if(js=='addDiscount') addDiscount()
        if(js=='removeDiscount') removeDiscount()
        if(js=='revealFeed') $('.invalid').toggleClass('hide')
        if(js=='viewStatusPage') {
            let url = new URL(window.location.href);
            if(url.hostname=='localhost') {
                url = url.origin + `/ecomm_demo/index.php?orderStatus=${btoa(editID)}&admin=true`
            } else {
                url = `https://www.ecomm.bradwrightwebdev.com/index.php?orderStatus=${btoa(editID)}&admin=true`
            }
            console.log(url,thisDetails)
            window.open(url, '_blank');
            setTimeout(function() {
                getOrderDetails().then(function() {
                    editOrder(editID)
                })
            },2000)
        }
    })


    

}


// function tAdd(what,ID) {
//     let btoaID = btoa(ID)
    

//     console.log(what)
//     let already = $('#body').val()
//     $('#body').val(already)

// }



function sendEmail(real,to,subject,body,note) {
    if(!real) to = 'brad@bradwrightwebdev.com'
    waiting('.order-set')
    $.ajax({
        type: 'POST',
        url: "ajax/admin.php?sendEmail",
        data: {
            to,
            subject,
            body
        },
        success: function(data) {
            console.log(data);
            removeWaiting()
            if(real) {
                updateOrder(editID,subject,body,'message','admin',note).then(function() {
                    getOrderDetails().then(function() {
                        editOrder(editID);
                    })
                })
            } else {

            }

        }
    });
}

function addPayPalButtons(total) {
    console.log('paypalling the buttons',total)

    return  `
    <div id='ffWindow'></div>
    <p>Please click a button below to submit payment</p>

    <div id="smart-button-container">
        <div style="text-align: center;">
            <div id="paypal-button-container"></div>
        </div>
    </div>
    <script>
        function initPayPalButton() {
            paypal.Buttons({

            style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'paypal',

            },

            createOrder: function(data, actions) {
                return actions.order.create({
                    purchase_units: [{"amount":{"currency_code":"USD","value":${total}}}]
                });
            },

            onApprove: function(data, actions) {


            },

            onError: function(err) {
                console.log(err);
            }
            }).render('#paypal-button-container');
        }
        initPayPalButton();
    </script>
`;
}


function figureStage() {

}


function determineStage(editID) {

    let thisStage = orderDetails.filter(o=> {
        return o.orderID==editID && o.action=='stage' && o.valid=='1'
    })
    // console.log(thisStage)
    let thisStage1
    if(thisStage.length>0) {
        thisStage1 = stages(editID).find(s=> {
            return s.stage == thisStage[0].field
        })
    }
    // console.log(thisStage1,editID)
    return thisStage1
}

function emailForm(editID,stage) {
    let content = ``;

}

function alterDetail(detailID,field,value) {
    
    console.log(detailID,field,value)
    return $.ajax({
        type: 'POST',
        url: "ajax/admin.php?alterDetail",
        data: {
            detailID,
            field,
            value
        },
        success: function(data) {
            console.log(data);
            return new Promise((resolve,reject) => {
                resolve('hey');
            })
        }
    });
}

function updateOrder(editID,field,content,action,action_by,note) {
    waiting('.admin-content')
    console.log(editID);
    $.ajax({
        type: 'POST',
        url: "ajax/admin.php?updateOrder",
        data: {
            editID,
            field,
            content,
            action,
            action_by,
            note
        },
        success: function(data) {
            
            console.log(data);
            getOrderDetails().then(function() {
                removeWaiting();
                editOrder(editID);
            })
        }
    });
}


// STORE
function viewEditSubMenu() {
    $('.admin-subsubmenu').remove();
    let subMenu = `
        <div class='admin-button-set admin-subsubmenu'>
            <div id='filterToggle' class='press green admin-button'>Filter</div>
            <div id='editFieldToggle' class='press green admin-button'>Edit Field</div>

        </div>
    `;
    $('.admin-content').prepend(subMenu);

    renderStoreItems();

    $("#filterToggle").on('click',function() {
        filterOn = !filterOn
        if(filterOn) {
            $("#filterToggle").css('background','blue');
            $("#editFieldToggle").css('background','');
            editField = false;
        } else {
            $("#filterToggle").css('background','');
        }
    })
    $("#editFieldToggle").on('click',function() {
        editField = !editField
        if(editField) {
            $("#editFieldToggle").css('background','blue');
            $("#filterToggle").css('background','');
            filterOn = false;
        } else {
            $("#editFieldToggle").css('background','');
        }
    })
    $('#storeFilterSelect').on('input',function() {
        let dataStore = $(this).val();
        renderStoreItems(dataStore);

    })
}

function reconcileStore() {
    let content = ``;
    // all art has store items
    content += `<p>missing store items (or fewer than 4)</p><br><hr><br>`;
    adArtList.forEach((art) => {
        let storeCount = adStoreList.filter((sto) => {
            return sto.artID == art.ID
        })
        if(storeCount.length < 4) {
            content += `<p>${art.ID} - ${art.title} has ${storeCount.length} store options`;
        }
    })

    // trim sizes
    let editArray = [];
    adStoreList.forEach(sto => {
        let sizeArr = sto.size.split('x');

        //console.log(sizeArr);
        // console.log(sizeArr[0].trim());
        // console.log(sizeArr[1].trim());
        let newSize = `${sizeArr[0].trim()} x ${sizeArr[1].trim()}`;
        let obj = {editID:sto.ID,field:'size',value:newSize}
        editArray.push(obj);
    })
    console.log(editArray)
    // $.ajax({
    //     type: 'POST',
    //     url: "ajax/admin.php?updateStoreField",
    //     data: {
    //         editList:editArray
    //     },
    //     success: function(data) {
    //         $('.admin-content').append(data)
    //     }
    // });
    // set up packaging ID's
    content += `<p>Setting up packaging ID's</p>`;
    // console.log(adStoreList,packageList);
    // adStoreList.forEach((item) => {
    //     let dims = [parseInt(item.l),parseInt(item.w),parseInt(item.h)];
    //     dims.sort(function(a, b){return b-a})
    //     // content += `<p>${dims[0]} - ${dims[1]} - ${dims[2]}</p>`;
    //     let boxID = []; let rollID = [];
    //     packageList.forEach((pack) => {
    //         if(parseInt(pack.l) > dims[0] && parseInt(pack.h) > dims[1] && pack.type=='box') {
    //             boxID.push(parseInt(pack.ID))
    //         } else {

    //         }
    //     })
    //     //console.log(boxID);
    //     if(boxID.length>0) {
    //         boxID = [Math.max(...boxID).toString()]
    //     } else {
    //         boxID = ['1','1']
    //     }
    //     // if(boxID.length == 0) {
    //     //
    //     // }
    //     //console.log(dims,boxID);
    //     item.boxID = JSON.stringify(boxID);

    //     packageList.forEach((pack) => {
    //         if(parseInt(pack.l) > dims[0] && pack.type=='roll') {
    //             rollID.push(parseInt(pack.ID))
    //         } else {

    //         }
    //     })
    //     if(rollID.length>0) {
    //         rollID = [Math.min(...rollID).toString()];
    //         rollID.push('24');
    //     } else {
    //         rollID = ['23','22','24']
    //     }

    //     item.rollID = JSON.stringify(rollID);
    //     console.log(item);
    //     $.ajax({
    //         type: 'POST',
    //         url: "ajax/admin.php?editStoreItem",
    //         data: {
    //             obj:item
    //         },
    //         success: function(data) {
    //             $('.admin-content').append(data)
    //         }
    //     });



    // })







    // check for duplicates

    // missing info


    $('.admin-content').html(content);
}

// STORE Add items
function artChoiceModal() {

        storeBuildArtList = [];
        let content = `<div class='painting-choice-exit press'>X</div><div class='painting-choice-modal'>`;
        adArtList = adArtList.sort((a,b) => {
            return a.title > b.title ? 1 : -1
        })
        adArtList.map((art) => {
            content += `<img data-artChoice='${art.ID}' class='press' data-artTitle='${art.title}' src='https://ecomm.bradwrightwebdev.com/assets/images/${art.fileName}' width='100'><p>${art.title}</p>`;
        });
        content += `</div>`;

        $('.admin-content').prepend(content);


        $('[data-artChoice]').on('click',function() {
            //$('.painting-choice-modal').remove();
            let artID = $(this).attr('data-artChoice');
            if(storeBuildArtList.includes(artID)) {
                console.log('already there');
            } else {
                storeBuildArtList.push(artID);
                thisPainting.title = $(this).attr('data-artTitle');
                thisPainting.filePath = $(this).attr('src');

                //renderStoreItems();


                $('.artChoiceDisplay').prepend(`
                    <div style='display:flex;flex-direction: column;max-width:100px;' data-artID='${artID}' class='press'>
                    <p style='font-size:8px;' id='#artChoice'>${thisPainting.title}</p>
                    <img id='${artID}' src='${thisPainting.filePath}' width='75'>
                    </div>
                    `);
            }

            console.log(storeBuildArtList);
            $("[data-artID]").off('click').on('click',function() {
                let artID = $(this).attr('data-artID');
                for (let i=0; i<storeBuildArtList.length; i++) {
                    if(storeBuildArtList[i] === artID) {
                        storeBuildArtList.splice(i,1);
                    }
                }
                $("[data-artID='"+artID+"']").remove();
                console.log(storeBuildArtList);
                dimSelect('addNew');
                renderStoreItems();
            })

        });
        $(".painting-choice-exit").click(function() {
            $('.painting-choice-exit').remove();
            $('.painting-choice-modal').remove();
        });

}

function dimSelect(purpose) {
    console.log(purpose);
    let content = ``;

    if($(".dimSelectDD").length > 0) {
        $('.dimSelect-set').empty();
    } else {
        console.log(dimList);
        content = `
            <div class='dimSelectDD'>
            <select style='background:green' class='dimItem' id='type' name='type'>
                <option value='original'>Original</option>
                <option selected value='print'>Print</option>
            </select>
            <input class='dimItem' id='enterPrice' type='number' placeholder='enter price'>
            `;
            dimList.forEach((dim) => {
                content += `<div data-dim='${dim}' class='press dimItem'>${dim}</div>`;
            });
            content +=`
            <div id='dimNew' data-dim='new' class='press dimItem'>new</div>
        </div>`;
        $('.dimSelect-set').html(content);

        if(purpose=='addNew') {
            $('.dimSelectDD').on('click','[data-dim]',function() {
                console.log('data-dim clicked');
                let dim = $(this).attr('data-dim');
                if(dim=='') {
                    dim = $('#dimAdd').val();
                }
                if(dim=='new') {
                    let dimAdd = `
                    <div class='dimForm'>
                        <input id='dimAdd' type='text'>
                        <i data-dim='' id='addDim' class="press far fa-check"></i>
                    </div>`;
                    $('.dimSelectDD').append(dimAdd);
                } else {

                    let type = $('#type option:selected').val();
                    let price = $('#enterPrice').val();
                    addStoreItem(dim,type,price);

                }

            });
        }

        if(purpose=='filter') {
            $('.dimSelectDD').on('click','[data-dim]',function() {
                let dim = $(this).attr('data-dim');
                showStoreBySize(dim);
            });
        }
    }
}

function showStoreBySize(dim) {
    console.log(adStoreList);
    let content = `<table class='perSizeTable'>`;
    //console.log(dimList,adStoreList);
    let thisDim = adStoreList.filter((art) => {
        return art.size == dim
    })
    //console.log(thisDim);
    content += `<tr><td colspan='5'><h2>${dim}</h2></td></tr>`;
    thisDim.forEach((dimStoreItem, i) => {
        let thisArt = adArtList.find((art) => {
            return art.ID == dimStoreItem.artID
        });
        content += `
        <tr style='height:40px;'>
            <td><i data-remove='${dimStoreItem.ID}' class="press fas fa-times"></i></td>
            <td>${thisArt.title}</td>
            <td>${dimStoreItem.type}</td>
            <td class='price' data-id='${dimStoreItem.ID}'>${dimStoreItem.price}</td>

        </tr>
        `;
    });
        //<td><img src='https://graceannart/assets/images/thumb/${thisArt.fileName}' width='75'></td>


    content +=`</table>`;

    $(".admin-content").html(content);
}

function storePerPainting() {
    storeItemBuild = []; counter = 1; thisPainting = {};
    console.log('addStoreItem running');
    if($('#addStoreItem').length > 0) {
        console.log('already there');
    } else {
        let content = `
            <form id='addStoreItem'>
                <div class='storeTable'>
                    <div class='store-form-item newItem-set'>
                        <div class='artChoice-set'>
                            <div class='painting-choice-button admin-button'>Choose Paintings</div>
                            <div class='artChoiceDisplay'></div>
                        </div>

                        <div class='dimChoice-set'>
                            <div class='dimSelect-button admin-button press'>Create new store item</div>
                            <div class='dimSelect-set'></div>
                        </div>
                    </div>
                    <hr>
                    <div class='store-item-create-set'>
                    </div>
                </div>
            </form>
        `;
        $('.admin-content').html(content);
        $('.painting-choice-button').on('click',function() {
            artChoiceModal();
        });
        $('.dimSelect-button').off('click').on('click',function() {
            dimSelect('addNew');
        });

    }

}

function storePerSize() {
    console.log(dimList);
    if($('#chooseDim').length > 0) {
        console.log('already there');
    } else {
        let content = `<div class='dimSelect-set'>`;
        $('.admin-content').html(content);
        dimSelect('filter');

    }

    //storeAdminClicks()
}

function renderStoreItems(filter,value,sortField,dir) {
    $("#filterToggle").css('background','');
    $("#editFieldToggle").css('background','');
    removeWaiting();
    $('.editFieldButtonSet').remove();
    //console.log(filter,value,sortField,dir);
    console.log(storeBuildArtList,adStoreList)
    let storeItems = ''; let storeFilterList = [];

    // add art Title to adStoreList
    adStoreList.forEach((item, i) => {
        let thisArt = adArtList.find((art) => {
            return art.ID == item.artID
        });
       // console.log(thisArt);
        thisArt!==undefined ? item.title = thisArt.title : '';
    });

    $('.storeTable').remove();

    if(value && filter) {
        storeItems = adStoreList.filter((thing) => thing[filter] == value);
        showStoreItems();
    }

    if(filterOn) {

        if(filter=='title') {
            let uniqueArtID = uniqueBy(adStoreList,"artID");
            uniqueArtID.forEach((item, i) => {
                let addArt = adArtList.filter((art) => art.ID == item);
                if(addArt[0]) {
                    storeFilterList.push({text:addArt[0]['title'],value:addArt[0]['title']});
                }

            });
            renderFilterSelect(filter);
        }

        if(filter=='size') {
            let uniqueSize = uniqueBy(adStoreList,"size");
            // uniqueSize = uniqueSize.sort((a,b) => {
            //     return
            // })
            console.log(uniqueSize);
            uniqueSize.forEach((item,i) => {
                let count = adStoreList.filter((sto) => {
                    return sto.size == item
                })
                // console.log(count.length);
                storeFilterList.push({text:item,value:item,count:count.length})
            })
            console.log(storeFilterList);
            renderFilterSelect(filter);
        }
        if(filter=='type') {
            storeFilterList.push({title:'original',text:'original'});
            storeFilterList.push({title:'print',text:'print'});
            renderFilterSelect(filter);
        }
        if(filter=='price') {
            let uniquePrice = uniqueBy(adStoreList,"price");
            console.log(uniquePrice);
            uniquePrice = uniquePrice.sort((a,b) => {
                return parseInt(a) > parseInt(b) ? 1 : -1
            })
            console.log(uniquePrice);
            uniquePrice.forEach((item,i) => {
                storeFilterList.push({value:item,text:item});

                //console.log(addPrice);
            })
            renderFilterSelect(filter);
        }
        if(filter=='boxID') {
            console.log(packageList)
            packageList.forEach((pack) => {
                if(pack.type=='box') {
                    storeFilterList.push({value:pack.ID,text:`${pack.l} x ${pack.w} x ${pack.h}`});
                }
            })
            renderFilterSelect(filter);
        }
        if(filter=='rollID') {
            console.log(packageList)
            packageList.forEach((pack) => {
                if(pack.type=='roll') {
                    storeFilterList.push({value:pack.ID,text:`${pack.l} x ${pack.w} x ${pack.h}`});
                }
            })
            renderFilterSelect(filter);
        }
    }

    if(value==undefined && filter==undefined) {
        storeItems = adStoreList.sort((a,b) => {
            return a.title > b.title ? 1 : -1
        });
        //console.log(storeItems);
        showStoreItems();
    }

    function renderFilterSelect(what) {
        $('.filterTable').remove();
        let content=`<div class='filterTable'>`; let image;
        console.log(storeFilterList,what);
        storeFilterList.forEach((item, i) => {
            if(what=='title') {
                let thisOne = adArtList.find((art) => {
                    return art.title == item.value
                })
                image = `<img width='55' src='https://www.ecomm.bradwrightwebdev.com/assets/images/${thisOne.fileName}'>`;
            } else {image = '';}
            content += `
            <div class='filterTable-item'>
                <div data-filter='${what}'  data-value='${item.value}' class='press'>${item.text}</div>
                ${item.count ? `<p style='position:absolute;bottom:0;right:0'>${item.count}</p>` : ''}
                ${image}
            </div>`
        });
        content += `</div>`;
        $('.admin-content').prepend(content);

    }

    $('[data-filter]').on('click',function() {
        let filterNew = $(this).attr('data-filter');
        let value = $(this).attr('data-value');
        filterOn = false;
        console.log(filterNew,value)
        renderStoreItems(filterNew,value)

    })

    function showStoreItems() {
        $('.storeTable').remove();
        $('.filterTable').remove();
        $('.add-store-set').remove();

        let content = `<table class='storeTable'>
        <thead>
            <tr>
                <th class='press' data-sortStore='type'>type</th>
                <th class='press' data-sortStore='title'>title</th>
                <th class='press' data-sortStore='size'>size</th>
                <th class='press' data-sortStore='price'>price</th>
                <th class='press' data-sortStore='boxID'>boxID</th>
                <th class='press' data-sortStore='rollID'>rollID</th>
                <th>remove</th>
            </tr>
        </thead><tbody>`;
        if(sortField) {
            if(sortField == 'price') {
                if(dir=='asc' || dir==undefined) {
                    storeItems = storeItems.sort((a,b) => (parseInt(a['price']) > parseInt(b['price'])) ? 1 : -1);
                } else if(dir=='desc') {
                    storeItems = storeItems.sort((a,b) => (parseInt(a['price']) < parseInt(b['price'])) ? 1 : -1);
                }
            } else {
                if(dir=='asc' || dir==undefined) {
                    storeItems = storeItems.sort((a,b) => (a[sortField] > b[sortField]) ? 1 : -1);
                } else if(dir=='desc') {
                    storeItems = storeItems.sort((a,b) => (a[sortField] < b[sortField]) ? 1 : -1);
                }
            }

        }

        // craft content
        storeItems.forEach((obj) => {
            let box = packageList.find((pack) => {
                return pack.ID == obj.boxID
            })
            let boxText, rollText
            if(box) boxText = `${box.l} x ${box.w} x ${box.h}`
            let roll = packageList.find((pack) => {
                return pack.ID == obj.rollID
            })
            if(roll) rollText = `${roll.l} x ${roll.w} x ${roll.h}<br>`;
            content += `
            <tr data-artID='${obj.artID}' data-storeID='${obj.ID}' class='store-item-line'>
                <td class='press' data-filter='type' data-value='${obj.type}'><p>${obj.type}</p></td>
                <td class='press' data-filter='title' data-value='${obj.title}'><p>${obj.title}</p></td>
                <td class='press' data-filter='size' data-value='${obj.size}'><p>${obj.size}</p></td>
                <td class='press' data-filter='price' data-value='${obj.price}'><p class='price'>${obj.price}</p></td>
                <td class='press' data-filter='boxID' data-value='${obj.boxID}'><p>${boxText}</p></td>
                <td class='press' data-filter='rollID' data-value='${obj.rollID}'><p>${rollText}</p></td>
                <td><p data-remove='${obj.ID}' class='press'>X</p></td>
                <td><p data-edit='${obj.ID}' class='press'>edit</p></td>
            </tr>
            `;
        });
        content += `</tbody></table>`;
        content += `
                <div class='add-store-set'>
                    <div style='font-size:42px' class='plus press'>+</div>
                    <div class='input-set'></div>
                </div>
                `;
        $('.admin-content').append(content);
        //console.log(storeItems);

        // sorting clicks
        $("[data-sortStore]").off('click').on('click',function() {
            console.log(filterOn,editField);
            let sortField = $(this).attr('data-sortStore');
            if(filterOn) {
                renderStoreItems(sortField);
            } else if(editField) {
                $(`[data-filter='${sortField}']`).each(function() {
                    let editStoreID = $(this).parents('.store-item-line').attr('data-storeID');
                    let editVal = $(this).text();
                    $(this).replaceWith(`<td><input class='editInput' data-editField='${sortField}' data-storeID='${editStoreID}' value='${editVal}'></td>`)
                });
                $('.admin-subsubmenu').append(`
                    <div class='editFieldButtonSet'>
                        <div id='saveFieldEdit' class='admin-button press'>SAVE</div>
                        <div id='cancelFieldEdit' class='admin-button press'>cancel</div>
                        <p>all inputs</p><div class='input-set'><input data-groupEntry id='${sortField}' value=''></div>
                        <div id='applyValue' class='admin-button press'>apply value</div>
                    </div>
                `);
                $('input').on('keyup focus',function(e) {
                    dropDown(e)
                })
                $("#applyValue").on('click',function() {
                    let val = $("[data-groupEntry").val();
                    $('.editInput').val(val);
                })
                $('#cancelFieldEdit').on('click',function() {
                    editField = false;
                    $('.editFieldButtonSet').remove();
                    renderStoreItems(filter,value,sortField,dir);
                });
                $('#saveFieldEdit').on('click',function() {
                    waiting('.admin-content');
                    editField = false;
                    let editList = [];
                    let count = $('.editInput').length;
                    console.log(count);
                    $('.editInput').each(function() {
                        let obj = {
                            editID: $(this).attr('data-storeID'),
                            field: $(this).attr('data-editField'),
                            value: $(this).val()
                        }
                        editList.push(obj);
                        console.log(editList);
                        if(editList.length == count) {
                            console.log('ready to send');
                            $.ajax({
                                type: 'POST',
                                url: "ajax/admin.php?updateStoreField",
                                data: {
                                    editList
                                },
                                success: function(data) {

                                    console.log(data);
                                    $.when(
                                        getStoreList(),
                                        getDimList()
                                    ).done(function() {

                                        renderStoreItems(filter,value,sortField,dir);
                                    });

                                }
                            });
                        }
                    });
                })

            } else {
                let sortDir = '';
                if(dir==undefined || dir=='desc') {
                    sortDir='asc';
                } else {
                    sortDir='desc';
                }
                console.log(sortField);
                renderStoreItems(filter,value,sortField,sortDir);
            }


        })
    }
    $('.plus').off('click').on('click',function() {
        console.log(adStoreList);
        let content = `
            <tr class='store-item-line'>
            <input type='hidden' id='artID' value=''>
            <td><div class='input-set'><input autocomplete='off' id='type' placeholder='type' value=''> </div></td>
            <td><div class='input-set'><input autocomplete='off' id='title' placeholder='title' value=''></div></td>
            <td><div class='input-set'><input autocomplete='off' id='size' placeholder='dim' value=''></div></td>
            <td><div class='input-set'><input type='number' autocomplete='off' id='price' placeholder='price' value=''></div></td>
            <td><div class='input-set'><input type='number' autocomplete='off' id='boxID' placeholder='boxID' value=''></div></td>
            <td><div class='input-set'><input type='number' autocomplete='off' id='rollID' placeholder='rollID' value=''></div></td>
            <td><div class='save press'>save</div></td>
            <td><div class='cancel press'>cancel</div></td>
            </tr>`;
        //$('.store-table').append(content);

        $('.storeTable tr:last').after(content);
        console.log(filter,value)
        $("#"+filter).val(value);

        $("input").on('keyup focus',function(e) {
            dropDown(e);

        })

        $('.cancel').on('click',function() {
            renderStoreItems(filter,value,sortField,dir)
        })

        $('.save').on('click',function() {
            waiting('.admin-content')
            let thisArtID = adArtList.find((art) => {
                return art.title == $('#title').val()
            })
            if(thisArtID) {
                $("#artID").val(thisArtID.ID);
                console.log('id found - '+thisArtID.ID);
            }
            let obj = [{
                artID: thisArtID.ID,
                type: $('#type').val(),
                size: $('#size').val(),
                price: $('#price').val(),
                boxID: $('#boxID').val(),
                rollID: $('#rollID').val(),
            }]
            console.log(obj);
            $.ajax({
                type: 'POST',
                url: "ajax/admin.php?newStoreItem",
                data: {
                    obj
                },
                success: function(data) {

                    console.log(data);
                    $.when(
                        getStoreList(),
                        getDimList()
                    ).done(function() {

                        renderStoreItems(filter,value,sortField,dir);
                    });
                    storeItemBuild = [];

                }
            });
        })
    })

    $('.admin-content').off('click').on('click','[data-edit]',function() {
        $('.cancelUpdate').trigger('click');
        let editID = $(this).attr('data-edit');
        let parent = $(this).parents('.store-item-line');
        let tempContent = parent.html();
        let obj = adStoreList.find(sto => {
            return sto.ID == editID
        })
        let thisArt = adArtList.find(art=>{
            return art.ID == obj.artID
        })
        console.log(thisArt,obj,adArtList)
        // let artID = parent.attr('data-artID');
        // let type = parent.find(`[data-filter='type']`).attr('data-value');
        // let title = parent.find(`[data-filter='title']`).attr('data-value');
        // let size = parent.find(`[data-filter='size']`).attr('data-value')
        // let price = parent.find(`[data-filter='price']`).attr('data-value')
        // let boxID = parent.find(`[data-filter='boxID']`).attr('data-value')
        // let rollID = parent.find(`[data-filter='rollID']`).attr('data-value')
        //console.log(editID,type);
        let content = `
            <input type='hidden' id='id' value='${editID}'>
            <input type='hidden' id='artID' value='${obj.artID}'>
            <td><div class='input-set'><input autocomplete='off' id='type' placeholder='type' value='${obj.type}'> </div></td>
            <td><div class='input-set'><input autocomplete='off' id='title' placeholder='title' value='${thisArt.title}'></div></td>
            <td><div class='input-set'><input autocomplete='off' id='size' placeholder='size' value='${obj.size}'></div></td>
            <td><div class='input-set'><input type='number' autocomplete='off' id='price' placeholder='price' value='${obj.price}'></div></td>
            <td><div class='input-set'><input type='text' autocomplete='off' id='boxID' placeholder='boxID' value='${JSON.stringify(obj.boxID)}'></div></td>
            <td><div class='input-set'><input type='text' autocomplete='off' id='rollID' placeholder='rollID' value='${JSON.stringify(obj.rollID)}'></div></td>
            <td valign='middle'><div  class='update press'>save</div></td>
            <td valign='middle'><div  class='cancelUpdate press'>X</div></td>
            `;
        parent.html(content);
        $("input").on('keyup focus',function(e) {
            console.log('drop downing')
            dropDown(e);

        })
        $(".cancelUpdate").on('click',function() {
            $(this).parents('tr').html(tempContent);
        })

        $(".update").on('click',function() {
            let obj = {
                ID: $('#id').val(),
                artID: $('#artID').val(),
                type: $('#type').val(),
                title: $('#title').val(),
                size: $('#size').val(),
                price: $('#price').val(),
                boxID: $('#boxID').val(),
                rollID: $('#rollID').val()
            }
            console.log(obj);
            $.ajax({
                type: 'POST',
                url: "ajax/admin.php?editStoreItem",
                data: {
                    obj
                },
                success: function(data) {
                    console.log(data);
                    $.when(
                        getStoreList(),
                        getDimList()
                    ).done(function() {
                        renderStoreItems(filter,value,sortField,dir);
                    });
                    storeItemBuild = [];

                }
            });
        })
    })

    $('[data-remove]').off('click').on('click',function() {
        let removeID = $(this).attr('data-remove');
        removeStoreItem(removeID);

    });

    function dropDown(e) {
        $('.autoFill-set').remove();
        let thisField = $(e.target).attr('id');
        let artID = undefined;
        $(e.target).after(`<div style='width:140px' class='autoFill-set'></div>`);
        let val = $(e.target).val().toLowerCase();
        let tempList = []; 
        
        if(thisField=='boxID') {
            packageList.forEach(p=>{
                let text = `${p.type} ${p.l} x ${p.h} x ${p.w}`
                tempList.push({title:text,value:p.ID})
            })
        } else if(thisField=='title') {
            adArtList.forEach((art) => {
                if(art[thisField].toLowerCase().search(val) !== -1) {
                    tempList.push({title:art.title,value:art.ID})
                }
            })
        
        } else {
            adStoreList.forEach((art) => {
                if(art[thisField] && art[thisField].toString().toLowerCase().search(val) !== -1) {
                    tempList.push({title:art[thisField],value:art[thisField]})
                }
            })
        }
        
        
        let unique1 = []
        let uniqueList = []
        tempList.forEach((t) => {
            if(!unique1.includes(t.title)) {
                unique1.push(t.title)
                uniqueList.push(t)
            }
        });
        console.log(unique1,uniqueList,tempList)
        let content = ``
        uniqueList.forEach((col, i) => {
            content += `<div style='margin-bottom:5px' data-value='${col.value}' class='press' >${col.title}</div>`;
        });
        
        $(".autoFill-set").html(content);
        
        $('[data-value]').on('click',function() {
            if(thisField=='title') {
                $('#artID').val($(this).attr('data-value'));    
            } else if(thisField=='boxID') {
                $('#boxID').val($(this).attr('data-value'))
            } else {
                let text = $(this).text();
                $('#'+thisField).val(text);
            }
            $('.autoFill-set').remove();
        })


    }

    function removeStoreItem(removeID) {
        waiting('.admin-content');
        adStoreList = adStoreList.filter((item) => {
            return item.ID !== removeID
        });
        console.log(adStoreList);
        console.log(removeID);
        $.ajax({
            url: "ajax/admin.php?removeStoreItem&ID="+removeID,
            success: function(data) {
                console.log(data);
                $.when(
                    getStoreList(),
                    getDimList()
                ).done(function() {
                    renderStoreItems(filter,value,sortField,dir);
                });
            }
        });
    }

    function addStoreItem(dim,type,price) {
        //console.log(dim);
       // waiting('.admin-content');
        storeBuildArtList.forEach((art) => {
            let obj = {
                ID:counter,
                artID:art,
                type:type,
                size:dim,
                price:price
            };
            storeItemBuild.push(obj);
        });
        console.log(storeItemBuild);
        //renderStoreItems();
        $('.dimSelectDD').remove();
        //console.log(type);

        $.ajax({
            type: 'POST',
            url: "ajax/admin.php?newStoreItem",
            data: {
                obj:storeItemBuild
            },
            success: function(data) {

                console.log(data);
                $.when(
                    getStoreList(),
                    getDimList()
                ).done(function() {
                    renderStoreItems(filter,value,sortField,dir);
                });
                storeItemBuild = [];

            }
        });
        // $(".store-item-create-set").append(`
        //     <div class='store-item-line'>
        //         <p>${obj.type}</p>
        //         <h3>${obj.dim}</h3>
        //         <p class='price' data-id='${obj.ID}' class='press'>$${obj.price}</p>
        //         <h3 data-remove='${obj.ID}' class='press'>X</h3>
        //     </div>
        // `);
        counter = counter + 1;
        // console.log(storeItemBuild);

    }

}


// PACKAGES
function showPackages() {
    console.log(packageList);
    let content = `
    <div class='admin-button-set'>
        <div id='newPack' class='press admin-button'>Add</div>
    </div>
    <table style='width:500px;font-size:16px' class='admin-table'>
        <thead>
            <tr style='height:30px'><th></th><th></th><th>type</th><th>size</th><th >weight</th><th>cost</th></tr>
        </thead>
        <tbody>`;
            packageList.forEach((pack) => {
                content += `
                <tr style='height:50px' data-id='${pack.ID}'>
                    <td class='press' data-remove='${pack.ID}'><i class="press fas fa-times"></i></td>
                    <td class='press' data-edit='${pack.ID}'><i class="fal fa-edit"></i></td>
                    <td>${pack.type}</td>
                    <td>${pack.l} X ${pack.w} X ${pack.h}</td>
                    <td>${pack.weight}lbs</td>
                    <td>$${pack.cost}</td>

                </tr>`;
            })

        content += `</tbody>
    </table>`;


    $(".admin-content").html(content)

    $(`[data-edit]`).on('click',function() {
        editPackage($(this).attr('data-edit'));
    })
    $(`[data-remove]`).on('click',function() {
        let id = $(this).attr('data-remove');
        console.log(id);
        $.ajax({
            url: "ajax/admin.php?removePackage",
            type : 'POST',
            data : {
                id
            },
            success: function(data) {
                console.log(data);
                $.when(
                    getPackageList()
                ).done(function() {
                    showPackages();
                })
            }
        });

    })
    $(`#newPack`).on('click',function() {
        $.ajax({
            url: "ajax/admin.php?newPackage",
            beforeSend: function() {
                waiting('.admin-content');
            },
            type : 'POST',
            success: function(data) {
                console.log(data);
                $.when(
                    getPackageList()
                ).done(function() {
                    editPackage(data);
                })

            }
        });

    })
}

function editPackage(editID) {
    let thisPackage = packageList.find((pack) => {
        return pack.ID == editID
    });
    console.log(editID,thisPackage)
    let tempObj = {ID:editID};
    let content = `
        <div class='admin-form flexc'>
            <div class='form-item'>
                <p>Type</p>
                <input id='type' value='${thisPackage.type}' placeholder='type'>
                <p>Size</p>
                <input id='l' value='${thisPackage.l}' placeholder='length'>in
                <p>X</p>
                <input id='w' value='${thisPackage.w}' placeholder='width'>in
                <p>X</p>
                <input id='h' value='${thisPackage.h}' placeholder='height'>in
            </div>
            <div class='form-item'>
                <p>Weight</p><input id='weight' value='${thisPackage.weight}' placeholder='lbs'>lbs
            </div>
            <div class='form-item'>
                <p>Cost</p>$<input id='cost' value='${thisPackage.cost}' placeholder='$'>
            </div>

            <div class='admin-button-set'>
                <button id='save' class='admin-button'>Save</button>
                <button id='cancel' class='admin-button'>Cancel</button>
            </div>
        </div>
    `;


    $('.admin-content').html(content);

    $('#save').on('click',function() {
        $('input').each(function() {
            let attr = $(this).attr('id');
            tempObj[attr] = $(`#${attr}`).val();
        });
        console.log(tempObj);
        $.ajax({
            url: "ajax/admin.php?updatePackage",
            type : 'POST',
            data : {
                tempObj
            },
            success: function(data) {
                console.log(data);
                $.when(
                    getPackageList()
                ).done(function() {
                    showPackages();
                })

            }
        });
    })

    $("#cancel").on('click',function() {
        showPackages();
    })

}

// ART
function showArtClicks() {

    $('.admin-content').off('click').on('click','[data-art]',function() {
        way = $(this).attr('data-art');
        let editID = $(this).attr('data-editid');
        let dir = $(this).attr('data-dir');
        console.log(way,dir,editID);

        if(way=='edit') {
            editArtLine(editID);
        }

        if(way=='move') {
            let thisArt = adArtList.find((art) => {
                return art.ID == editID
            });
            console.log(thisArt,adArtList);
            let activeArt = adArtList.filter((art) => {
                return art.active == 'active'
            })
            if(dir=='up') {
                let alterArt = adArtList.find((art) => {
                    return art.orderNum == parseInt(thisArt.orderNum)-1
                })
                //console.log(alterArt);
                alterArt.orderNum = parseInt(thisArt.orderNum);
                thisArt.orderNum = parseInt(thisArt.orderNum)-1;
                showArt('active');
                artOrderUpdate();
            }
            if(dir=='down') {
                let alterArt = adArtList.find((art) => {
                    return art.orderNum == parseInt(thisArt.orderNum)+1
                })
                //console.log(alterArt);
                alterArt.orderNum = parseInt(thisArt.orderNum);
                thisArt.orderNum = parseInt(thisArt.orderNum)+1;
                showArt('active');
                artOrderUpdate();
            }
            if(dir=='start') {
                let shiftUp = adArtList.filter((art) => {
                    return art.orderNum>0 && art.orderNum<thisArt.orderNum
                })
                shiftUp.forEach((art) => {
                    art.orderNum = parseInt(art.orderNum)+1
                });

                thisArt.orderNum = 1;
                showArt('active');
                artOrderUpdate();
            }
            if(dir=='end') {
                let shiftUp = adArtList.filter((art) => {
                    return art.orderNum!==0 && art.orderNum>thisArt.orderNum
                })
                shiftUp.forEach((art) => {
                    art.orderNum = parseInt(art.orderNum)-1
                });
                console.log(shiftUp);
                thisArt.orderNum = parseInt(activeArt.length);
                showArt('active');
                artOrderUpdate();
            }
        }

        if(way=='inOut') {
            let thisArt = adArtList.find((art) => {
                return art.ID == editID
            });
            console.log(thisArt);
            if(thisArt.active=='active') {
                thisArt.active='inactive';
                let shift = adArtList.filter((art) => {
                    return art.orderNum > thisArt.orderNum
                });
                shift.forEach((art) => {
                    art.orderNum = parseInt(art.orderNum)-1;
                });
                thisArt.orderNum=0;
                artOrderUpdate();
            } else {
                thisArt.active='active';
                let activeArt = adArtList.filter((art) => {
                    return art.active == 'active'
                })
                thisArt.orderNum = parseInt(activeArt.length);
                artOrderUpdate();
            }
            $.ajax({
                type: "POST",
                url: "ajax/admin.php?activeChange",
                data: {
                    thisArt
                },
                success: function(data) {
                    showArt('active');
                }
            });
        }

    })

    $('.admin-content').on('click',"[data-editCol]",function() {
        let editThis = $(this).attr('data-editCol');
        let attr = $(this).attr('data-filter');
        if(typeof attr !== typeof undefined && attr !== false) {
            showArt(way,'',editThis,editThis,attr)
        } else if(editColReady) {
            showArt(way,editThis);
        } else if(filterColReady) {
            filterModal(editThis);
            //showArt(way,'','',editThis)
        } else {
            showArt(way,'',editThis);
            dir = !dir;
        }

    })
}

function filterModal(col) {
    editColReady = false; filterColReady = false;
    $('[data-editCol]').css('background','none');
    let content = ``;
    let newList = uniqueBy(showArtList,col);
    newList.forEach((item, i) => {
        content += `<p data-editCol='${col}' data-filter='${item}'>${item}</p><hr>`;
    });
    //console.log(content);
    $('.admin-content').prepend(`<div class='filterModal'>${content}</div>`);


}

// render ART items
function showArt(way = 'active',editCol = '',sort = 'orderNum',filterCol,filterVal) {
    removeWaiting();
    let editColArray = ['title','collection','subcollection','medium','dim','description','orderNum'];
    console.log(way,editCol,sort,filterCol,filterVal);

    // craft edit buttons
    let content = `
        <div class='admin-button-set table-edits' style='position:sticky;top:50px'>
            <div data-editCol-start='edit' class='admin-button press'>Edit by Column</div>
            <div data-editCol-start='filter' class='admin-button press'>Filter by Column</div>
        </div>`;

    // craft table
    content += `<table class='admin-table'><thead><tr>`;

    // find keys
    let keyArray = Object.keys(adArtList[0]);

    // load column headers
    if(way=='active') {
        // active headers
        content += `
            <th colspan='2' style='width: 30px'>change order</th>
            <th style='width: 50px';>edit</th>
            <th style='width: 50px';>remove</th>
            <th data-editCol='title'>title</th>
            <th data-editCol='collection'>collection</th>
            <th data-editCol='subcollection'>subcollection</th>
            <th data-editCol='medium'>medium</th>
            <th data-editCol='dim'>dimension</th>
            <th data-editCol='description'>description</th>
            <th data-editCol='orderNum'>order</th>
            <th>thumb</th>`;
    }
    if(way=='inactive') {
        // inactive headers
        content += `
            <th style='width: 50px';>edit</th>
            <th style='width: 50px';>add</th>
            <th>title</th>
            <th>thumb</th>`;
    }

    content += `</tr></thead><tbody>`;

    // filter artList
    showArtList = adArtList.filter((art) => {
        if(way=='active') {
            return art.active == 'active'
        }
        if(way=='inactive') {
            return art.active == 'inactive' || art.active == '' || art.orderNum=='0'
        }

    });
    // sort artList
    // if(!isNaN(sort)) {
    //     console.log('true')
    // } else {
    //     console.log('false')
    // }
    function compare(a,b) {
        let aa,bb
        if(!isNaN(a[sort])) {
            aa = parseInt(a[sort]);
            bb = parseInt(b[sort]);
        } else {
            aa = a[sort];
            bb = b[sort];
        }
        if(aa>bb) {
            if(dir) {return 1} else {return -1}
        } else if(aa<bb) {
            if(dir) {return -1} else {return 1};
        }
        return 0
        }
    if(filterCol) {
        showArtList = showArtList.filter((item) => {
            return item[filterCol] == filterVal
        })
    }
    showArtList = showArtList.sort(compare);
    //console.log(typeof(sort),showArtList);

    // craft table content
    showArtList.map(function(art) {

        content += `<tr style='border-bottom:1px solid #878787;padding:10px 0;'>`;
        // row content
        if(way=='active') {
            // change order
            content += `
            <td style='width: 30px;'>
                <div style='display:flex;flex-direction:column;align-items:center'>
                    <i data-art='move' data-editID='${art.ID}' data-dir='up' class="press fas fa-sort-up"></i>
                    <i data-art='move' data-editID='${art.ID}' data-dir='down' class="press fas fa-sort-down"></i>
                </div>
            </td>
            <td>
                <div style='display:flex;flex-direction:column;align-items:center'>
                    <i data-art='move' data-editID='${art.ID}' data-dir='start' class="press far fa-arrow-to-top"></i>
                    <i data-art='move' data-editID='${art.ID}' data-dir='end' class="press far fa-arrow-to-bottom"></i>
                </div>
            </td>`;
        }
        // add/remove art
        content += `
        <td data-art='edit' data-editID='${art.ID}' style='width: 30px';class='press'><i class="press far fa-edit find-${art.ID}" ></i></td>
        <td data-art='inOut' data-editID='${art.ID}' style='width: 30px';class='press'><i class="press fas fa-times"></i></td>
        `;

        // table data
        if(way=='active') {
            content += `
            <td data-editID='${art.ID}' data-col='title'>${art.title}</td>
            <td data-editID='${art.ID}' data-col='collection'>${art.collection}</td>
            <td data-editID='${art.ID}' data-col='subcollection'>${art.subcollection}</td>
            <td data-editID='${art.ID}' data-col='medium'>${art.medium}</td>
            <td data-editID='${art.ID}' data-col='dim'>${art.w} X ${art.h}</td>
            <td data-editID='${art.ID}' data-col='description' width='200'>${art.description}</td>
            <td data-editID='${art.ID}' data-col='orderNum'>${art.orderNum}</td>`;
        }

        // add picture
        content += `<td id='${art.fileName}' class='artHover'><img data-bigPic='${art.fileName}' src='https://ecomm.bradwrightwebdev.com/assets/images/${art.fileName}' width='100'></td>`;
        content += `</tr>`;




    })
    content += `</tbody></table>`;

    // render content
    $('.admin-content').html(content);

    // change to inputs for editCol - add save button
    if(editCol!=='') {
        console.log('trying to edit '+editCol);
        $("[data-col='"+editCol+"']").each(function() {
                let thisText = $(this).text();
                let editID = $(this).attr('data-editID');
                $(this).html(`<input type='text' data-thisID='${editID}' value='${thisText}' name='${editCol}'>`);
        })
        $(".table-edits").append(`<div class='admin-button' data-editCol-save style='background:red'>Save</div>`);

        $('[data-editCol-save]').off('click').on('click',function() {
            console.log('trying to save');
            let saveArray = [];

            $('input').each(function() {
                let newObj = {};
                newObj.id = $(this).attr('data-thisID');
                newObj.val = $(this).val();
                saveArray.push(newObj);

            })
            $.ajax({
                type: "POST",
                beforeSend: function() {
                    waiting('.admin-content');
                },
                url: "ajax/admin.php?updateArtListColumn",
                data: {
                    col:editCol,
                    saveArray
                },
                success: function(data) {
                    //console.log(data);
                    getArtList(showArt);

                }
            });


        });
    }

    $('[data-editCol-start]').off('click').on('click',function() {
        $('.filterModal').remove();
        let val = $(this).attr('data-editCol-start');
        if(val=='edit') {
            editColReady = true; filterColReady = false;
        }
        if(val=='filter') {
            editColReady = false; filterColReady = true;
        }
        $('[data-editCol]').css('background','green');
    })

     // hover to see bigger pic
    $('[data-bigpic]').hover(function() {
        let pic = $(this).attr('data-bigPic');
        //console.log(pic);
        let offset = $(this).offset();
        let top = offset.top;
        let left = offset.left / 2;
        //console.log(top);
        let content = `
        <div id='biggerArt' style='position:absolute;width:300px;top:${top}px;left:${left}px;z-index:99;background:rgba(250,250,250,.7);padding:20px;border:1px solid black;transition:all 1s;'><img src='https://www.ecomm.bradwrightwebdev.com/assets/images/${pic}' width='300'></div>
        `;
        $('body').prepend(content);
    }, function() {
        $('#biggerArt').remove();
    });


    showArtClicks();
}

// add ART
function addArtForm(container = '.admin-content',isNew) {
    console.log(adArtList);
    console.log($('[data-editID]').attr('data-editID'))
    if($('#addArtForm').length > 0) {
        console.log('already there');
    } else {
        let content = `
            <form id='addArtForm'>
                <input type='file' name='upload' id='upload'>
                <div id='uploadIt' class='press admin-menu-item'>Upload</div>
            </form>
        `;

        $(container).prepend(content);

        $('#addArtForm').on('input',function() {
            //let thisVal = ;
            if(adArtList.some((art) => {
                return art.fileName == $('input').val().split(`\\`).pop();
            })) {
                $('#addArtForm').append('<p>There is already a file with this name.  Proceeding with upload will replace photo currently on server</p>');
            }
        });
        $('#uploadIt').click(function() {
            if($('input').val()) {
                uploadPhoto(isNew)

            } else {
                alert('nothing to upload')
            }

        });
    }

}

function uploadPhoto(isNew) {
    //getArtList(artIntroInfo('76'));
    console.log('trying to upload');
    var formData = new FormData();
    var file_data = $('#upload').prop('files')[0];
    formData.append('file', file_data);
    console.log(JSON.stringify(formData),file_data);
    if(isNew) {
        $.ajax({
            url: "ajax/admin.php?uploadNew",
            type : 'POST',
            beforeSend: function() {
                waiting('.admin-content');
            },
            data : formData,
            processData: false,  // tell jQuery not to process the data
            contentType: false,  // tell jQuery not to set contentType
            success: function(data) {
                removeWaiting()
                console.log(data);
                $.when(
                    getArtList()
                ).then(function(e) {
                    //console.log(e);
                    artIntroInfo(data)
                })
            }
        });

    } else {
        $.ajax({
            url: "ajax/admin.php?uploadReplace",
            type : 'POST',
            beforeSend: function() {
                waiting('.admin-content');
            },
            data : formData,
            processData: false,  // tell jQuery not to process the data
            contentType: false,  // tell jQuery not to set contentType
            success: function(data) {
                removeWaiting()
                console.log(data);
                let editID = $('[data-editID]').attr('data-editID')

                let imageSrc = `https://www.ecomm.bradwrightwebdev.com/assets/images/${data}`

                $('.artline-edit-pic').find('img').after(`<img src='${imageSrc}' width='150' alt='do what now?'><p >Upload successful - to keep this new image, type it's filename into the 'filename' input to the left and click save</p>`)

            }
        });
    }
}

function artIntroInfo(id) {
    removeWaiting();
    if($('#addArtForm').length==0) {
        $('.admin-content').html(`<div id='addArtForm'></div>`);
    }
    console.log(adArtList);
    let count = adArtList[0].length;
    let propList = Object.keys(adArtList[0]);
    console.log(propList,id)
    let thisArt = []; //ksadf
    if(id) {
        thisArt = adArtList.find((art) => {
            return art.ID == (id.toString())
        });
        //thisArt = thisArt[0];
    } else {
        thisArt = undefined;
    }
    console.log(id,thisArt);
    let content = ``;
    //<div style='display:none' id='ID' data-id='${id}'></div>
    content += `
        <div class='set'>
            <div class='left'>

                <input type='hidden' name='ID' id='ID' value='${id}'>`;
        propList.forEach((prop) => {
            let value
            if(thisArt) {
                value = thisArt[prop];
            } else {
                value = '';
            }
            if(prop=='description') {
                content += `<div class='item'><h4>${prop}</h4><textarea type='text' name='${prop}' id='${prop}' value='${value}'></textarea></div>`;
            } else if(prop == 'ID' || prop=='orderNum') {

            } else {
                content += `
                    <div class='item'><h4>${prop}</h4><input type='text' name='${prop}' id='${prop}' value='${value}'></div>
                `;
            }

        })
            content += `</div>
            <div class='right'>
                <div class='choice'></div>
            </div>
        </div>
        <div class='flexr'>
            <div class='button press' data-r='build'>Build Store Items</div>
            <div class='button press' data-r='submit'>Submit</div>
        </div>`;

    $("#addArtForm").html(content);

    $('input').on('focus input',function() {
        let val = $(this).val();
        let name = $(this).attr('id');
        let newList = [];
        //console.log(val,name)
        adArtList.forEach((item, i) => {
            //if(val=='') {
                if(item[name].toLowerCase().includes(val.toLowerCase())) {
                    newList.push(item[name]);
                }
            //}
        });
        let uniqueList = [];
        newList.forEach((item, i) => {
            if(!uniqueList.includes(item)) {uniqueList.push(item)}
        });
        content = '';
        //console.log(filterIt);
        if(uniqueList.length > 0) {
            uniqueList.forEach((item, i) => {
                content += `<p data-addToForm='${name}' data-val='${item}' class='press'>${item}</p>`;
            });
        }
        $('.choice').html(content);
        $(`[data-addToForm]`).on('click',function() {
            let name = $(this).attr('data-addToForm');
            let val = $(this).attr('data-val');
            $(`#`+name).val(val);
        })
        //console.log(val, name);
    });

    $('.button').on('click',function() {
        console.log($("#ID").val());
        if($('.item').length > 0) {
            propList.forEach((prop) => {
                thisArt[prop] = $("#"+prop).val();
            })
        }
        let success = false;
        // thisArt = $('#addArtForm').serializeArray();
        console.log(thisArt);
        if($(this).attr('data-r') == 'submit') {
            //let id = $("#ID").val();
            $('#addArtForm').empty();
            //console.log(input);
            $.ajax({
                url: "ajax/admin.php?artInfo",
                type : 'POST',
                data : {
                    id: id,
                    input:thisArt
                },
                success: function(data) {
                    console.log(data);
                    $("#addArtForm").append('<p>Art info is saved</p><br><br>');
                }
            });

            if(storeAddObj.length > 0) {
                console.log('it exists');
                $.ajax({
                    url: "ajax/admin.php?newStoreItem",
                    type : 'POST',
                    data : {
                        obj:storeAddObj
                    },
                    success: function(data) {
                        console.log(data);
                        thisArt = {};
                        storeAddObj = [];
                        $("#addArtForm").append('<p>Store info is saved</p>');
                    }
                });
            } else {
                console.log('not built yet');
            }

        }

        if($(this).attr('data-r') == 'build') {
            // console.log(thisArt);
            // propList.foreach((item, i) => {
            //     thisArt[item.name] = item.value
            // });
            console.log(thisArt);
            let tempID = 1;
            let content = `
                <div class=''>
            `;
            $('.set').prepend(content);
            $('.left').html(`<div class='dimSelect-set'></div>`);
            //console.log(dimList);
            content = `
                <div class='dimSelectDD'>
                <select style='background:green' class='dimItem' id='type' name='type'>
                    <option value='original'>Original</option>
                    <option selected value='print'>Print</option>
                </select>
                <input class='dimItem' id='enterPrice' type='number' placeholder='enter price'>
                `;
                dimList.forEach((dim) => {
                    content += `<div data-dim='${dim}' class='press dimItem'>${dim}</div>`;
                });
                content +=`
                <div id='dimNew' data-dim='new' class='press dimItem'>new</div>
            </div>`;
            $('.dimSelect-set').html(content);
            $("#enterPrice").focus();
            $(".choice").html(`<table class='admin-table'>`);

            $('.dimSelectDD').on('click','[data-dim]',function() {
                //let dim = ;
                let tempObj = {
                    id: tempID,
                    artID: thisArt.ID,
                    type: $('#type').val(),
                    size: $(this).attr('data-dim'),
                    price: $('#enterPrice').val()

                }

                console.log(tempObj,storeAddObj);
                if(storeAddObj.some((item) => item.size == tempObj.size && item.type == tempObj.type)) {
                    console.log('already built');
                } else {
                    storeAddObj.push(tempObj);
                    tempID++;
                }

                // add new dim
                $("#dimNew").on('click',function() {
                    let dimAdd = `
                    <div class='dimForm'>
                        <input id='dimAdd' type='text'>
                        <i data-dim='' id='addDim' class="press far fa-check"></i>
                    </div>`;
                    $('.dimSelectDD').append(dimAdd);
                })

                function showStoreAddObj() {
                    content = '';
                    storeAddObj.forEach((item, i) => {
                        content += `<tr><td>${item.type}</td><td>${item.size}</td><td>$${item.price}</td><td data-remove='${item.id}' class='press'>X</td></tr>`;
                    });
                    $('.admin-table').html(content);
                    $('[data-remove]').on('click',function() {
                        let gone = parseInt($(this).attr('data-remove'));

                        storeAddObj = storeAddObj.filter((item) => {
                            return item.id !== gone
                        })
                        console.log(storeAddObj,gone);
                        showStoreAddObj();
                    })
                }

                showStoreAddObj();
                $("#enterPrice").val('').focus();
            });
        }

    })

}

function storeIntroInfo() {

}

// Edit ART
function artOrderUpdate() {
    console.log(adArtList);
    $.ajax({
        type: "POST",
        url: "ajax/admin.php?artOrderUpdate",
        data: {
            adArtList
        },
        success: function(data) {
            console.log(data);
            //showArt('active');
            //console.log(adArtList);
        }
    });
}

function editArtLine(editID) {
    let home = document.querySelector(`.find-${editID}`);
    console.log(home)
    let offset = home.getBoundingClientRect();
    let main = document.getElementById('main')
    let scrollTop = main.pageYOffset || main.scrollTop;
    let top = offset.top + scrollTop - 200 + 'px';
    // let offsetTop = home.offset.top
    console.log(home,offset,top)
    if($('.admin-edit-set').length > 0) {
        $('.admin-edit-set').remove();
    }
    console.log(offset,top,'trying to edit');
    let keyArray = Object.keys(adArtList[0]);
    let content = `
        <div data-editID='${editID}' class='admin-edit-set'>
        <form id='artEditForm' class='admin-edit-form'>
    `;

        let thisArt = adArtList.find((art) => {
            return art.ID == editID
        });


        content += `
        <input type='hidden' id='ID' value='${thisArt.ID}'>
        <input type='hidden' id='orderNum' value='${thisArt.orderNum}'>
        <input type='hidden' id='active' value='${thisArt.active}'>

        <div class='artline-edit-item'><p>Title</p>
            <input type='text' id='title' value="${thisArt.title}">
        </div>
        <div class='artline-edit-item'><p>fileName</p>
            <p>${thisArt.fileName}</p>
            <div id='uploadForm' class='artline-upload-button'>upload new</div>
            <input type='text' id='fileName' value="${thisArt.fileName}">
        </div>
        <div class='artline-edit-item'><p>collection</p>
            <input type='text' id='collection' value="${thisArt.collection}">
        </div>
        <div class='artline-edit-item'><p>subcollection</p>
            <input type='text' id='subcollection' value="${thisArt.subcollection}">
        </div>
        <div class='artline-edit-item'><p>medium</p>
            <input type='text' id='medium' value="${thisArt.medium}">
        </div>
        <div class='artline-edit-item'><p>width</p>
            <input type='text' id='w' value="${thisArt.w}">
        </div>
        <div class='artline-edit-item'><p>height</p>
            <input type='text' id='h' value="${thisArt.h}">
        </div>
        <div class='artline-edit-item'><p>Description</p>
            <textarea cols='40' rows='8' type='text' id='description' value="${thisArt.description}">${thisArt.description}</textarea>
        </div>
        <div class='artline-edit-item'><p>Year</p>
            <input type='number' id='year' value="${thisArt.year}">
        </div>`;

        content += `
            <div class='admin-button-set'>
                <div data-artline-do='save' class='admin-button press'>Save</div>
                <div data-artline-do='cancel' class='admin-button press'>Cancel</div>
            </div></form>`;

        content += `
            <div class='artline-edit-pic'>
                <div style='width:100%' class='flexr'><img width='150' src="https://ecomm.bradwrightwebdev.com/assets/images/${thisArt.fileName}" alt='huh?'></div>
                <div class='choice'></div>
            </div>
            `;



    function insertEditLine() {
        $(".admin-content").prepend(content);
        $('.admin-edit-set').animate({top:top,left:70},0);
    }

    function saveEditLine() {
        thisArt.ID = editID;
        let w = $('#w').val();
        let h = $('#h').val();
        thisArt.dim = `${w} X ${h}`;
        keyArray.forEach((key) => {
            thisArt[key] = $('#'+key).val();
        });
        console.log(thisArt);
        //console.log(adArtList);
        //thisArtSend = JSON.stringify(thisArt);
        $.ajax({
            type: "POST",
            url: "ajax/admin.php?updateArtList",
            data: {
                thisArt
            },
            success: function(data) {
                console.log(data);
                showArt('active');
                console.log(adArtList);
            }
        });
    }

    $.when(insertEditLine()).then(function() {

        $('input').on('input',function() {
            let val = $(this).val();
            let name = $(this).attr('id');
            let newList = [];
            console.log(val,name)
            adArtList.forEach((item, i) => {
                if(val!=='') {
                    if(item[name] && item[name].toLowerCase().includes(val.toLowerCase())) {
                        newList.push(item[name]);
                    }
                }
            });
            let uniqueList = [];
            newList.forEach((item, i) => {
                if(!uniqueList.includes(item)) {uniqueList.push(item)}
            });
            content = '';
            //console.log(filterIt);
            if(uniqueList.length > 0) {
                uniqueList.forEach((item, i) => {
                    content += `<p data-addToForm='${name}' data-val='${item}' class='press'>${item}</p>`;
                });
            }
            $('.choice').html(content);
            $(`[data-addToForm]`).on('click',function() {
                let name = $(this).attr('data-addToForm');
                let val = $(this).attr('data-val');
                $(`#`+name).val(val);
            })
            console.log(val, name);
        });
        $('input').keypress(function(e) {
            if(e.keyCode == 13) {
                e.preventDefault();
                saveEditLine();
            }
        })
        

        $('#uploadForm').on('click',function() {
            addArtForm('.artline-edit-pic',false)
        })

        $('[data-artline-do]').on('click',function() {
            let way = $(this).attr('data-artline-do');
            console.log(way);
            console.log(thisArt);
            if(way=='save') {
                saveEditLine();
            }
            if(way=='delete') {

            }
            if(way=='cancel') {
                $('.admin-edit-set').remove();
            }
        })
    })




}

function deleteArtLine(id) {
    console.log(id);
    $.ajax({
        type: "POST",
        url: "ajax/admin.php?deleteArtLine",
        data: {
            id
        },
        success: function(data) {
            console.log(data);
            if(data=='success') {
                reconcilePics();
            }
        }
    });
}

function removeRow(fileName) {
    console.log(fileName);
    $.ajax({
        type: "POST",
        url: "ajax/admin.php?removeRow",
        data: {
            fileName
        },
        success: function(data) {
            console.log(data);
            if(data=='success') {
                reconcilePics();
            }
        }
    });
}

// RECONCILE ALL THINGS
function reconcilePics() {
    $.ajax({
        url: "ajax/admin.php?reconcilePics",
        beforeSend: function() {
            waiting('.admin-content');
        },
        success: function(data) {
            $('.admin-content').html(data);
            getArtList();
            getDimList();
            getStoreList();
            getOrderDetails();
            removeWaiting();
        }
    });
    // $.ajax({
    //     url: "ajax/admin.php?updateFileList",
    //     beforeSend: function() {
    //         $('.admin-content').append('confirming file list');
    //     },
    //     success: function(data) {
    //         console.log(data);
    //         $('.admin-content').append('file list updated');
    //     }
    // });
}

function unlinkPic(fileName) {
    console.log(fileName)
    $.ajax({
        type: "POST",
        url: "ajax/admin.php?removePic",
        data: {
            fileName
        },
        success: function(data) {
            console.log(data);
            if(data==true) {
                reconcilePics();
            }
        }
    });
}




function runTest() {
    console.log('running test');
    $.ajax({
        url: "ajax/admin.php?test",
        type : 'POST',
        //data : formData,
        success: function(data) {
            console.log(data);
            $('.admin-content').html(data);

        }
    });
}
